.title-medium-submission {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.text-medium {
  font-size: 20px;
  font-family: Roboto;
  font-weight: normal;
  color: #000;
}

.file-submission .MuiDropzoneArea-root {
  min-height: 100px;
  background-color: #f9f9f9;
  border-radius: 10px;
  border: dashed 2px #dfdfdf;
}
.info-text {
  color: #455461;
  font-size: 14px;
}
.error-text {
  font-size: 14px;
  color: #f44d5e;
}
.success-text {
  font-size: 14px;
  color: rgb(24, 173, 24);
}
.waiting-text {
  font-size: 14px;
  color: orange;
}

.selectedfilebox {
  min-height: 100px;
  background-color: #f9f9f9;
  border-radius: 10px;
  border: dashed 2px #dfdfdf;
  width: 100%;
  text-align: center;
}
.dark-selectedfilebox {
  min-height: 150px;
  background-color: #121A35;;
  border-radius: 10px;
  border: 1px dashed #263559;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.dark-close-file-hld {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #343847;
  padding: 0px 14px 5px 14px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.dark-close-file-hld img {
  width: 14px !important;
}
::placeholder {
  color: #bfbfbf;
  font-size: 16px;
  text-transform: none;
}

.submission-dialog {
  text-align: center;
  min-width: 500px;
}

.success-title {
  font-size: 24px;
  font-family: Roboto;
  font-weight: 500;
  color: #000;
  margin-top: 10px;
}
.submission-msg {
  font-size: 16px;
  font-family: Roboto;
  font-weight: normal;
  color: #000;
}

.persistent-error {
  min-width: 250px;
  width: auto;
  background-color: #fff4f4;
  color: #e9505c;
  border: solid 1px #f1a3ab;
  border-radius: 5px;
}

.dark-persistent-error {
  min-width: 250px;
  width: auto;
  /* background-color: #262938; */
  color: #e9505c;
  /* border: 1px solid #343847; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.persistent-success {
  min-width: 200px;
  width: auto;
  background-color: #f5fff8;
  color: #84d69a;
  border: solid 1px #84d69a;
  border-radius: 5px;
}

.toast-success {
  color: #84d69a;
  font-size: 14px;
}

.toast-error {
  color: #e9505c;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
}

.MuiCircularProgress-colorSecondary {
  color: #f44d5e;
}
.darkTheme .submission-form .dark-submission-input,
.darkTheme .submission-form .dark-submission-input:focus {
  background-color: transparent;
  border: 1px solid #263559;
  border-radius: 50px !important;
  font-size: 12px;
  padding: 18px 10px;
  color: #b5c0d3;
}
.darkTheme .submission-form .dark-submission-input:focus {
  background-color: #050c2a;
  border: 1px solid #263559 !important;
  color: #b5c0d3;
}
.dark-file-submission .submission-form .dark-submission-input::placeholder {
  color: #263559;
  font-size: 12px;
}
.submission-form .dark-submission-input:focus {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #343847;
}
.darkTheme .hackathonSubmithld .hackathonSubmitbtn {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%) !important;
  border-radius: 50px !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 4px 40px !important;
}

.bootstrapisoauth .hackathonSubmithld .btn{
  background: #757575;
  color: #fff;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;
  padding: 4px 40px;
}
.darkTheme .hackathonSubmithld .btn{
  background: #263559;
}
 .arrowFixDown{
  margin-top: 6px;
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
 }
.hackathonSubmithld .hackathonSubmitbtn{
  background: #f44d5e !important;
  border-radius: 50px !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 4px 40px !important;
}
/* .darkTheme .hackathonSubmithld .dark-new-topic{
  background-color: #4E5267 !important;
} */
.submitSuccess .MuiDialog-paper {
  background: #262938;
  border: 1px solid #343847;
}
.finalDataSetText {
  border: 1px solid #263559;
  border-radius: 10px;
  padding: 10px;
  text-transform: none;
  font-weight: 400;
}
@media only screen and (max-width: 575px) {
  .submission-dialog {
    min-width: 250px;
  }
}
