.heading-hld {
  padding: 10px 30px;
  border-bottom: 1px solid #c4c4c4;
}
.dashboard-heading {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #000;
  margin-bottom: 0 !important;
}
.dashboard-list-hld {
  border-radius: 10px;
  min-height: auto;
  margin: 0px;
  cursor: pointer;
}
.dashboardImage {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* min-height: 200px; */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.userImageHld {
  width: 55px;
  height: 55px;
  background: #fff;
  position: absolute;
  bottom: -25px;
  left: 10px;
  border-radius: 50px;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.userImage {
  width: 50px;
  border-radius: 50px;
}
.userName {
  font-size: 14px;
  color: #000;
  margin-bottom: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}
.visual-hack-details {
  padding: 25px 10px 15px;
  border: solid 1px #e7e7e7;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.likeNViews img {
  margin-right: 4px;
  cursor: pointer;
}
.likeNViews span {
  font-size: 16px;
  color: #455461;
  font-weight: 400;
  font-size: 14px;
}
.like-width-14 {
  width: 14px;
}
.likeshld {
  display: flex;
  justify-content: center;
  border: 0.5px solid #263559;
  border-radius: 50px;
  padding: 0px 10px;
  margin-right: 30px;
}
.active-likeshld {
  background: #786BF9;
}
.like-icon {
  vertical-align: top !important;
}
.dashboardIframe {
  width: fit-content !important;
}
.dashboardImagehld {
  position: relative;
  min-height: 138px;
}
.dashboardImagehld .overlayImage {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardImagehld:hover .overlayImage {
  visibility: visible;
  opacity: 0.5;
}
.hackathon-dashboard .MuiInput-formControl {
  background: #303043;
  border: 2px solid #39394c;
  border-radius: 50px;
}
.lightMode .MuiInput-formControl {
  background: #d9d9d9;
  border: 2px solid #dadce0;
  border-radius: 50px;
}
.lightMode .MuiSelect-select {
  color: #000 !important;
}
.lightMode .MuiSelect-icon {
  color: #000 !important;
}

.hackathon-dashboard .MuiSelect-select {
  padding: 6px 10px !important;
  color: #b5c0d3;
}
.hackathon-dashboard .MuiSelect-icon {
  color: #b5c0d3;
}
.hackathon-dashboard .MuiPagination-ul .MuiPaginationItem-root {
  border-radius: 5px;
  margin-left: 5px;
  color: #6c6fc3;
  border: 0.5px solid #b5c0d3;
}

.hackathon-dashboard .MuiPagination-ul .Mui-selected {
  border-color: #6c6fc3;
  background-color: #6c6fc3;
  color: #fff;
}
/* Dark Mode css */
.MuiMenu-list {
  background-color: #262938 !important;
  color: #ffffff !important;
}
.darkTheme .heading-hld {
  border-bottom: 1px solid #263559;
}
.darkTheme .visual-hack-details {
  border: solid 1px #263559;
}
.darkTheme .hackathon-dashboard .MuiPagination-ul .MuiPaginationItem-root {
  border: 0.5px solid #263559;
}
