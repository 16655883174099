.hackathon-browser-info{
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  padding: 6px 12px;
  border-radius: 5px;
  color: #14A1DF;
  background: rgba(20, 161, 223, 0.1);
}
.bootstrap-iso .monk {
  position: relative;
}

.bootstrapisoauth .monk {
  background: url("img/lbpagehack.png") no-repeat bottom center;
  /* background-color: #cccccc; */
  height: 180px;
  /* width: auto; */
}

.bootstrapisoauth .monk-hackathon {
  position: absolute;
  left: 0;
  bottom: 0;
}
.bootstrapisoauth .lb-hack {
  height: 214px;
}
.scoreHld {
  max-width: 12%;
  min-width: 12%;
}
/* @media only screen and (max-width: 1000px) {
	.bootstrapisoauth .lb-hack {
		display: none;
	}
} */
@media only screen and (max-width: 991px) {
  .bootstrapisoauth .lb-hack {
    display: block;
    text-align: center;
    height: auto;
    width: 100%;
  }
}

.bootstrapisoauth .mobile-view {
  display: none;
}

.bootstrapisoauth .web-view {
  display: block;
}

.bootstrapisoauth .page-item.active .page-link {
  background-color: #e9505c;
  border-color: #e9505c;
}

.lederborad-head {
  background-color: #000;
  border-radius: 5px;
}

.lederborad-body {
  background-color: #f4f4f4;
}
.body-text {
  color: #80878c;
  font-size: 13px;
  font-weight: normal;
}

.body-text-bold {
  color: #455461;
  font-size: 14px;
  font-weight: 500;
}

.body-text-bold a {
  color: #455461;
  font-size: 14px;
  font-weight: 500;
}

.head-text {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.bootstrapisoauth .btn-download {
  border-radius: 50px !important;
  border: solid 1px #abb2b8 !important;
  background-color: #fff !important;
  color: #a5acb2 !important;
  font-size: 14px !important;
}
.bootstrapisoauth .btn-download:hover {
  color: #a5acb2 !important;
}
.bootstrapisoauth .publicbtn:hover {
  color: #000 !important;
}
.bootstrapisoauth .btn-download img {
  width: 10px;
}

.custom-list li {
  background: url("./img/dark-leaderboard-star.svg") no-repeat left center;
  padding: 5px 0px 5px 40px;
  list-style: none;
  margin: 0;
  font-size: 14px;
}

/* @media only screen and (max-width: 780px) {
	.bootstrapisoauth .mobile-view {
		display: block;
	}
	.bootstrapisoauth .web-view {
		display: none;
	}
} */
.myPerformance {
  border-radius: 5px;
  background-color: #f4f5f4;
}
.myPerformance .heading {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  border-bottom: 1px solid #dadce0;
  line-height: 2;
}
.myPerformance .myscoretxt {
  font-size: 14px;
  font-weight: normal;
  color: #000;
}
.myPerformance .myscorevalue {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #000;
}
.publicPrivatebtn {
  color: #ffffff !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  width: 100%;
  border-radius: 0px !important;
  font-weight: normal !important;
  box-shadow: none;
}
.bootstrapisoauth .publicPrivatebtn:hover {
  color: #ffffff !important;
}
.publicPrivateActivebtn .publicPrivatebtn:hover {
  color: #000 !important;
}
.publicPrivateActivebtn {
  background-color: #f44d5e !important;
  color: #fff !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  width: 100%;
  border-radius: 2px !important;
  font-weight: 500 !important;
  box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 991px) {
  .bootstrapisoauth .mobile-view {
    display: block;
  }
  .bootstrapisoauth .web-view {
    display: none;
  }
}

/* .MuiPagination-ul li{
	background-color: #fff;
} */

.MuiPagination-ul button:hover,
button:focus {
  outline: none !important;
}

.MuiPagination-ul button:hover {
  background-color: #fff;
}
.leaderboard th:first-child,
.leaderboard td:first-child {
  border-radius: 0px;
  -moz-border-radius: 0px;
}

.leaderboard th:last-child,
.leaderboard td:last-child {
  border-radius: 0px;
  -moz-border-radius: 0px;
}
.leaderboard thead {
  background: #303242;
  border: 1px solid #303242;
  color: #fff;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}
.leaderboard th,
.leaderboard td {
  padding: 16px 11px;
}
.leaderboard tbody tr:nth-child(odd) {
  background-color: rgb(244, 244, 244);
}
.darkTheme .dark-leaderboard tbody tr {
  background-color: transparent;
  border-bottom: 1px solid #263559;
}
.darkTheme .dark-leaderboard tbody {
  border: 1px solid #263559;
  border-bottom: none;
}
.leaderboard tbody::before {
  all: unset;
}
.darkPublicActive {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
  border-radius: 50px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
  padding: 8px 26px !important;
}
.darkPublic {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
  padding: 8px 26px !important;
}
.darkPrivateActive {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
  border-radius: 50px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
  padding: 8px 26px !important;
}
.darkPrivate {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
  padding: 8px 26px !important;
}
.privatepublicbtnHld {
  display: flex;
  background: #303242;
  border-radius: 50px;
  width: fit-content;
}
.lightprivatepublicbtnHld {
  display: flex;
  background: #f4f4f4;
  border-radius: 50px;
  width: fit-content;
}
.Public,
.Private {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #757575 !important;
  padding: 8px 26px !important;
}
.PrivateActive,
.PublicActive {
  background: #f44d5e !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
  padding: 8px 26px !important;
}
.darkTheme .darklederborad-head {
  background: #303242;
  border: 0.5px solid #2f3242;
  padding: 16px 0;
  border-radius: 0;
}
.leaderboardHld {
  border: 1px solid #303242;
  /* border-bottom: none; */
}
.darkmyPerformance {
  background: #262938;
  border: 0.5px solid #2f3242;
  border-radius: 5px;
}
.darkmyPerformance .heading {
  border-bottom: 1px solid #303242;
  color: #b5c0d3;
}
.darkmyPerformance .myscoretxt {
  color: #b5c0d3;
}
.darkmyPerformance .myscorevalue {
  color: #ffffff;
}
@media only screen and (max-width: 768px) {
  .leaderboard {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
}
.profileHoverCardCss {
  z-index: 1;
  left: 44px;
  top: -10px;
}
/*New LeaderBoard*/
.dark-hackathon-leaderboard-head {
  background: #151d38;
  display: flex;
  align-items: flex-end;
  /* justify-content: space-between; */
  border-radius: 5px 5px 0px 0px;
}
.dark-leaderboard-hackathon-header-text {
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
}
.leaderboard-strip-latest {
  background: rgba(47, 56, 77, 0.2);
  min-width: 126px;
  min-height: 54px;
}
.leaderboard-strip {
  min-width: 126px;
  min-height: 54px;
}
.leaderboard-strip-best {
  background: rgba(47, 56, 77, 0.2);
  min-width: 126px;
  min-height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dark-table-body {
  border-bottom: 0.5px solid #263559;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  background: #151d38;
  border-left: 0.5px solid #263559;
  border-right: 0.5px solid #263559;
  border-bottom: 0.5px solid #263559;
}
.leaderboard-down-img {
  max-width: 33px !important;
  min-width: 33px !important;
}
.light-table-body {
  border-bottom: 0.5px solid #d9d9d9;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  border-left: 0.5px solid #d9d9d9;
  border-right: 0.5px solid #d9d9d9;
  border-bottom: 0.5px solid #d9d9d9;
}
.dark-hackathon-chat-notification {
  background: #0fd68d;
  border: 1px solid #ffffff;
  border-radius: 50px;
  left: 24px;
  top: 24px;
}
.leaderboard-strip-body {
  margin-left: 72px;
  padding: 15px 0px;
}
.leaderboard-strip-dimension {
  min-width: 126px;
  min-height: 54px;
}
.leaderboard-strip-body-two {
  margin-left: 72px;
  padding: 15px 0px;
}
.dark-leader-inc-text {
  font-weight: 700;
  font-size: 12px;
  color: #0fd68d;
  position: absolute;
  left: 10px;
  top: 1px;
}
.dark-leader-inc-text img {
  vertical-align: inherit;
}
.dark-leaderboard-shadow {
  min-width: 52px;
}
.hackathon-user-chat-section .user-picture {
  width: 34px;
  height: 34px;
  border-radius: 50px;
}
.dark-leader-hackathon-user-text {
  font-weight: 600;
  font-size: 14px;
  color: #b5c0d3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.teamsHld .dark-leader-hackathon-user-text a{
  color: #000000;
}
.leader-board-username-section {
  max-width: 200px;
  min-width: 200px;
}
.mobile-leader-board-username-section {
  max-width: 200px;
  min-width: 175px;
}
.light-leader-hackathon-user-text {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 148px;
}
.dark-latest-text {
  font-weight: 600;
  font-size: 14px;
  color: #b5c0d3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width: 100px; */
  text-align: center;
}
.light-latest-text {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 64px;
}
/* .dark-table-body::before {
  background: linear-gradient(
    90deg,
    rgba(255, 220, 123, 0.24) 0%,
    rgba(48, 50, 66, 0) 23.58%
  );
  mix-blend-mode: normal;
  content: '';
} */
/*New LeaderBoard*/
.leaderboard-card-light {
  border: 0.5px solid #D9D9D9;
  border-top: none;
  min-height: 50px;
  background: #ffffff;
}
.leaderboard-card {
  border: 0.5px solid #263559;
  background: #151d38;
  border-top: none;
  min-height: 50px;
}
.rankGradient1 {
  background: linear-gradient(
    90deg,
    rgba(234, 168, 94, 0.24) 0%,
    rgba(48, 50, 66, 0) 30.08%
  );
  mix-blend-mode: normal;
  border-top: none;
}
.rankGradient2 {
  background: linear-gradient(
    90deg,
    rgba(167, 177, 201, 0.24) 0%,
    rgba(48, 50, 66, 0) 29.75%
  );
  mix-blend-mode: normal;
  border-top: none;
}
.rankGradient3 {
  background: linear-gradient(
    90deg,
    rgba(234, 168, 94, 0.24) 0%,
    rgba(48, 50, 66, 0) 30.08%
  );
  mix-blend-mode: normal;
  border-top: none;
}
.rankGradient1::before,
.rankGradient2::before,
.rankGradient3::before {
  content: "";
  width: 4px;
  height: 26px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(
    180deg,
    #fff5a8 -29.1%,
    #bd8100 154.47%,
    #e0be58 420.93%,
    #f1dc83 563.05%
  );
  opacity: 0.2;
  position: absolute;
}
.dark-fire-lottie {
  height: 26px !important;
  width: 100px !important;
}
.dark-leaderboard-date-text {
  font-weight: 500;
  font-size: 14px;
  color: #b5c0d3;
  /* min-width: 117px;
  max-width: 117px; */
}
.light-leaderboard-date-text {
  font-weight: 500;
  color: #757575;
  font-weight: 500;
  font-size: 12px;
  min-width: 117px;
  max-width: 117px;
}
.LbPosition {
  /* min-width: 36px; */
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
}
.light-LbPosition {
  /* min-width: 36px; */
  text-align: center;
  color: #000000;
  font-weight: 700;
  font-size: 12px;
}
.rank-image {
  vertical-align: initial !important;
}
.leaderboard-username-section {
  max-width: 259px;
  min-width: 259px;
}
.leaderboard-download {
  margin-left: 8px !important;
}
.hackathon-leaderboard-teams img {
  position: absolute;
  border-radius: 50px;
  min-width: 32px;
  max-height: 32px;
  border: 4px solid black;
  top: -15px;
}
.teams-img1 {
  left: 20px;
}
.teams-img2 {
  left: 44px;
}
.teams-img3 {
  left: 64px;
}
.teams-img4 {
  left: 84px;
}
.leaderboard-teams-section {
  max-width: 182px;
  min-width: 182px;
}
.leaderboard-teams-section-rankers {
  max-width: 178px;
  min-width: 178px;
}
.dark-leaderboard-current {
  background: #07091d;
  border: 0.5px solid #786bf9;
}
.leaderborad-memeber-head {
  min-width: 113px;
  max-width: 113px;
}
.light-leaderboard-current {
  background: #feebed;
  border: 1px solid #f44d5e;
}
.dark-currentuser-text {
  font-weight: 700;
  font-size: 12px;
  color: #07091d;
  top: 4px;
  left: 20px;
}
.light-currentuser-text {
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  top: 4px;
  left: 20px;
}
.dark-user-rank-section {
  max-width: 67px;
  min-width: 67px;
}
.dark-user-Ranker-section {
  max-width: 67px;
  min-width: 67px;
  text-align: center;
}
.dark-header-taems-section {
  min-width: 320px;
  max-width: 320px;
}

.light-table-header {
  background: #000000;
  display: flex;
  align-items: flex-end;
  /* justify-content: space-between; */
  border-radius: 5px 5px 0px 0px;
}
.light-hackathon-leaderboard-teams img {
  position: absolute;
  border-radius: 50px;
  min-width: 32px;
  max-height: 32px;
  border: 4px solid #ffffff;
}
.leader-applaud-section {
  background: rgba(61, 78, 149, 0.2);
  mix-blend-mode: normal;
  border: 0.5px solid #263559;
  border-radius: 50px 0px 0px 50px;
  padding: 4px 15px 4px 23px;
}
.light-leader-applaud-section {
  background: #f7f7f7;
  mix-blend-mode: normal;
  border: 0.5px solid #d9d9d9;
  border-radius: 50px 0px 0px 50px;
  padding: 4px 15px 4px 23px;
}
.light-leader-applauded-text {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}
.leader-applauded-text {
  font-weight: 500;
  font-size: 14px;
  color: #b5c0d3;
}
/*Mobile*/
.mobile-header-taems-section {
  min-width: 174px;
  max-width: 218px;
}
.mobile-leaderboard-username-section {
  max-width: 259px;
  min-width: 174px;
}
.mobile-leaderboard-strip-latest {
  background: #212841;
  mix-blend-mode: screen;
  min-width: 73px;
  min-height: 54px;
}
.mobile-leaderboard-strip-best {
  background: #182744;
  mix-blend-mode: screen;
  min-width: 77px;
  min-height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*Mobile*/
