.hackathon-banner-img-genpact img{
  max-width: 1200px;
}
.hackathon-banner-img-genpact-mob img{
  width: 100%;
}
.hackathonBannerCntr {
  text-align: center;
  padding: 5px 0 0px 0;
}

.hackathonBannerCntr .hackathonInfoList {
  margin-bottom: 40px;
  margin-top: 25px;
}

.hackathonInfoList .dot {
  width: 3px;
  height: 3px;
  border-radius: 50px;
  background: #B5C0D3;
  line-height: 0;
}

.light-bg .hackathonInfoList .dot {
  background: #757575;
}

.hackathonBannerCntr .hackathon-timer {
  height:34px;
}
.hackathon-registration-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  background: linear-gradient(283.18deg, #7554EC -10.6%, #E75996 63.41%, #FF8841 97.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.hackathonBannerCntr .hackathonPrize {
  background: #303043;
  border: 2px solid #39394c;
  border-radius: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #b5c0d3;
  display: inline-block;
  padding: 2px 20px;
}

.hackathonBannerCntr .lightHackathonPrize {
  background: #F9F9F9 !important;
  border: 2px solid #E1E1E1 !important;
  color: #000 !important;
}

.collabhld {
  font-weight: 400;
  font-size: 20px;
  color: #b5c0d3;
}

.light-bg .collabhld {
  font-weight: 400;
  font-size: 20px;
  color: #757575;
}

.collabhld .incollabLogo {
  /* width: 120px; */
  height: 40px !important;
  object-fit: contain;
}

.hackathonBannerCntr .dark-button {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
  border-radius: 50px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 12px 24px;
}

.light-bg .dark-button {
  background: linear-gradient(121.13deg, #DC4E5A 20.17%, #E2595B 31.41%, #F57F5F 71.17%, #FD8E61 92.78%);
}

.hackathonSub {
  padding: 40px 20px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .hackathonBannerCntr .dark-button,
  .light-bg .dark-button {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    width: 90%;
  }
  .hackathonBannerCntr .hackathon-float-ask-btn{
    position: fixed;
    right: 30%;
    transform: translate(0, -50%);
    top: unset;
    bottom: 20px;
    z-index: 999;
    border-radius: 50px;
    background-image: url(assets/img/MachineAi/askBtn.svg);
    border: none;
    font-weight: 700;
    font-size: 11.6683px;
    color: #FFFFFF;
    width: 137px;
    height: 32px;
  }
}
.hackathonBannerCntr .hackathon-float-ask-btn div{
  width: 70px;
}