.bootstrapisoauth .text-secondary {
  font-size: 14px;
  font-weight: 500;
  color: #000 !important;
  line-height: 2.2;
}
.shareHack {
  font-size: 13px;
  color: #000;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #f76f78;
  background-image: none;
  flex: 1;
  padding: 0 0.5em;
  color: #fff;
  cursor: pointer;
  font-size: 1em;
  font-family: "Open Sans", sans-serif;
}
select::-ms-expand {
  display: none;
}
.select {
  position: relative;
  display: flex;
  width: 15em;
  height: 30px;
  line-height: 2;
  background: #e8505c;
  overflow: hidden;
  border-radius: 0.25em;
  color: #fff;
  margin-left: 40px;
}
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #e9505c;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}
.select:hover::after {
  color: #fff;
}

.select option {
  background: #fff;
  color: #000;
}
.HiringModal .MuiTypography-h6 {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #0f0506;
  text-transform: capitalize;
}
.HiringModal .MuiDialog-paperWidthMd {
  background-color: #f9f9f9;
}
.HiringModal .MuiDialogContent-dividers {
  padding: 30px 60px;
}
.hiring-radio-btns {
  display: flex;
}
.HiringModal .MuiDropzoneArea-root {
  min-height: auto;
}
.HiringModal .MuiDropzoneArea-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: #0f0506;
}
.HiringModal .MuiDropzoneArea-textContainer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  margin-top: 20px;
}
.HiringModal .MuiFormGroup-root {
  flex-direction: row;
}
.HiringModal .current-employed {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #455461;
}
.HiringModal .current-employed span {
  font-weight: bold;
}
.HiringModal .form-group {
  display: flex;
  flex-direction: column;
}
.HiringModal .form-group label {
  font-size: 14px;
  line-height: 1.5;
  color: #455461;
}
.HiringModal .form-group .form-control {
  border-radius: 5px;
  border: solid 1px #dfdfdf;
  padding: 10px;
  margin-top: 8px;
}
.HiringModal .MuiFormControlLabel-label {
  font-size: 16px;
  line-height: 1.5;
  color: #455461;
}
.dark-hiring-acceptance-form .MuiFormControlLabel-label {
  font-size: 16px;
  line-height: 1.5;
  color: #b5c0d3;
}
.HiringModal .TnCTxt {
  font-size: 16px;
  line-height: 1.5;
  color: #455461;
}
.HiringModal .MuiDropzoneArea-root {
  border: dashed 2px #dfdfdf;
  background-color: #f4f4f4;
}
/* .update-profile .form-group .form-control {
  border-radius: 5px;
  border: solid 1px #dfdfdf;
  padding: 10px;
  margin-top: 8px;
  width: 500px;
} */
.dark-update-profile .form-group .form-control {
  border-radius: 50px;
  border: solid 1px #343847;
  background: #262938;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
.dark-update-profile .form-group .form-control::placeholder {
  color: #b5c0d3;
  font-size: 14px;
  font-weight: 400;
}
/* .update-profile .form-group {
  display: flex;
  flex-direction: column;
  margin: 20px;
  position: relative;
}
.update-profile .form-group label {
  font-size: 15px;
  line-height: 1.5;
  text-align: left;
  color: #455461;
} */
.dark-update-profile .form-group label {
  font-size: 14px;
  font-weight: 400;
  color: #b5c0d3;
}
.err-msg {
  font-size: 12px;
  color: #e9505c;
  margin: 6px 0;
}
.err-control {
  border: solid 1px #e9505c !important;
}
.update-profile .MuiDialogContent-dividers {
  padding: 20px 50px 30px;
}
.update-profile .btnsCntr {
  text-align: center;
  margin: 40px 0 0;
}
.update-profile .MuiTypography-h6 {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  color: #0f0506;
}
.update-profile .MuiDialog-paperWidthMd {
  max-width: 700px;
}
.bootstrapisoauth .btn:hover {
  color: #fff !important;
  text-decoration: none;
}
.showdescription {
  display: flex;
  justify-content: center;
  align-items: center;
}
.showdescription .formPopupHld {
  background: #ffffff;
  padding: 20px 40px;
  border-radius: 10px;
  min-width: 700px;
}
.formPopupHld .form-popupTitle {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #000000;
}
.formPopupHld .popup-lable {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #1a1a1a;
}
.formPopupHld .form-control {
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  text-transform: none;
}
.formPopupHld .privacyterms {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #848e96;
}
.formPopupHld button {
  border-radius: 4px;
  border: transparent;
  color: #ffffff;
  padding: 8px 60px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
.formPopupHld .MuiDropzoneArea-root {
  min-height: 125px;
  border-radius: 4px;
  border-color: #c4c4c4;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.formPopupHld .MuiDropzoneArea-root p {
  margin: 0;
}
.stepperHead .stephead {
  background: #e2e2e9;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  text-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #3c3e4d;
  display: flex;
  justify-content: center;
}
.stepperHead span {
  width: 50px;
  height: 1px;
  background: #e2e2e9;
}
/* .stepperHead .stephead:first-child::after{
  content: "";
  width: 150px;
  height: 1px;
  background: #E2E2E9;
} */
.cityDropdown {
  background: #ffffff;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  z-index: 2;
  overflow: auto;
  top: 75px;
  height: 150px;
  width: 100%;
}
.cityDropdown p {
  padding: 8px 10px;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}
.cityDropdown p:hover {
  background: #f2f3f4;
  color: #000000;
}
.stepperHead .activestep {
  background: #3c3e4d;
  color: #ffffff;
}
.dark-Nav-hld {
  border-bottom: 1px solid #263559;
}
.dark-active-nav {
  color: #ffffff;
  position: relative;
}
.dark-leaderboard-active-nav {
  background: #e4950f !important;
  color: #ffffff;
}
.dark-leaderboard-active-nav p {
  color: #ffffff !important;
}
.dark-active-nav::after {
  content: " ";
  width: 50px;
  height: 3px;
  position: absolute;
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
  border-radius: 50px;
  bottom: -2px;
  transform: translate(-50%, -50%);
  left: 50%;
}
.dark-active-nav .darkText {
  color: #ffffff;
}
.bootstrapisoauth .hackathoncenterTabs .darkLeaderboard {
  background: linear-gradient(
    270.29deg,
    rgba(121, 133, 160, 0.4) -3.7%,
    rgba(21, 29, 56, 0.4) 155.6%
  );
  border: 1px solid #3f4c6f;
  box-shadow: 0px 10px 30px rgba(7, 21, 59, 0.1);
  border-radius: 50px;
  padding: 3px 15px;
}
.hackathoncenterTabs .leaderboard-menu-item-text {
  background: linear-gradient(90.01deg, #896ced 0.01%, #0bdab1 99.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.darkTheme .darkParticipateCntr {
  border: none;
  margin-top: 30px;
}
.darkTheme .darkParticipateCntr .calender-icon {
  filter: invert(84%) sepia(20%) saturate(210%) hue-rotate(179deg)
    brightness(90%) contrast(87%);
}
.darkTheme .darkParticipateCntr .evntDuration {
  color: #ffffff;
}
.darkTheme .darkParticipateCntr .evntDate {
  color: #ffffff;
}
.darkTheme .darkParticipateCntr .light-icon {
  filter: invert(84%) sepia(20%) saturate(210%) hue-rotate(179deg)
    brightness(90%) contrast(87%);
}
.darkTheme .darkParticipateCntr .beginnerTxt,
.darkTheme .darkParticipateCntr .codetxt,
.darkTheme .darkParticipateCntr .price {
  color: #b5c0d3;
}
.darkTheme .darkParticipateCntr button {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
  border-radius: 50px !important;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.darkTheme .dark-telegramHld {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
  border-radius: 5px;
  text-align: center;
}
.darkTheme .dark-telegramHld a {
  background: #ffffff;
  color: #000000;
  font-weight: 400;
}
.darkTheme .dark-telegramHld p {
  color: #ffffff;
}
/* .dark-hiring-acceptance-form .MuiDialog-paper {
  background: #262938;
  border: 1px solid #343847;
  border-radius: 15px;
} */
@media only screen and (max-width: 600px) {
  select {
    width: 10em;
  }
}
@media (max-width: 575.98px) {
  .HiringModal .MuiDialogContent-dividers,
  .update-profile .MuiDialogContent-dividers {
    padding: 10px;
  }
  /* .update-profile .form-group .form-control {
    width: auto;
  }
  .update-profile .form-group {
    margin: 10px 0;
  } */
  .bootstrapisoauth .hackathoncenterTabs .darkLeaderboard{
    margin: 8px 0px;
  }
  .hackathoncenterTabs .leaderboard-menu-item-text{
    font-size: 12px;
  }
  .dark-active-nav::after {
    bottom: -2px;
    left: 20px;
    transform: none;
  }
}
.profileHoverCard {
  z-index: 9999 !important;
  left: 0px !important;
  right: -20px !important;
}
