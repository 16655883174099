.genpact-google-popup .popup-lable {
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 5px;
    padding-left: 4px;
}
.lightTheme .genpact-google-popup .popup-lable {
    color: #8B8E98;
}

.lightTheme .genpact-google-popup .form-control:focus {
    border: 1px solid transparent;
    box-shadow: 0px 0px 0px 2px #242424;
    background-color: transparent;
}
.darkTheme .genpact-google-popup .form-control:focus{
    /* border: 2px solid #263559; */
    border: 1px solid #007bff;
    /* background:#263559; */
    background-color: transparent;
}
.darkTheme .genpact-google-popup .form-control{
    background:#263559;
}

.genpact-google-popup .form-control {
    border-radius: 9px;
    outline: none;
    height: 40px;
    font-size: 12px;
    padding: 0 0 0 16px;
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
    margin-bottom: 15px;
}

.lightTheme .genpact-google-popup .form-control {
    background-color: #F2F2F2;
    border: 1px solid #e5e5e500;
}

.genpact-google-popup .form-control::placeholder {
    font-size: 12px;
    font-weight: 400;
}

.lightTheme .two-side-popup,
.darkTheme .genpact-google-popup.two-side-popup {
    min-width: 600px;
    border-radius: 26px;
    box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.genpact-google-popup .termsConditionText {
    font-size: 12px;
    line-height: 16px;
}

.genpact-google-popup .selectedfilebox,
.genpact-google-popup .dark-selectedfilebox,
.genpact-google-popup .contactUsZone ,.darkTheme .genpact-google-popup .darkDropZone {
    height: 177px;
    margin-bottom: 10px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px dotted #a3a3a3;
    border-radius: 5px;
    background-image: none;
}
.genpact-google-popup .overview{
    font-size: 14px;
    font-weight: 400;
    color: #2e2e2e;
}
.genpact-google-popup .formatText{
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    color: #a3a3a3;
}

.selectedfilebox .submissionFileImg{
    height: 32px;
}
.chooseFileBtn{
    background-color: rgb(27, 27, 27);
    font-size: 14px;
    color: rgb(255, 255, 255);
    border-radius: 50px;
    border: none;
    padding:3px 20px;
}
.darkTheme .chooseFileBtn{
    background: #ffffff;
    color: rgb(27, 27, 27);
}