.title-medium-submission {
  font-size: 18px;
  font-family: Roboto;
  font-weight: 500;
  color: #000;
}
.text-medium {
  font-size: 20px;
  font-family: Roboto;
  font-weight: normal;
  color: #000;
}

.file-submission .MuiDropzoneArea-root {
  min-height: 100px;
  background-color: #f9f9f9;
  border-radius: 10px;
  border: dashed 2px #dfdfdf;
}
.info-text {
  color: #455461;
  font-size: 16px;
}
.error-text {
  font-size: 14px;
  color: #f44d5e;
}
.success-text {
  font-size: 14px;
  color: rgb(24, 173, 24);
}
.waiting-text {
  font-size: 14px;
  color: orange;
}

.selectedfilebox {
  min-height: 220px;
  background-color: #f9f9f9;
  border-radius: 10px;
  border: dashed 2px #dfdfdf;
  width: 100%;
  text-align: center;
  padding-top: 5%;
}

::placeholder {
  color: #bfbfbf;
  font-size: 16px;
  text-transform: none;
}

.submission-dialog {
  text-align: center;
  min-width: 500px;
}

.success-title {
  font-size: 24px;
  font-family: Roboto;
  font-weight: 500;
  color: #000;
  margin-top: 10px;
}
.submission-msg {
  font-size: 16px;
  font-family: Roboto;
  font-weight: normal;
  color: #000;
}

.persistent-error {
  min-width: 250px;
  width: auto;
  background-color: #fff4f4;
  color: #e9505c;
  border: solid 1px #f1a3ab;
  border-radius: 5px;
}

.persistent-success {
  min-width: 200px;
  width: auto;
  background-color: #f5fff8;
  color: #84d69a;
  border: solid 1px #84d69a;
  border-radius: 5px;
}

.toast-success {
  color: #84d69a;
  font-size: 14px;
}

.toast-error {
  color: #e9505c;
  font-size: 14px;
}

.MuiCircularProgress-colorSecondary {
  color: #f44d5e;
}

.visual-assessment-banner {
  width: 100%;
  position: relative;
  background-color: #333333;
  background: url("../../../../assets/img/Submission/external-link-dashboard.svg")
    center center no-repeat;
  position: relative;
  padding: 50px 40px;
  margin-bottom: 3rem;
  color: #ffffff;
}
.visual-assessment-banner h1 {
  font-size: 34px !important;
  font-weight: bold !important;
  color: #fff;
}
.visual-assessment-banner p {
  font-family: Roboto;
  font-size: 24px;
  color: #fff;
  margin-bottom: 26px !important;
}
.upload-success {
  border-radius: 10.4px;
  border: solid 1.6px #efc8cb;
  background-color: #feedef;
  margin: 20px 0;
  text-align: center;
  padding: 6px 0;
  font-size: 18px;
  font-weight: 500;
  color: #e9505c;
}
.upload-success p {
  font-size: 16px;
  font-weight: 500;
  color: #e9505c;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 0 !important;
}
.upload-success  img {
  width: 25px;
}
@media only screen and (max-width: 575px) {
  .submission-dialog {
    min-width: 250px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 479px) {
  .visual-assessment-banner {
    background: #000;
  }
}

.embed-url:focus {
  outline: none !important;
  border-color: #ced4da !important;
}
