.header-container {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  background-color: #ffffff;
  z-index: 9;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
}
/* added for genpact  gogle hackathon */
.multi-header-container{
  top: 47px;
}
.darkHeader-container {
  background: #09112e;
  box-shadow: 0px 20px 40px rgb(0 0 0 / 20%);
}
.mh-logo {
  width: 200px;
  margin-right: 20px;
}
.header-container .headerItems {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin-right: 20px;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
}
.darkTheme .headerItems {
  color: #B5C0D3;
}
.header-container .headerItems:hover {
  color: #f44d5e;
}
.darkTheme .headerItems:hover {
  color: #6641d7;
}
.header-container .activeHeaderItem {
  color: #000000;
  /* font-weight: 700; */
}
.darkTheme .activeHeaderItem {
  color: #ffffff;
}
.activeBar {
  width: 55px;
  height: 3px;
  position: absolute;
  bottom: -23px;
  left: 17%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.activeHeaderItem::after {
  content: "";
  background: url("assets/img/landingpage/multi-color.gif");
  background-size: 55px;
  width: 55px;
  height: 3px;
  position: absolute;
  bottom: -21px;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.leaderboardbutton {
  background: linear-gradient(
    272.59deg,
    #f2f3f4 -36.2%,
    rgba(242, 243, 244, 0) 108.64%
  );
  border: 1.5px solid #ffffff;
  filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.1));
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0px 10px 30px 0px #0000001a;
  transition: all 0.2s ease-in-out 0s;
}
.leaderboardbutton:hover {
  background: #ffffff;
  border: 1.5px solid #f2f3f4;
}
.darkTheme .leaderboardbutton {
  background: linear-gradient(
    270.29deg,
    rgba(121, 133, 160, 0.4) -3.7%,
    rgba(21, 29, 56, 0.4) 155.6%
  );
  border: 1px solid #3f4c6f;
  transition: all 0.3s;
  position: relative;
}
.darkTheme .leaderboardbutton:hover {
  background: #333c58;
}
.darkTheme .leaderboardbutton:before {
  content: "";
  position: absolute;
  left: 0em;
  width: 2em;
  height: 100%;
  top: 0;
  transition: transform 0.4s ease-in-out;
  background: linear-gradient(
    to right,
    transparent 1%,
    rgba(121, 133, 160, 0.1) 40%,
    rgba(121, 133, 160, 0.1) 60%,
    transparent 100%
  );
  transform: rotate(30deg);
  animation-duration: 0.6s;
  animation-name: leaderboardBtnANimation;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes leaderboardBtnANimation {
  from {
    transform: translateX(0em);
  }
  to {
    transform: translateX(9em);
  }
}
.darkTheme .leaderboardbutton:hover:before {
  animation: 0;
}
.leaderboardbutton img {
  width: 14px;
  height: 14px;
  vertical-align: text-top;
  margin-right: 4px;
}
.leaderboardbutton span {
  background: linear-gradient(276.73deg, #fccf31 -27.06%, #f55555 41.31%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 600;
  font-size: 14px;
}
.darkTheme .leaderboardbutton span {
  background: linear-gradient(90.01deg, #896ced 0.01%, #0bdab1 99.24%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.EnterpriseBtn {
  border: 0.5px solid #d9d9d9;
  border-radius: 50px;
  padding: 8px 20px;
  margin-right: 20px;
  cursor: pointer;
  background: transparent;
}
.darkTheme .EnterpriseBtn {
  background: linear-gradient(#09112e, #09112e) padding-box,
    linear-gradient(45deg, #896ced, #0bdab1) border-box;
  border: 0.5px solid transparent;
}
.EnterpriseBtn img {
  margin-right: 4px;
}
.EnterpriseBtn span {
  background: linear-gradient(92.31deg, #af245f 4.46%, #fb6d7e 101.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
  font-size: 14px;
}
.darkTheme .EnterpriseBtn span {
  background: linear-gradient(90.01deg, #896ced 0.01%, #0bdab1 99.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.darkTheme .EnterpriseBtn span:hover {
  background: linear-gradient(90.01deg, #0bdab1 0.01%, #896ced 99.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.signInBtn {
  background: linear-gradient(88.78deg, #f04f5e 20.65%, #e97f3d 98.95%);
  border-radius: 50px;
  font-weight: 700;
  font-size: 14px;
  border: none;
  padding: 10px 20px;
  color: #ffffff;
  cursor: pointer;
}
.signInBtn:hover {
  background: linear-gradient(88.78deg, #e97f3d 20.65%, #f04f5e 98.95%);
}
.darkTheme .signInBtn {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
}
.darkTheme .signInBtn:hover {
  background: linear-gradient(92.97deg, #6540d7 10.8%, #445eb7 94.39%);
}
.searchicon {
  margin-right: 20px;
  cursor: pointer;
}
.premiumHld {
  cursor: pointer;
}
.premiumHld .premiumIcon {
  width: 30px;
  height: 30px;
  background: #fff8e3;
  border-radius: 10px;
  margin-right: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.darkTheme .premiumHld .premiumIcon {
  background: #2a355c;
}
.premiumHld .premiumText {
  font-weight: 700;
  font-size: 14px;
  margin-right: 20px;
  transition: all 0.2s ease-in-out 0s;
  color: #000000;
}
.premiumHld .premiumText:hover {
  color: #f44d5e;
}
.darkTheme .premiumHld .premiumText {
  color: #ffffff;
}
.darkTheme .premiumHld .premiumText:hover {
  color: #6641d7;
}
.header-container .align-items-center {
  display: flex;
  align-items: center;
}
.notifyMsghld {
  border: 0.5px solid #c9c9c9;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
}
.darkTheme .notifyMsghld {
  border: 0.5px solid #2a355c;
}
.ActivenotifyMsghld {
  background: rgba(244, 77, 94, 0.1);
  border: 0.5px solid transparent;
}
.darkTheme .ActivenotifyMsghld {
  background: #2a355c;
}
.ActivenotifyMsghld::before {
  content: "";
  background: #f44d5e;
  width: 28px;
  height: 2px;
  position: absolute;
  bottom: -18px;
  border-radius: 5px 5px 0 0;
}
.notificationHeightlisthld {
  height: calc(100vh - 140px);
  overflow: auto;
}
.darkTheme .ActivenotifyMsghld::before {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
}
.currentuserHld {
  background: #f2f3f4;
  border-radius: 50px;
  width: 60px;
  cursor: pointer;
}
.darkTheme .currentuserHld {
  background: #263559;
}
.currentuserHld .userPicture {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: 2px solid #ffffff;
}
.darkTheme .currentuserHld .userPicture {
  border: 2px solid #263559;
}
.currentuserHld .chevron-down {
  padding-left: 8px;
}
.currentuserHld .chevron-up {
  margin-left: 8px;
  transform: rotate(180deg);
}
.newNotificationnotify {
  position: absolute;
  right: -3px;
  content: "";
  width: 12px;
  height: 12px;
  background: #f44d5e;
  border-radius: 50px;
  z-index: 099999;
  top: -3px;
  border: 2px solid #ffffff;
}
.profdropOptions .noOfcoins {
  position: absolute;
  top: 34px;
  background: linear-gradient(135deg, #595959 57.38%, #5a5a5a 86.72%);
  color: #fff;
  border-radius: 50px;
  padding: 0px 6px;
  font-size: 10px;
  line-height: 14px;
  transform: translate(-50%, -50%);
  left: 50%;
}
.darkTheme .profdropOptions .noOfcoins {
  background: #ffffff;
  color: #000000;
}
.darkTheme .newNotificationnotify {
  border: 2px solid #161e39;
}
.profileSubOptions:hover {
  background-color: #f2f3f4;
}
.darkTheme .profileSubOptions:hover {
  background-color: #223052;
}
.logo-center {
  margin: 0 auto;
}
@media (max-width: 600px) {
  .darkHeader-container,
  .header-container {
    padding: 0 10px;
  }
}
