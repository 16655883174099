.profiledropdown {
  position: fixed;
  right: 22px;
  width: 300px;
  top: 64px;
  z-index: 9999;
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 20px 40px rgb(0 0 0 / 10%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.profiledropdown .dropDownItem {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  cursor: pointer;
}
.profiledropdown .username {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
}
.profiledropdown .tagline {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #e9ac44;
  background: #fdf7ec;
  border-radius: 5px;
}
.profiledropdown .user-image-container {
  height: 45px;
  width: 45px;
  border-radius: 50px;
}
.username-and-image-hld {
  border-bottom: 0.5px solid #d9d9d9;
}
.profiledropdown .user-email-address {
  font-weight: 500;
  font-size: 14px;
  color: #757575;
  width: 172px;
}
.profiledropdown .signOutbtn {
  background: #f2f3f4;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  display: inline-block;
  font-size: 12px;
}
.profdropOptions {
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}
.profdropOptions:hover {
  background: #e5e5e5;
}
.profdropOptions:hover img {
  transform: scale(1.15);
}
.profdropOptions img {
  transition: all 1.3s;
}
.profdropOptions p {
  font-weight: 500;
  font-size: 11px;
  color: #000000;
  margin-top: 4px;
}
.toggle-container {
  width: 40px;
  /* background-color: #c4c4c4; */
  cursor: pointer;
  user-select: none;
  border-radius: 50px;
  padding: 2px;
  height: 21px;
  position: relative;
}

.dialog-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  left: 21px;
  transition: all 0.3s ease;
  top: 2px;
}
.darkbg {
  background-color: #113449;
}
.lightbg {
  background-color: #fbeeb7;
}
.disabled {
  left: 2px;
}
.globalLeaderboardImg {
  margin-left: -8px;
  width: 32px;
  padding-bottom: 2px;
}
.profileCardbadges{
  background: #F7F7F8;
}
.darkTheme .profileCardbadges {
  background: #263559;
}
.badge-and-user-menu-hld {
  background: #F7F7F8;
}
.darkTheme .badge-and-user-menu-hld {
  background: transparent;
}
/* dark mode */
.darkTheme .profileSubOptions img {
  width: 20px;
  height: 20px;
}
.darkprofiledropdown {
  background: #263559;
  box-shadow: 0px 40px 10px rgb(0 7 38 / 5%);
  border: transparent;
}
.darkTheme .username-and-image-hld {
  border-bottom: 0.5px solid #36456A;
}
.darkTheme .profdropOptions {
  background: rgba(200, 216, 255, 0.1);
}
.darkTheme .profdropOptions:hover {
  background: rgba(200, 216, 255, 0.2);
}
.darkTheme .signOutbtn {
  background: rgba(200, 216, 255, 0.1);
}
