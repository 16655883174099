/* Contains General css */
body {
  transition: background 1s ease-out;
  font-family: "Inter";
  background: #000726;
}

ˀ .searchHld .roundedInput {
  border-radius: 50px;
  background-color: transparent;
  cursor: auto;
}

.searchHld .searchIconInsideInputField {
  position: absolute;
  top: 10px;
  left: 28px;
  width: 25px;
  height: 25px;
}

.marginTop60 {
  margin-top: 60px;
}
/* added for genpact google hackathon */
.marginTopExtended{
  margin-top: 107px;
}

.routesHld {
  min-height: calc(100vh - 80px);
}
.marginTopExtended .routesHld {
  min-height: calc(100vh - 180px);
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.new-topic {
  background-color: #000000;
  color: #fff;
  font-size: 16px !important;
  font-weight: 500 !important;
  border: none;
  border-radius: 50px !important;
  padding: 6px 15px;
  z-index: 9;
}
.btn-new-topic {
  background-color: #f44d5e;
  color: #fff;
  font-size: 14px !important;
  font-weight: 500 !important;
  border: none;
  border-radius: 50px !important;
  padding: 6px 15px;
}
.darkTheme .btn-new-topic {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
}

.discseperator {
  font-size: 12px;
  color: #263559;
  font-weight: normal;
}

.routesHld .paragraph,
.routesHld .paragraph,
.routesHld .paragraph > p,
ul,
li,
ol,
li .routesHld .paragraph > p,
ul,
li,
ol,
li {
  font-size: 15px;
  color: #111111;
  font-weight: 400;
  line-height: 25px;
}

.routesHld .paragraph img {
  max-width: 100%;
  height: auto;
}

.routesHld .paragraph h1,
.routesHld .paragraph h2,
.routesHld .paragraph h3 {
  font-size: 18px;
}

.routesHld .paragraph > h5,
.routesHld .paragraph > h6 {
  font-size: 15px;
  color: #111111;
  font-weight: 500;
  line-height: 25px;
  text-transform: none;
}

.routesHld .paragraph > ul li {
  list-style-type: disc;
}

.routesHld .paragraph > ol,
.routesHld .paragraph > ul {
  margin-left: 30px;
}

.routesHld .paragraph > a {
  color: #f44d5e;
}

.routesHld .paragraph ol li a {
  text-align: start;
  text-transform: none;
  display: block;
}

.searchInput .form-control {
  background: #ffffff;
  border: 0.8px solid #d9d9d9;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  padding-left: 40px;
}

.searchInput input::placeholder {
  all: unset;
  font-size: 12px;
}

.searchInput .searchIcon {
  position: absolute;
  z-index: 9;
  top: 10px;
  left: 25px;
  width: 20px;
  height: 20px;
}

.routesHld .primary-cta-button,
.mhcoinspopup .mhcoinspopupCntr .primary-cta-button {
  background: #ef4f5e;
  border-radius: 40px;
  border: transparent;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  padding: 5px 25px;
}

.primary-cta-button:hover > img {
  position: relative;
  animation-name: arrowAnimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.primary-cta-button img {
  vertical-align: initial;
}

.premium-lock-btn {
  background: linear-gradient(111.4deg, #fec411 28.74%, #ffae3e 64.08%);
  border-radius: 40px !important;
  border: none;
  height: 40px;
  width: 160px;
  font-weight: 500;
  font-size: 16px !important;
  color: #ffffff;
}

.hack-premium-lock-btn {
  border: 1px solid #f3ede2 !important;
  background: #ffffff;
  border-radius: 40px !important;
  border: none;
  font-weight: 500;
  font-size: 14px !important;
  color: #e4950f !important;
  padding-right: 12px;
  padding-left: 12px;
  opacity: 1;
  padding-top: 4px;
  padding-bottom: 4px;
}

.dark-hack-premium-lock-btn {
  background: linear-gradient(311.94deg, #e4950f -10.01%, #ffca28 50.79%);
  border-radius: 40px !important;
  border: none !important;
  font-weight: 400;
  font-size: 14px !important;
  color: #e4950f !important;
  padding-right: 12px;
  padding-left: 12px;
  opacity: 1;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #000000 !important;
}

.secondary-btn {
  background: #ffffff;
  border: 1px solid #e1e1e1 !important;
  border-radius: 50px !important;
  border: none;
  height: 40px;
  width: 160px;
  font-weight: 500;
  font-size: 16px !important;
  color: #595959;
}

.mhcoinspopup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mhcoinspopup .mhcoinspopupCntr {
  background: #ffffff;
  border-radius: 5px;
  max-width: 400px;
  min-width: 350px;
  padding: 20px;
}

.mhcoinspopup .mhcoinspopupCntr {
  background: #ffffff;
  border-radius: 5px;
  max-width: 400px;
  min-width: 350px;
  padding: 20px;
  z-index: 1;
}

.mhcoinspopup .mhcoinspopupCntr .noOfCoinsHld {
  background: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  border: 1px solid;
  border-radius: 50px;
  width: 150px;
  height: 70px;
}

.mhcoinspopup .mhcoinspopupCntr .noOfCoinsHld span {
  font-weight: 900;
  font-size: 26px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  display: block;
}

.routesHld .validationErrormsg {
  color: red;
  font-size: 12px;
}

/* mh wordle card */
.mhWordleCard {
  background: url("/src/assets/img/DailyWordle/wordleCardBack.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000000;
  mix-blend-mode: normal;
  box-shadow: inset 0px 10px 4px rgb(255 255 255 / 2%);
  border-radius: 10px 10px 10px 10px;
  text-align: left;
}

.mhwordleCardHome {
  border: 0.5px solid #c4c4c4;
  border-radius: 10px 10px 0px 0px;
  background-color: #ffffff;
}

.mhWordleCard .mhWordleHeaderText {
  font-weight: 900;
  font-size: 24px;
  line-height: 20px;
  color: #ffb936;
  padding: 15px;
  border-bottom: 0.5px solid #d9d9d9;
}

.mhwordleCardHome .mhWordleHeaderText {
  color: #ef4f5e;
}

.mhWordleCard .mhWordleBtn {
  background: #ef4f5e;
  border-radius: 50px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 30px;
  border: none;
  text-align: right;
  color: #ffffff;
}

.mhWordleCard .mhWordleCardText {
  font-weight: 700;
  font-size: 28px;
  line-height: 30px;
  padding-left: 27px;
  text-align: left;
  color: #ffffff;
}

.mhwordleCardHome .mhWordleCardText {
  color: #000000;
  font-size: 17px;
}

@keyframes arrowAnimation {
  0% {
    left: 0px;
  }

  50% {
    left: 4px;
  }

  100% {
    left: 0px;
  }
}

.profileCardTopRankersName {
  z-index: 99;
}

.profileCardForDiscussion {
  z-index: 10;
  top: 0px;
  left: 83px;
}

.profileCardForNameDiscussion {
  position: absolute;
  z-index: 10;
  max-width: 290px;
}

.profileCardForHackthonScriptList {
  position: absolute;
  z-index: 99;
  max-width: 290px;
  left: 95px;
  top: 0px;
}

.profileCardForHackthonScriptListName {
  z-index: 10;
  max-width: 290px;
}

.cardFollowBtn > img {
  padding: 5px;
}

.cardMessageBtn > img {
  padding: 5px;
}

.profileCardDiscussionNameHover {
  z-index: 99;
  position: absolute;
  max-width: 290px;
}

.profileCArdHoverFOrSIngleDiscussion {
  z-index: 10;
  top: 0px;
  left: 100px;
}

.profileCardForSingleDiscussion {
  z-index: 10;
  top: 0px;
  left: 75px;
}

.profileCardForSingleDiscussionName {
  z-index: 10;
  max-width: 290px;
}

.betahld {
  position: absolute;
  top: -8px;
  right: -13px;
}

.betahld .beta-badge {
  background: #ffb23d;
  border-radius: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 6px;
  line-height: 20px;
  color: #ffffff;
  padding: 1px 5px;
}

.dropZoneStyles {
  border: 1px dashed #0000001f;
  background-color: #f9f9f9 !important;
  border-radius: 5px;
}

.colorLightInvert {
  -webkit-filter: invert(100%);
  /* Safari/Chrome */
  filter: invert(100%);
}

.lightCardHeader {
  background: #f4f4f4 !important;
}

.lightBorder {
  border: 1px solid #f4f4f4;
}

/* dark mode */
.lightText {
  color: #000;
}

.darkTheme .darkText {
  color: #b5c0d3 !important;
}

.darkTheme .whiteText {
  color: #ffffff !important;
}

.darkTheme .darkLightText {
  color: #7b839b;
}

.darkTheme .darkPurpleText {
  color: #6c6fc3;
}

.darkTheme .dark-border {
  border-bottom: 1px solid #263559;
}

.darkTheme .dark-bg {
  background-color: #000726;
}

.darkTheme .darkBorder {
  border: 0.5px solid #263559;
}

.light-bg .light-hackathon-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 52px;
  text-align: center;
  color: #000000;
}

.dark-bg .dark-hackathon-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 52px;
  text-align: center;
  background: linear-gradient(164.66deg, #ffffff 29.36%, #7b7c83 100.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.dark-bg .dark-hackathon-title::selection {
  color: #ffffff !important;
  background: #435eb7;
  -webkit-background-clip: text;
  -webkit-text-fill-color: white;
  background-clip: none;
}

.darkTheme .dark-card-bg-color {
  background: #262938 !important;
  border-radius: 5px !important;
  border: none !important;
}

.darkTheme .darkInputBox2 {
  border: 1px solid #343847 !important;
  font-size: 12px;
  color: #d9d9d9 !important;
  background-color: #262938 !important;
}

.darkTheme .darkInputBox2:focus {
  border: 1px solid #d9d9d9 !important;
  outline: none;
  box-shadow: none;
}

.darkTheme .darkHeaderText {
  color: #6540d7 !important;
}

.darkTheme .roundBorder {
  border-radius: 50px !important;
}

.darktheme .dropdownitemhover:hover {
  background: #343847 !important;
}

.darkTheme .darkDiffCapsule {
  background-color: #27293d !important;
  border-radius: 50px;
}

.darkTheme .hackathonSearch {
  padding: 12px 13px 12px 50px !important;
}

.darkTheme .hackathonSearch:focus {
  outline: none;
  box-shadow: none;
}

.darkTheme .dark-bg-white {
  background-color: #ffffff !important;
  color: #000;
}

.darkTheme .darkInputBox {
  border: 1px solid #263559 !important;
  font-size: 12px;
  color: #d9d9d9;
  background-color: #0d1028 !important;
}

.darkTheme .darkInputBox:focus {
  color: #d9d9d9;
}

.darkTheme .darkInputBox input {
  color: #d9d9d9 !important;
}

.darkTheme .darkInputBox::placeholder {
  color: #54535d;
}

.darkTheme .darkInputRadius {
  border-radius: 50px;
}

.darkTheme .darkBorderRight {
  border-right: 1px solid #303242 !important;
}

.darkTheme .darkBtn {
  background: linear-gradient(
    92.97deg,
    #445eb7 10.8%,
    #6540d7 94.39%
  ) !important;
  color: #fff !important;
}

.darkTheme .darkPracBtn {
  background: #263559 !important;
  color: #fff !important;
}

.darkTheme .dark-pick {
  background: linear-gradient(
    125.41deg,
    #7554ec -10.71%,
    #e75996 51.89%,
    #ff8841 104.36%
  );
  color: #ffffff;
}

.darkTheme .darkDropdown {
  background-color: #303043 !important;
  border: 2px solid #39394c !important;
  color: #ffffff;
}

.darkTheme .darkCardBody {
  background: #202337 !important;
}

.darkTheme .whiteArrow {
  filter: brightness(0) invert(4);
}

.darkTheme .darkTabActive {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #666666;
  padding: 0.5rem;
  cursor: pointer;
}

.darkTheme .MuiDialog-paperScrollPaper .ck-editor {
  border: 1px solid #39394c;
  border-radius: 5px;
}

.darkTheme .post-comment-btn-active {
  background: linear-gradient(
    92.97deg,
    #445eb7 10.8%,
    #6540d7 94.39%
  ) !important;
  color: #fff !important;
}

.darkTheme .darkTab {
  background: #263559;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #b5c0d3;
  padding: 0.5rem;
  cursor: pointer;
}

.darkTheme .darkBorderBtn {
  border: none !important;
}

.darkTheme .darkBorderBottom {
  border-bottom: 1px solid #303242 !important;
}

.darkTheme .darkBorderRadius {
  border-radius: 50px !important;
}

.dark-separator {
  color: #b5c0d3;
}

.darkTheme .darkCapsule {
  background-color: #303043 !important;
  color: #ffffff !important;
  border: none !important;
}

.darkTheme .darkFollow {
  border-radius: 50px;
  border: 1.41px solid #594ccb;
  color: #6244d4;
}

.darkTheme .hoverableBtn:hover {
  background: linear-gradient(
    92.97deg,
    #445eb7 10.8%,
    #6540d7 94.39%
  ) !important;
}

.darkTheme .darkPlayBackBtn {
  background: #b5c0d3;
  border-radius: 50px;
  color: #0d1028;
}

.darkTheme .darkCheckbox:hover {
  background-color: #0d1028;
}

.darkTheme .overflowAsses {
  overflow: hidden;
}

.darkTheme hr {
  border-top: 1px solid #263559;
}

.darkTheme .iconToLight {
  filter: invert(100%) sepia(81%) saturate(7382%) hue-rotate(179deg)
    brightness(90%) contrast(84%);
}

.darkTheme .darkTableHeader {
  background: #303242;
  border: 0.5px solid #2f3242;
}

.darkTheme .colorInvert {
  -webkit-filter: invert(100%);
  /* Safari/Chrome */
  filter: invert(100%);
}

.darkTheme .colorToDark {
  filter: invert(51%) sepia(95%) saturate(6224%) hue-rotate(244deg)
    brightness(85%) contrast(97%);
}

.darkTheme .darkBorderBottom {
  border-bottom: 1px solid #263559 !important;
}

.lightModePagination .MuiPagination-ul .MuiPaginationItem-root {
  border-radius: 5px;
  /* background-color: #fff; */
  margin-left: 5px;
  color: #f44d5e;
  border: 0.5px solid #b5c0d3;
}

.lightModePagination .MuiPagination-ul .MuiPaginationItem-root:hover {
  background-color: none;
}

.darkTheme .iconToDim {
  filter: invert(87%) sepia(12%) saturate(384%) hue-rotate(181deg)
    brightness(88%) contrast(87%);
}

.lightModePagination .MuiPagination-ul .Mui-selected {
  border-color: #f44d5e;
  background-color: #f44d5e;
  color: #fff;
}

.lightModePagination .MuiPagination-ul .Mui-selected:hover {
  background-color: #f44d5e;
}

.disableEffect {
  opacity: 0.4;
}

.dark-paragraph p {
  color: #ffffff;
  margin-bottom: 0.1rem;
}

.darkTheme .ck .ck-toolbar {
  background: #303242;
  border: 1px solid #303242;
  border-radius: 5px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.darkTheme .ck .ck-icon {
  filter: invert(88%) sepia(13%) saturate(470%) hue-rotate(187deg)
    brightness(88%) contrast(86%);
}

.darkTheme
  .ck.ck-dropdown.ck-heading-dropdown
  .ck-dropdown__button
  .ck-button__label {
  color: #b5c0d3;
  width: 70px;
}

.darkTheme .ck.ck-button:not(.ck-disabled):hover,
.darkTheme a.ck.ck-button:not(.ck-disabled):hover {
  background: #303242;
}

.darkTheme .ck.ck-button.ck-on:not(.ck-disabled):hover,
.darkTheme a.ck.ck-button.ck-on:not(.ck-disabled):hover {
  background: #303242;
}

.darkTheme .ck.ck-button.ck-on,
.darkTheme a.ck.ck-button.ck-on {
  background: #262938;
}

.darkTheme .ck.ck-list {
  background: #262938;
}

.darkTheme
  .ck.ck-splitbutton.ck-splitbutton_open
  > .ck-button:not(.ck-on):not(.ck-disabled):not(:hover),
.darkTheme
  .ck.ck-splitbutton:hover
  > .ck-button:not(.ck-on):not(.ck-disabled):not(:hover) {
  background: #262938;
}

.darkTheme .ck.ck-button .ck-button__label,
.darkTheme a.ck.ck-button .ck-button__label {
  color: #ffffff;
}

.darkTheme .ck.ck-dropdown__panel {
  background: #262938;
  border: 1px solid #333;
}

.darkTheme p {
  color: #b5c0d3;
}

.darkTheme .ck.ck-dropdown .ck-dropdown__arrow {
  filter: invert(88%) sepia(13%) saturate(470%) hue-rotate(187deg)
    brightness(88%) contrast(86%);
}

.darkTheme .postTopic.ck.ck-placeholder:before,
.darkTheme .ck .ck-placeholder:before {
  color: #455063;
}

.darkTheme .ck.ck-editor__main > .ck-editor__editable {
  background: #262938;
  border: none;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  color: #ffffff;
  font-weight: 400;
}

.darkTheme .ck-content pre {
  color: #fff;
}

.darkTheme .ck.ck-editor__main > .ck-editor__editable h1,
.darkTheme .ck.ck-editor__main > .ck-editor__editable h2,
.darkTheme .ck.ck-editor__main > .ck-editor__editable h3,
.darkTheme .ck.ck-editor__main > .ck-editor__editable h4,
.darkTheme .ck.ck-editor__main > .ck-editor__editable h5,
.darkTheme .ck.ck-editor__main > .ck-editor__editable h6 {
  color: #b5c0d3;
}

.darkTheme .ck-placeholder {
  color: #b5c0d3;
}

.darkTheme .ck.ck-editor__main > .ck-editor__editable:focus {
  border: none;
}

.darkTheme .ck-placeholder {
  color: #b5c0d3;
  font-size: 12px;
}

.darkTheme .ck-content p {
  font-size: 16px;
}

.darkTheme .like-div,
.darkTheme .dislike-div {
  border: solid 1px #263559;
}

.darkTheme .like-div img,
.darkTheme .dislike-div img {
  width: 15px;
}

.darkTheme .post-btn-active {
  background: linear-gradient(
    92.97deg,
    #445eb7 10.8%,
    #6540d7 94.39%
  ) !important;
  color: #ffffff;
  border-top-left-radius: 10px !important;
}
.darkTheme .post-btn {
  background: #303043 !important;
  color: #ffffff;
  border-top-left-radius: 10px !important;
}

.dark-seperator {
  color: #303242;
  font-size: 14px;
}

.darkTheme .noBorder {
  border: none !important;
}

.darkTheme .notificationSign::after {
  background: #6540d7;
  width: 9px;
  height: 9px;
}

.dark-commentLine {
  background: #303242;
  width: 1px;
  height: 90%;
  margin: 6px auto !important;
}

.dark-replyLine {
  background: #303242;
  width: 1px;
  height: 100px;
  margin: 6px auto !important;
}

/* .darkTheme .MuiPaper-root,
.darkTheme .two-side-popup {
  background-color: #0d1028;
} */
.two-side-popup .err-alert {
  background-color: #F44D5E;
  color: #ffffff;
  font-weight: 600;
}
.darkTheme .err-alert {
  background-color: #FDDBDF;
  color: #F44D5E;
}
.err-alert .MuiAlert-icon {
  color: #ffffff !important;
}
.darkTheme .err-alert .MuiAlert-icon {
  color: #F44D5E !important;
}
.darkTheme .MuiIconButton-label {
  color: white;
}

.darkConfirmDeleteModal .MuiDialogContent-dividers {
  border-top: 1px solid #343847;
  border-bottom: none;
  padding: 20px 75px 10px;
  text-align: center;
}

.darkConfirmDeleteModal .MuiDialogContentText-root {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
  color: #000;
  margin-bottom: 0;
}

.darkConfirmDeleteModal .MuiDialogActions-root {
  justify-content: center;
  margin-bottom: 20px;
}

.darkConfirmDeleteModal .MuiDialog-paper {
  background: #262938;
  border: 1px solid #343847;
  border-radius: 15px;
}

.darkConfirmDeleteModal .delete-cancel-btn {
  background-color: #f04f5f;
  color: #fff;
  border-radius: 5px;
  width: 100px;
  text-transform: capitalize;
}

.darkTheme .whiteText p,
.darkTheme .blogInnerPageContent h1,
.darkTheme .blogInnerPageContent h2,
.darkTheme .blogInnerPageContent h3,
.darkTheme .blogInnerPageContent h4,
.darkTheme .blogInnerPageContent h5,
.darkTheme .blogInnerPageContent h6 {
  color: #ffffff !important;
}

.darkTheme .darkDiffCapsule:hover {
  border-radius: 50px !important;
}

.darkTheme .css-hyxlzm {
  color: #fff;
}

.darkTheme .lateSubmissionCntr .hackathonHld .imgHld {
  background-color: #0d1028;
}

.darkTheme .iconToWhite {
  filter: invert(93%) sepia(7%) saturate(0%) hue-rotate(145deg) brightness(164%)
    contrast(112%);
}

.darkTheme .darkDropZone {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23263559FF' stroke-width='2' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  background-color: #050c2a;
  border-radius: 5px;
}

.dark-overview > h3 strong,
.dark-overview > h4 strong,
.dark-overview > h5 strong,
.dark-overview > h3,
.dark-overview > h4,
.dark-overview > h5 {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #6045d7 !important;
}

.bootstrapisoauth .dark-overview,
.bootstrap-iso .dark-overview,
.dark-overview p,
.dark-overview ul,
.dark-overview ul li,
.dark-overview ol li,
.dark-overview span {
  font-size: 16px;
  color: #ffffff !important;
  font-weight: 400;
  line-height: 28px;
}
.dark-overview ul {
  padding-left: 20px;
}
.dark-overview h4,
.dark-overview h5,
.dark-overview h3 {
  font-size: 16px;
}

.dark-overview h3 strong,
.dark-overview h4 strong,
.dark-overview h5 strong,
.dark-overview h3,
.dark-overview h4,
.dark-overview h5 {
  font-weight: 500;
  font-size: 16px;
}

.dark-overview > h3 strong,
.dark-overview > h4 strong,
.dark-overview > h5 strong,
.dark-overview > h3,
.dark-overview > h4 {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #ffffff !important;
}

.dark-overview .table td {
  border-top: 1px solid #303242;
  color: #ffffff;
}

.dark-overview img {
  max-width: 100%;
  height: auto;
}

.dark-overview pre {
  background: #26274e;
  border: 0.5px solid #9e56ce;
  color: #ffffff;
}

.dark-overview pre code {
  color: #ffffff;
}
.dark-overview a,
.bootstrapisoauth .dark-bg li a,
.darkTheme .competitionDes a,
.darkTheme .competitionDes a span {
  color: #d1457e;
  text-decoration: underline;
}

.darkPagination .MuiPagination-ul .MuiPaginationItem-root {
  border-radius: 5px;
  /* background-color: #fff; */
  margin-left: 5px;
  color: #6c6fc3;
  border: 0.5px solid #253356;
}

.darkPagination .MuiPagination-ul .Mui-selected {
  border-color: #6c6fc3;
  background-color: #6c6fc3;
  color: #0d1028;
}

.darkPagination .MuiPagination-ul .Mui-selected:hover {
  background-color: #6c6fc3;
}

/* No data css */

.no-data-cntr .btn {
  height: 40px;
  line-height: normal;
  font-weight: 700;
  font-size: 14px;
  width: 180px;
  background: #f65160;
}

.no-data-cntr .text {
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
}

/* Toast Message */

.ToastMessageCntr {
  background: #ffffff;
  border: 1px solid #e1e3e6;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 3px 5px;
  z-index: 9999999;
  position: fixed;
  top: auto;
  bottom: 30px;
  transition: opacity 5s;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 50%;
}

.DarkToastMessageCntr {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 3px 5px;
  z-index: 9999999;
  position: fixed;
  top: auto;
  bottom: 30px;
  transition: opacity 5s;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 50%;
}

.ToastMessageHld {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.ToastMessageText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  margin-left: 10px;
}

.DarkToastMessageCntr .ToastMessageText {
  color: #ffffff;
}

.darkTheme .dark-calendar-card {
  background: #0c1431;
  border: 0.5px solid #263559;
  border-radius: 5px;
}

.darkTheme .dark-calendar-text {
  color: #b5c0d3;
}

.darkTheme .blackText {
  color: #000000 !important;
}

.darkTheme .whiteDescription {
  color: #b5c0d3;
}

.darkTheme .feed-cards {
  background: #1b234a;
  border: 0.5px solid #263559;
  border-radius: 10px;
}

.darkTheme .feed-iconToLight {
  filter: invert(100%) sepia(81%) saturate(7382%) hue-rotate(179deg)
    brightness(90%) contrast(84%);
}

.darkTheme .dark-new-topic {
  background-color: #263559;
  border: 2px solid #39394c;
  border-radius: 50px !important;
  box-shadow: none;
  background: #263559;
  border: 2px solid #263559;
  padding: 4px 40px;
}
.darkTheme .dark-new-topic p {
  font-weight: 500;
}
.cursor-pointer {
  cursor: pointer;
}
.hackathon-content-border {
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  border-radius: 5px;
  margin-bottom: 30px;
}
.darkTheme .hackathon-content-border {
  background: #0c1431;
  border: 0.5px solid #263559;
}
.footer-list li {
  margin-bottom: 10px;
}
.overviewHackathonCntr .rules-headline {
  background: rgb(244, 244, 244);
  border-radius: 50px;
  font-weight: 700;
  font-size: 14px;
  display: inline-block;
  padding: 6px 20px;
  white-space: nowrap;
  margin-right: 20px;
  text-transform: capitalize;
  color: #000000;
}
.darkTheme .overviewHackathonCntr .rules-headline {
  background: #212842;
}
.rules-line {
  height: 0.5px;
  background: #d9d9d9;
  width: 100%;
  margin-right: -25px;
}
.darkTheme .rules-line {
  background: #263559;
}
.rulesUnorderedlist {
  list-style: none;
}
.rulesUnorderedlist li::before {
  content: "\2022";
  color: #786bf9;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.competition-timeline {
  background: rgb(244, 244, 244);
  border: 0.5px solid #9e56ce;
  border-radius: 0px 10px 10px 10px;
}
.darkTheme .competition-timeline {
  background: #26274e;
}
.competition-timeline h6 {
  background: linear-gradient(
    283.18deg,
    #7554ec -10.6%,
    #e75996 63.41%,
    #ff8841 97.5%
  );
  border-radius: 0px 0px 50px 0px;
  display: inline-block;
  margin-bottom: 0px;
  padding: 8px 20px;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
}
.competition-timeline .timeline-hld {
  padding: 10px 20px;
}
.competition-timeline .timeline-time {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
}
.competition-timeline .timeline-time span {
  font-weight: 700;
}
.competition-timeline .time-Left {
  background: linear-gradient(
    283.18deg,
    #7554ec -10.6%,
    #e75996 63.41%,
    #ff8841 97.5%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-transform: lowercase;
  font-weight: 700;
  font-size: 18px;
  display: block;
}
.competition-timeline .timeline-line {
  border-bottom: 0.5px solid #9e56ce;
  width: 100%;
  min-width: 350px;
}
.competition-timeline .timeline-line::after {
  content: "";
  background: #9e56ce;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  bottom: 11px;
  right: 0;
  border-radius: 50px;
}
.competition-timeline .timeline-line::before {
  content: "";
  background: #9e56ce;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  bottom: 11px;
  left: 0;
  border-radius: 50px;
}
.darkTheme .disabledBtn {
  background: #263559;
  border-radius: 50px;
  padding: 4px 40px;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
}
.tabsContentHld {
  margin-top: 35px;
}
.homePageTabs {
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  border-radius: 10px;
  padding: 24px 0 0;
  min-width: 135px;
}
.darkTheme .homePageTabs {
  background: rgba(38, 53, 89, 0.4);
  border: 0.5px solid #0c1431;
}
.HomeTabMargin {
  margin-bottom: 20px;
}
.prizeInactiveTab {
  background: linear-gradient(#fff2e8, #fff2e8) padding-box,
    linear-gradient(45deg, #ff8841, #e75996) border-box;
  border: 1px solid #f4b2b7;
  border-radius: 5px;
  padding: 4px 34px 4px 20px;
  cursor: pointer;
}
.darkTheme .prizeInactiveTab {
  border: 1px solid transparent;
  background: linear-gradient(#26274e, #26274e) padding-box,
    linear-gradient(45deg, #f4b2b7, #fcc18a) border-box;
}
.prizeInactiveTab p {
  background: linear-gradient(
    283.18deg,
    #7554ec -10.6%,
    #e75996 63.41%,
    #ff8841 97.5%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 500;
  font-size: 14px;
}
.prizeActiveTab {
  background: linear-gradient(
    121.13deg,
    #dc4e5a 20.17%,
    #e2595b 31.41%,
    #f57f5f 71.17%,
    #fd8e61 92.78%
  );
  padding: 4px 34px 4px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.darkTheme .prizeActiveTab {
  background: linear-gradient(
    283.18deg,
    #7554ec -10.6%,
    #e75996 63.41%,
    #ff8841 97.5%
  );
}
.bootstrapisoauth .prizeActiveTab p {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}
.bootstrapisoauth .dark-bg .prizeActiveTab p {
  color: #0d1028;
}
/* Mh Coins popup */
.mh-coins-popup-cntr {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mh-coins-popup .coin-lottie {
  width: 100px;
  position: absolute;
  left: -12px;
  top: -20px;
}
.mh-coins-popup .warning-lottie {
  position: absolute;
  width: 60px;
  top: 2px;
  left: 10px;
}
.mh-coins-popup .mh-coin-and-text {
  display: flex;
  align-items: center;
}
.mh-coins-popup {
  border-radius: 10px;
  border: transparent;
  position: fixed;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 660px;
  z-index: 9999;
  padding: 14px 20px;
  min-height: 60px;
}
.mh-coins-popup-cntr .mh-coins-orange-bg {
  background: linear-gradient(
    121.13deg,
    #dc4e5a 20.17%,
    #e2595b 31.41%,
    #f57f5f 71.17%,
    #fd8e61 92.78%
  );
}
.darkTheme .mh-coins-orange-bg {
  background: #26274e;
  border: 0.5px solid #9e56ce;
}
.mh-coins-popup-cntr .mh-coins-red-bg {
  background: linear-gradient(271.14deg, #c53737 55.4%, #f67762 106.03%);
}
.mh-coins-popup .mh-coins-text {
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  margin-left: 60px;
  margin-bottom: 0;
}
.darkTheme .mh-coins-orange-bg .mh-coins-text {
  background: linear-gradient(
    283.32deg,
    #7554ec -17.27%,
    #e75996 61.27%,
    #ff8841 97.46%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.mh-coins-popup button {
  border-radius: 50px;
  border: transparent;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  padding: 4px 16px;
  cursor: pointer;
  background: #ffffff;
}
.darkTheme .mh-coins-orange-bg button {
  background: linear-gradient(
    283.18deg,
    #7554ec -10.6%,
    #e75996 63.41%,
    #ff8841 97.5%
  );
  color: #ffffff;
}
.infiniteScrollcntr {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.infiniteScrollcntr::-webkit-scrollbar {
  display: none;
}
.machine-gen-ai-logo {
  width: 160px;
}
#root .middleContainer{
  min-height: calc(100vh - 110px);
}
@media (max-width: 600px) {
  .mh-coins-popup {
    min-width: auto;
  }
}
