.form-control-row{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.update-profile .form-control-row .form-group{
  margin: 0px 0px 0px 0px;
}
.bootstrapisoauth .hackathon-prize-link {
  color: #17A0E0;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;
  -webkit-text-fill-color:#17A0E0;
}
.bootstrapisoauth .text-secondary {
  font-size: 14px;
  font-weight: 500;
  color: #000 !important;
  line-height: 2.2;
}
.shareHack {
  font-size: 13px;
  color: #000;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #f76f78;
  background-image: none;
  flex: 1;
  padding: 0 0.5em;
  color: #fff;
  cursor: pointer;
  font-size: 1em;
  font-family: "Open Sans", sans-serif;
}
select::-ms-expand {
  display: none;
}
.select {
  position: relative;
  display: flex;
  width: 15em;
  height: 30px;
  line-height: 2;
  background: #e8505c;
  overflow: hidden;
  border-radius: 0.25em;
  color: #fff;
  margin-left: 40px;
}
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #e9505c;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}
.select:hover::after {
  color: #fff;
}

.select option {
  background: #fff;
  color: #000;
}
.HiringModal .MuiTypography-h6 {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #0f0506;
  text-transform: capitalize;
}
.HiringModal .MuiDialog-paperWidthMd {
  background-color: #f9f9f9;
}
.HiringModal .MuiDialogContent-dividers {
  padding: 30px 60px;
}
.hiring-radio-btns {
  display: flex;
}
.HiringModal .MuiDropzoneArea-root {
  min-height: auto;
}
.HiringModal .MuiDropzoneArea-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: #0f0506;
}
.HiringModal .MuiDropzoneArea-textContainer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  margin-top: 20px;
}
.HiringModal .MuiFormGroup-root {
  flex-direction: row;
}
.HiringModal .current-employed {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #455461;
}
.HiringModal .current-employed span {
  font-weight: bold;
}
.HiringModal .form-group {
  display: flex;
  flex-direction: column;
}
.HiringModal .form-group label {
  font-size: 14px;
  line-height: 1.5;
  color: #455461;
}
.HiringModal .form-group .form-control {
  border-radius: 5px;
  border: solid 1px #dfdfdf;
  padding: 10px;
  margin-top: 8px;
}
.HiringModal .MuiFormControlLabel-label {
  font-size: 16px;
  line-height: 1.5;
  color: #455461;
}
.dark-hiring-acceptance-form .MuiFormControlLabel-label {
  font-size: 16px;
  line-height: 1.5;
  color: #b5c0d3;
}
.HiringModal .TnCTxt {
  font-size: 16px;
  line-height: 1.5;
  color: #455461;
}
.HiringModal .MuiDropzoneArea-root {
  border: dashed 2px #dfdfdf;
  background-color: #f4f4f4;
}
.update-profile .form-group .form-control {
  border-radius: 5px;
  border: solid 1px #dfdfdf;
  padding: 13.5px 10px;
  width: 100%;
}
.dark-update-profile .form-group .form-control {
  color: #ffffff;
  font-size: 14px;
  background: transparent;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid #263559;
}
.update-profile .form-group .form-control:-webkit-autofill,
  .update-profile .form-group .form-control:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
.dark-update-profile .form-group .form-control::placeholder {
  color: #b5c0d3;
  font-size: 14px;
  font-weight: 400;
}
.update-profile .form-group {
  margin: 20px 0px;
  position: relative;
}
.update-profile .form-group label {
  position: absolute;
  top: 10px;
  opacity: 0.6;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 400;
  color: #455461;
}
.dark-update-profile .form-group label {
  position: absolute;
  top: 10px;
  opacity: 0.6;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 400;
  color: #b5c0d3;
}
.err-msg {
  font-size: 12px;
  color: #e9505c;
  margin: 6px 0;
}
.err-control {
  border: solid 1px #e9505c !important;
}
.update-profile .btnsCntr {
  text-align: center;
  margin: 40px 0 0;
}
.update-profile .MuiTypography-h6 {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  color: #0f0506;
}
.update-profile .MuiDialog-paperWidthMd {
  max-width: 700px;
}
.bootstrapisoauth .btn:hover {
  color: #fff !important;
  text-decoration: none;
}
.showdescription {
  display: flex;
  justify-content: center;
  align-items: center;
}
.showdescription .formPopupHld {
  background: #ffffff;
  padding: 20px 40px;
  border-radius: 10px;
  min-width: 700px;
}
.formPopupHld .form-popupTitle {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #000000;
}
.formPopupHld .popup-lable,
.popup-lable {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #1a1a1a;
}
.darkTheme .popup-lable {
  color: #b5c0d3;
}
.formPopupHld .form-control {
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  text-transform: none;
}
.formPopupHld .privacyterms {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #848e96;
}
.formPopupHld button {
  border-radius: 4px;
  border: transparent;
  color: #ffffff;
  padding: 8px 60px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
.formPopupHld .MuiDropzoneArea-root {
  min-height: 125px;
  border-radius: 4px;
  border-color: #c4c4c4;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.formPopupHld .MuiDropzoneArea-root p {
  margin: 0;
}
.stepperHead .stephead {
  background: #e2e2e9;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  text-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #3c3e4d;
  display: flex;
  justify-content: center;
}
.stepperHead span {
  width: 50px;
  height: 1px;
  background: #e2e2e9;
}
/* .stepperHead .stephead:first-child::after{
  content: "";
  width: 150px;
  height: 1px;
  background: #E2E2E9;
} */
.cityDropdown {
  background: #ffffff;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  z-index: 2;
  overflow: auto;
  top: 75px;
  height: 150px;
  width: 100%;
}
.cityDropdown p {
  padding: 8px 10px;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-bottom: 0;
}
.cityDropdown p:hover {
  background: #f2f3f4;
  color: #000000;
}
.darkTheme .cityDropdown {
  background: #050c2a;
  border: 0.5px solid #263559;
}
.darkTheme .cityDropdown p:hover {
  background: #263559;
  color: #ffffff;
}
.stepperHead .activestep {
  background: #3c3e4d;
  color: #ffffff;
}
.telegramHld {
  background: #dbf1fb;
  border-radius: 5px;
  padding: 23px 10px;
}
.dark-Nav-hld {
  border-bottom: 1px solid #263559;
}
.light-Nav-hld {
  border-bottom: 1px solid #dadce0;
}
.active-nav {
  position: relative;
}
.active-nav p{
  color: #000000;
}
.banner-menu-item  a , .banner-menu-item a:hover ,.hackathoncenterTabs .active-nav  p{
  color:#000000;
}
.banner-menu-item p{
  color: #757575;
}
 .darkTheme .active-nav  p{
  color: #ffffff;
}
.dark-leaderboard-active-nav {
  background: linear-gradient(
    263.57deg,
    #c44285 2.44%,
    #ff7015 100.42%
  ) !important;
  color: #ffffff;
}
.dark-leaderboard-active-nav p {
  color: #ffffff !important;
}
.active-nav::after {
  content: " ";
  width: 50px;
  height: 3px;
  position: absolute;
  background: #f44d5e;
  border-radius: 50px;
  bottom: -2px;
  transform: translate(-50%, -50%);
  left: 50%;
}
.darkTheme .active-nav::after {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
}
.dark-active-nav .darkText {
  color: #ffffff;
}
.bootstrapisoauth .hackathoncenterTabs .darkLeaderboard {
  background: linear-gradient(
    270.29deg,
    rgba(121, 133, 160, 0.4) -3.7%,
    rgba(21, 29, 56, 0.4) 155.6%
  );
  border: 1px solid #3f4c6f;
  box-shadow: 0px 10px 30px rgba(7, 21, 59, 0.1);
  border-radius: 50px;
  padding: 3px 15px;
}
.bootstrapisoauth .hackathoncenterTabs .darkLeaderboard p{
  width: max-content;
}
.hackathoncenterTabs .leaderboard-menu-item-text {
  background: linear-gradient(90.01deg, #896ced 0.01%, #0bdab1 99.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  width: max-content;
}
.bootstrapisoauth .hackathoncenterTabs .lightLeaderboard {
  background: linear-gradient(272.59deg, #F2F3F4 -36.2%, rgba(242, 243, 244, 0) 108.64%);
  border: 1.5px solid #FFFFFF;
  filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.1));
  box-shadow : 0px 10px 18px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  padding: 0px 15px;
}
.light-hackathon-layout-spacing .hackathoncenterTabs .lightLeaderboardActive {
  background: linear-gradient(272.59deg, #F99643 -36.2%, #F65656 108.64%);
  line-height: 33px;
}
.light-hackathon-layout-spacing .hackathoncenterTabs .lightLeaderboardActive p{
  width: max-content;
}
.leaderBoardText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  background: linear-gradient(276.73deg, #FCCF31 -27.06%, #F55555 41.31%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  width: max-content;
}
.darkTheme .dark-hackathon-participateCntr {
  border: none;
}
.darkTheme .dark-hackathon-participateCntr .calender-icon {
  filter: invert(84%) sepia(20%) saturate(210%) hue-rotate(179deg)
    brightness(90%) contrast(87%);
}
.darkTheme .dark-hackathon-participateCntr .evntDuration {
  color: #b5c0d3;
}
.darkTheme .dark-hackathon-participateCntr .evntDate {
  color: #b5c0d3;
}
.darkTheme .dark-hackathon-participateCntr .light-icon {
  filter: invert(84%) sepia(20%) saturate(210%) hue-rotate(179deg)
    brightness(90%) contrast(87%);
}
.darkTheme .dark-hackathon-participateCntr .beginnerTxt,
.darkTheme .dark-hackathon-participateCntr .codetxt {
  color: #b5c0d3;
}
.darkTheme .dark-hackathon-participateCntr .price {
  background: linear-gradient(
    283.18deg,
    #7554ec -10.6%,
    #e75996 63.41%,
    #ff8841 97.5%
  );
  background-clip: text;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.darkTheme .dark-hackathon-participateCntr button {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
  border-radius: 50px !important;
  font-size: 16px;
  font-weight: 700;
}
.darkTheme .dark-telegramHld {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
  border-radius: 5px;
  text-align: center;
}
.darkTheme .dark-telegramHld a {
  background: #ffffff;
  color: #000000;
  font-weight: 400;
}
.darkTheme .dark-telegramHld p {
  color: #ffffff;
}
.dark-hiring-acceptance-form .MuiDialog-paper,
.darkTheme .two-side-popup {
  border-radius: 10px;
  background: #1D2542;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);  
  max-width: 440px;
}
.btnsCntr .hackathon-submit-btn{
  border-radius: 50px;
  width: 100%;
  margin-bottom: 20px;
  background: #F2F3F4;
  color: #292929;
  border: none;
  font-size: 15px;
  font-weight: 600;
}
.darkTheme .hackathon-submit-btn {
  background: #B5C0D3;
  color: #1D2542;
  border: none;
  transition: background 0.7s, color 0.7s;
}
.darkTheme .hackathon-submit-btn:hover {
  border: none;
  background:  linear-gradient(92.97deg,
  #445fb7 10.8%,
  #6640d7 94.39%);
  box-shadow: none;
}

@media only screen and (max-width: 600px) {
  select {
    width: 10em;
  }
  .banner-menu-item p{
    font-size: 13px;
  }
  .hackathon-mobile-next-btn{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0%,-50%);
  }
  .hackathonMobileView  .hackathon-participateCntr , .darkTheme .dark-calendar-card{
    border: none;
    border-radius: 0;
  }
}
@media (max-width: 575.98px) {
  .HiringModal .MuiDialogContent-dividers,
  .update-profile .MuiDialogContent-dividers {
    padding: 10px;
  }
  .update-profile .form-group {
    margin: 10px 0;
  }
  .hackathoncenterTabs .leaderboard-menu-item-text{
    font-size: 12px;
  }
  .bootstrapisoauth .hackathoncenterTabs .darkLeaderboard{
    margin: 8px 0px;
  }
}
.whitetext {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}
