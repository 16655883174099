.DashuserImageHld {
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #c4c4c4;
}
.DashuserImageHld h2 {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  width: 70%;
}
.imageHld {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.userImage {
  width: 50px;
}
.singleDashboardHld {
  box-shadow: 3px 3px 10px 5px rgb(0 0 0 / 10%);
  background-color: #fff;
  margin-top: 7rem;
  padding-bottom: 40px;
}
.singleDashContainer {
  max-width: 1440px !important;
}
.total-views {
  border-radius: 26px;
  background-color: #c4c4c4;
  color: #fff;
  padding: 8px 20px;
}
.total-views .fa-eye {
  margin-right: 6px;
}
.total-likes {
  border-radius: 26px;
  border: solid 1px #c4c4c4;
  color: #e8505b;
  padding: 8px 20px;
}
.total-likes .fa-thumbs-up {
  margin-right: 6px;
  vertical-align: text-top;
}
.total-like span {
  vertical-align: text-top;
}
.user-name {
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin-bottom: 10px !important;
}
.user-personel-details {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #3a3a3a;
  margin-bottom: 6px !important;
}
.dashboard-image-body {
  padding: 50px 0;
}
.likeActionCntr {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.likeActionCntr .views {
  border: solid 1px #455461;
  border-radius: 26px;
  padding: 8px 20px;
  color: #455461;
  height: 40px;
  margin-right: 20px;
}
.likeActionCntr .likes {
  border: solid 1px #455461;
  border-radius: 26px;
  padding: 8px 20px;
  color: #455461;
  height: 40px;
}
.likeActionCntr .likes span {
  vertical-align: text-top;
}
.thumbsUpbtn {
  font-size: 26px;
  background: #000;
  color: #fff;
  border-radius: 50px;
  padding: 20px;
  margin-right: 20px;
}
.dashImageName {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.singleDashiframe {
  width: inherit;
  min-height: 600px;
}
/* .react-pdf__Page {
    margin-top: 10px;
  }
  .react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
  }
  
  .react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
  }
  
  .react-pdf__Page__canvas {
    margin: 0 auto;
  } */
.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.prevnextbtn {
  border-radius: 50px !important;
  background: #e9505c;
  border: none;
  color: #fff;
  padding: 8px 30px;
  font-size: 18px !important;
  font-weight: 500;
  width: 150px;
  margin-right: 10px !important;
}
.prevnextbtn:disabled {
  background: #dfdfdf !important;
  border: none;
  cursor: no-drop;
}
.pageNumbers {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.likedButton {
  background-color: #e8505b;
  color: #fff;
  border: #e8505b;
}
.selectedThumb {
  background: #e85065;
}
/* dark mode */
.darkSingleDashboardHld {
  padding-top: 10px;
  padding-bottom: 60px;
}
.white-text {
  color: #ffffff;
}
.small-text {
  color: #b5c0d3;
}
.darkInactiveBtn {
  background: #303043 !important;
  color: #ffffff;
}
.darkActiveBtn {
  background: linear-gradient(
    92.97deg,
    #445eb7 10.8%,
    #6540d7 94.39%
  ) !important;
  border: none;
  color: #ffffff;
}
.darkborderBtn {
  border: solid 1px #ffffff !important;
  color: #ffffff !important;
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .DashuserImageHld h2 {
    font-size: 20px;
    width: 60%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .DashuserImageHld h2 {
    font-size: 20px;
    width: 48%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 479px) {
  .DashuserImageHld {
    flex-wrap: wrap;
  }
  .likeActionCntr {
    padding: 2px;
  }
  .likeActionCntr .views {
    padding: 8px 16px;
    margin-right: 10px;
  }
  .likeActionCntr .likes {
    padding: 8px 16px;
    height: 40px;
  }
  .thumbsUpbtn {
    margin-right: 10px;
  }
  .dashImageName {
    font-size: 16px;
  }
  .DashuserImageHld h2 {
    font-size: 18px;
    width: auto;
  }
}
