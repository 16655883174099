#page {
  height: 100%;
  background-color: pink;
  display: grid;
  grid-template-areas: "leftcol  leftdragbar   rightcol";
  grid-template-rows: 1fr;
  grid-template-columns: 18fr 1fr 18fr;
}

/*****************************/
#header {
  background-color: lightblue;
  overflow: auto;
  grid-area: header;
}

#leftcol {
  background-color: #aaaaaa;
  overflow: auto;
  grid-area: leftcol;
  height: 50vh;
}

.backBtnCoding {
  background: #000000;
  color: #ffffff;
  border-radius: 50px;
  padding: 3px 16px;
  max-width: 82px;
}

.scrollFix {
  overflow-x: hidden;
  overflow-y: scroll;
  /* height: calc(100vh - 185px) !important; */
}

#leftdragbar {
  background-color: black;
  grid-area: leftdragbar;
  cursor: ew-resize;
  width: 6px;
}

#tabs {
  background-color: #cccccc;
  overflow: auto;
  grid-area: tabs;
}

#tabpages {
  background-color: #888888;
  overflow: auto;
  grid-area: tabpages;
}

#rightdragbar {
  background-color: black;
  grid-area: rightdragbar;
  cursor: ew-resize;
}

#rightcol {
  background-color: #aaaaaa;
  overflow: auto;
  grid-area: rightcol;
}

#footer {
  background-color: lightblue;
  overflow: auto;
  grid-area: footer;
}

.practRight,
.practRight .innerContainer .innerHead,
.editorHead {
  background: #f5f5f5;
}
.bootstrap-iso .contentOne {
  width: 50%;
}
.contentOne .levelDiv pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
}

.divider {
  padding: 0 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: col-resize;
  /* background: linear-gradient(#f5f5f5, #fff); */
}

.divider span {
  line-height: 8px;
}

.divider:hover {
  background: #f4f4f5;
}

/* discussion */
.borderedContainer {
  border-left: 0.5px solid #d9d9d9;
  border-right: 0.5px solid #d9d9d9;
}

.discussBtn {
  all: unset;
  background: #000000;
  border-radius: 50px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  border: none;
  border-radius: 50px !important;
  padding: 2px 15px;
}

.discussButton {
  background: #d9d9d9;
  border-radius: 0px 50px 50px 0px !important;
  border: none;
  padding: 2.8px 2px;
}

.discussInput::placeholder {
  font-size: 12px !important;
}

.discussInp {
  width: 80% !important;
  background: #fafafb;
  border: 0.5px solid #d9d9d9;
  border-radius: 50px 0px 0px 50px;
  padding: 2px 0px;
}

.discussNavbar {
  background: #f4f4f5;
  border: 0.5px solid #d9d9d9;
  align-items: center;
  padding: 10px 20px;
}

.navbarText,
.practLeft .inputCont {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  cursor: pointer;
  white-space: pre-wrap;
}

/* discussionEnd */
.pracTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

/* solution */
.playAgain,
.subcribeBtn {
  all: unset;
  background: #ffffff;
  border: none;
  border-radius: 50px !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
  padding: 6px 20px;
}

.playAgain {
  padding: 6px 26px;
}

.subcribeBtn {
  border-radius: 40px !important;
  color: #666666;
}

.solutionVideo {
  padding-bottom: 310px;
  overflow: hidden;
  position: relative;
  text-align: center;
  background-color: #000;
}

.solutionVideo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bootstrap-iso .solutionTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 20px;
  line-height: 16px;
  color: #000000;
}

.solutionText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #666666 !important;
}

.solutionFont {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #666666 !important;
}

/* solution end*/
/* allProblems */
.backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 11111;
}

.backdrop .sideNav {
  width: 42%;
  height: 100%;
  background: #212121;
}

.problemslist {
  cursor: pointer;
}

.problemslist .dimFont {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #9e9e9e;
}

.problemslist .active {
  color: #ffffff;
}

.higFont {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.filterTabs {
  border: 0.8px solid #757575;
  border-radius: 50px;
}

.filterTabs .searchInp {
  background: #fafafb;
  border: 0.5px solid #d9d9d9;
  border-radius: 50px 0px 0px 50px;
  padding: 2px 0px;
}

.sideNav .btnProblems .searchInp {
  padding: 4px 0px;
  color: #808080;
}

.searchInp::placeholder {
  font-size: 12px !important;
  color: #757575 !important;
}

.filterTabs .searchBtn,
.discussButton {
  background: #d9d9d9;
  border-radius: 0px 50px 50px 0px !important;
  border: none;
  padding: 2.8px 2px;
}

.btnProblems {
  font-weight: 400;
  font-size: 12px;
  width: fit-content;
  padding: 5px 20px;
  line-height: 20px !important;
  color: #808080;
  border: 0.8px solid rgb(117, 117, 117);
  background: transparent;
  height: 33px;
  /* border-radius: 50px !important; */
}

.searchInp {
  background: transparent;
  border: 0.5px solid #d9d9d9;
  border: none;
  padding: 0px 0px;
}

.searchInput ::placeholder {
  all: unset;
  font-size: 12px !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgb(117, 117, 117) !important;
}

.searchInput {
  border-radius: 50px;
}

.searchBtn {
  background: transparent;
  border: none;
  padding: 2.8px 2px;
}

.filterContainer {
  background: #262626;
}

.filterContainer>.filterValues {
  background: rgba(117, 117, 117, 0.4);
  width: fit-content;
  padding: 6px 12px;
  opacity: 0.7;
  border-radius: 50px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
}

.topicSelected {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

/* allProblems end*/

/* submissionTable */
.subTable .green-stat {
  color: #00b547;
  font-weight: 500;
  text-transform: capitalize;
}

.subTable .green-stat:focus {
  color: #00b547;
}

.subTable .red-stat {
  color: #ef4f5e;
  font-weight: 500;
  text-transform: capitalize;
}

.subTable .red-stat:focus {
  color: #ef4f5e;
}

.subTable .red-stat:hover {
  color: #ef4f5e;
  text-decoration: underline;
}

.subTable .green-stat:hover {
  color: #00b547;
  text-decoration: underline;
}

.subTable thead tr th,
.subTable thead tr {
  border: none !important;
  border-bottom: none !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
  text-align: center;
}

.subTable tr td {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
  text-align: center;
}

.bootstrap-iso .subTable tr td,
.bootstrap-iso .subTable th {
  border: 0.5px solid #d9d9d9;
  border-top: none !important;
}

.darkTheme .subTable tr td,
.darkTheme .subTable th {
  border: 0.5px solid #303242;
  border-top: none !important;
}

.bootstrap-iso .subTable tr td:first-child,
.bootstrap-iso .subTable th:first-child {
  border-left: none !important;
}

.bootstrap-iso .subTable tr td:last-child,
.bootstrap-iso .subTable th:last-child {
  border-right: none !important;
}

/* submissionTable end*/
.tabText {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #666666;
  padding: 0.5rem;
  background: #f5f5f5;
  cursor: pointer;
  border-bottom: 0.5px solid #d9d9d9;
}

.tab {
  background: #f4f4f5;
  cursor: pointer;
}

.tabActive,
.tabActiveLast {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #666666;
  padding: 5px 20px 7px;
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  border-left: none;
  border-bottom: none;
}

.tabText::after {
  content: "";
  position: absolute;
  border-left: 1px #d9d9d9 solid;
  top: 20%;
  right: 0;
  height: 60%;
}

.tabTextNoBorder::after {
  content: none;
}

.tabTextprev::after {
  content: "";
  position: absolute;
  border-left: 1px #d9d9d9 solid;
  top: 0;
  right: 0;
  height: 100%;
}

.questionText {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.levelDiv {
  border-top: 0.5px solid #d9d9d9;
}

.dark-levelDiv {
  border-top: 0.5px solid #263559;
}

.practLeft .levelDiv p {
  margin-bottom: 0;
}

.practLeft::-webkit-scrollbar {
  display: none;
}

.practLeft .endSection {
  border-top: 0.5px solid #d9d9d9;
}

.exampleText {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.inputOutputDiv {
  background: #fafafb;
}

.inputOutputDiv .input {
  white-space: pre-wrap;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  border: none;
}

.constraints ul {
  all: unset;
}

.constraints li,
.levelDiv li,
.endSection p {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  line-height: 20px;
}

.practRight .languageTag,
.practLeft .languageTag {
  background: #d9d9d9;
  border: none;
  font-weight: 400;
  font-size: 12px;
  padding: 0px 16px;
  color: #666666;
}

.codingText {
  background: #111c3d;
}

.allProblemsBtn {
  background: #fafafb;
  border: 0.5px solid #d9d9d9 !important;
  border-radius: 50px !important;
  padding: 5px 16px;
}

.leftFooter .pickOne {
  background: #fafafb;
  border: 0.5px solid #d9d9d9 !important;
  border-radius: 50px !important;
  padding: 5px 8px;
}

.prev {
  background: #fafafb;
  border: 0.5px solid #d9d9d9 !important;
  border-radius: 50px !important;
  padding: 3px 12px;
}

.next {
  background: #fafafb;
  border: 0.5px solid #d9d9d9 !important;
  border-radius: 50px !important;
  padding: 3px 12px;
}

.practLeft {
  border: 0.5px solid #d9d9d9;
  border-right: none;
  border-top: none;
  height: calc(100vh - 225px);
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.dark-practLeft {
  border: 0.5px solid #263559;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: none;
  height: calc(100vh - 197px);
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.marginTopExtended .dark-practLeft,
.marginTopExtended .practLeft {
  height: calc(100vh - 272px);
}
.dark-border-header{
  border: 0.5px solid #263559;
    border-bottom: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.contentOne .practLeft .actions,
.contentOneDiscuss .practLeft .actions {
  font-size: 13px;
  line-height: 17px;
  color: #666666;
}

/* .closed-half-GLobe {
  background: #1700FF;
  filter: blur(100px);
} */

.practiceQ {
  /* border: 0.5px solid #d9d9d9;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px; */
  /* height: calc(100vh - 185px); */
  overflow: auto;
  margin-top: 8px;
  border-top-left-radius: 3px;
}
.marginTopExtended .practiceQ {
  height: calc(100vh - 225px);
}

.practiceQ .compiler {
  border: 0.5px solid #d9d9d9;
}

.editorSection .compiler {
  border-left: none;
}

.outputStatic {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding: 8px 32px;
  cursor: pointer;
  position: relative;
}

.active-output-text {
  background: #111c3d;
}
.active-output-text::after {
  content: "";
  width: 20px;
  height: 2px;
  display: inline-block;
  background: linear-gradient(
    125.41deg,
    #7554ec -10.71%,
    #e75996 51.89%,
    #ff8841 104.36%
  );
  position: absolute;
  bottom: 0;
  transform: translate(50px, -50px);
  transform: translate(-50%, 0);
  left: 50%;
  border-start-end-radius: 10px;
  border-start-start-radius: 10px;
}

.outputResult {
  background: #f4f4f5;
  width: 100%;
  padding: 8px 25px;
}

.compResult {
  min-height: 18vh;
  overflow: auto;
}

.outputConatiner {
  background: #263559;
}

.compResult:hover {
  overflow-y: scroll;
}

.successSection,
.errorSection {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  padding: 13px 14px 35px;
}

.errorSection {
  padding-bottom: 10px;
}

.errorContainer {
  padding: 13px 14px 35px;
}

.errorMsg {
  white-space: pre-wrap;
  background: #fdedef;
  padding: 10px;
  border: none;
  border-radius: 10px;
  color: #f44d5e;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.warningMsg {
  white-space: pre-wrap;
  background: #fff3e1;
  padding: 10px;
  border: none;
  border-radius: 10px;
  color: #f5a843;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.runBtn {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
  border: 0.5px solid #d9d9d9 !important;
  padding: 3px 16px;
  border-radius: 50px !important;
}

.submitBtn {
  background: #f44d5e;
  border-radius: 50px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  border: none;
  padding: 3px 20px;
  border-radius: 50px !important;
}

/* .test{
  background: #c21e1e;
} */
.tab li {
  list-style: none;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #666666;
}

.tab:hover {
  background-color: #ffffff !important;
}

.leftFooter {
  background: #fff;
  line-height: 24px;
  /* padding: 10px 8px 0; */
  margin-left: -10px;
}

.leftFooter span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
}

/* compiler */
.practiceQ .ace_gutter-layer {
  background: #ffff;
  border-right: 1px solid #d9d9d9;
  /* width: 40px !important; */
}

.practiceQ .ace_gutter {
  left: 0px;
  width: 40px;
  background: transparent;
}

.practiceQ .ace_folding-enabled>.ace_gutter-cell {
  padding: 0px 10px 0px 10px;
  background: transparent;
}

.practiceQ .ace_scroller {
  left: 25px;
  line-height: 14px;
  right: 0px;
  bottom: 0px;
}

.practiceQ .ace-tm .ace_gutter-active-line {
  background: none;
}

/* .contentOne {
  min-width: 40vw;
} */

.contentOne .intermeidate,
.contentOneDiscuss .intermeidate {
  color: #feb91e;
}

.contentOne .advance,
.contentOneDiscuss .advance {
  color: #d6313a;
}

.contentOne .beginner,
.contentOneDiscuss .beginner {
  color: #00b547;
}

/* compiler */
/* Solution page */
.nonPremiumUser {
  position: relative;
  filter: blur(3px);
}

.nonPremiumUser::before {
  background: rgba(0, 0, 0, 0.3);
  content: "";
  position: absolute;
  top: -15px;
  bottom: 0;
  left: -24px;
  right: -22px;
  z-index: 9;
}

.subscribebtnsHld {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  top: 200px;
  left: 0;
  right: 0;
  z-index: 9;
  visibility: visible;
  z-index: 999;
}

.previewVideoPopup {
  border-radius: 20px;
  position: absolute;
  top: 30%;
  z-index: 999;
  min-width: 500px;
  margin: 0 auto;
  left: 50%;
  background: #ffffff;
  padding: 20px;
  transform: translate(-50%, -50%);
}

.quesNoText {
  font-weight: 400;
}

.subscribeHead {
  font-size: 16px;
  font-weight: 500;
}

.problemslistCntr {
  height: calc(100% - 175px);
  overflow: auto;
}

.difficulty .active {
  background: #757575;
  color: #ffffff;
}

/* custom tooltip */

.Graphtooltip {
  position: relative;
  display: inline-block;
}

.Graphtooltip .Graphtooltiptext {
  visibility: visible;
  width: max-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 1;
  padding: 8px 20px;
}

.Graphtooltip .Graphtooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 52%;
  margin-top: 14px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.Graphtooltip:hover .Graphtooltiptext {
  visibility: visible;
}

.submissionDetails .submissionDetailsCntr {
  max-width: 1300px;
}

.submissionDetails .submissionDetailshld {
  max-width: 800px;
  margin: 0 auto;
}

.submissionDetails .barchartMemoryDistribution .recharts-wrapper {
  min-height: 200px;
}

.submissionDetails .barchartMemoryDistribution .recharts-surface {
  min-height: 200px;
}

.practiceSideHeader {
  background: #f4f4f5;
}

.case-tag {
  display: inline-block;
  border: 0.5px solid #263559;
  border-radius: 50px;
  padding: 5px 16px;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.active-case-tag {
  background: #263559;
}
.test-caseshld .input-output-text {
  font-size: 12px;
  font-weight: 700;
}
.test-cases-value {
  background: #263559;
  border-radius: 10px;
  padding: 10px;
}
.expandedheightoftestcase {
  height: 24vh;
}
.expandedheightofoutput {
  height: 32vh;
}
.heightoftestcase {
  height: 10vh;
}
.heightofoutput {
  height: 18vh;
}

/*Dark*/
.dark-paragraph p {
  color: #ffffff;
}

.dark-paragraph pre {
  background-color: #263559 !important;
  color: #ffffff;
}

.dark-discussion-height {
  min-height: calc(100vh - 50px);
}

.dark-compiler {
  background-color: #0d1028;
  color: #ffffff;
}

.darkTheme .darkHintsText h5,
.darkTheme .darkHintsText p {
  color: #b5c0d3;
}

.darkTheme .descBg {
  background: #0C1431;
  /* margin: 10px 0px 10px 24px; */
}

.darkTheme .darkTabActive {
  background: #0C1431;
}

.leaderBoardContainer {
  max-width: 1300px;
  margin: 0 auto;
  border-radius: 3px;
}
.darkTheme .leaderBoardContainer{
  background: #0C1431;
}
.darkTheme .resizeCntr {
  background: #0C1431 !important;
}
.darkTheme .practRight,
.darkTheme .practRight .innerContainer .innerHead,
.darkTheme .editorHead {
  background: #0C1431;
}
/*Dark*/


/* media query practice */
@media (max-width:834px)and (min-width: 500px) {

  .coursePractice .tabTextNoBorder,
  .coursePracCodingBtn,
  .coursePracFooter,
  .coursePracSubmit {
    display: none;
  }
}

/* .machineAI-section {
  position: fixed;
  bottom: -128px;
  left: 50%;
  transform: translateX(-97%);
  z-index: 36;
  background: linear-gradient(0deg, #000726 0%, rgba(0, 7, 38, 0) 61.09%);
} */

/* .machineAiModal .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: linear-gradient(0deg, #000726 0%, rgba(0, 7, 38, 0) 34.09%) !important;
  height: 271px !important;
} */

.darkTheme .practiceNavigation:first-child .darkTabActive{
  border: 0.5px solid #263559;
  border-right: none;
}

