.typewriter-text {
    border-right: 8px solid rgb(237, 234, 229);
    height: 20px;
    padding: 0px 0px 0px 4px;
}

.header-response-line {
    background: linear-gradient(90deg, #FD00FE -1.22%, #9B7AEE 26.33%, #5FCE5B 53.64%, #E7AD27 75.06%, #F60AFD 101.6%);
    height: 3px;
    animation: gradient 1s ease infinite;
}

@keyframes header-response-line {
    0% {
        background-position: 0 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}

.response-section {
    max-height: 313px;
    min-width: 891px;
    min-height: 313px;
    background: #000726;
    border-radius: 4px 4px 10px 10px;
}

.light-response-section {
    max-height: 313px;
    min-width: 891px;
    min-height: 313px;
    background: #ffffff;
    border-radius: 4px 4px 10px 10px;
}

/* .machine-response-text span {
    font-weight: 500;
    font-size: 14px;
    color: #B5C0D3;
} */

.responseGeneratedText>ol>li {
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 33px !important;
    color: #B5C0D3 !important;
    padding-left: 12px !important;
}

.light-responseGeneratedText>ol>li {
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 33px !important;
    color: #000000 !important;
    padding-left: 12px !important;
}

.responseGeneratedText {
    overflow: auto;
    max-height: 130px;
}

​

 .responseGeneratedHeader p ,.responseGeneratedText .responseGeneratedHeader p{
    font-weight: 500;
    font-size: 13px;
    color: #B5C0D3;
    white-space: pre-wrap;
}

.light-responseGeneratedText .light-responseGeneratedHeader p {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    white-space: pre-wrap;
}

.mini-ai-section {
    background: linear-gradient(233.63deg, #087CE1 10.57%, #E607B0 78.51%);
    box-shadow: 0px 4px 50px rgba(53, 101, 216, 0.2);
    position: absolute;
    display: flex;
    align-items: center;
    max-width: 129px;
    max-height: 31px;
    min-height: 31px;
    border-radius: 50px 0px 0px 50px;
    right: 0;
    top: -30px;
}

.mini-ai-filter {
    padding: 7px 18px;
    /* background: #0FD68D;
    filter: blur(12.5px); */
    position: absolute;
    top: -9px;
    left: 11px;
    z-index: 0;
}

.half-globe-semiCircle {
    /* background: #1700FF;
    filter: blur(116px); */
    border-radius: 50px;
    min-width: 165px;
    min-height: 172px;
    max-width: 165px;
    position: absolute;
    left: 23px;
    bottom: 0px;
}

.askAgainCount {
    background: #263559;
    font-weight: 500;
    font-size: 13px;
    color: #FFFFFF;
    max-width: 191px;
    text-align: center;
    min-height: 35px;
    border-radius: 50px 50px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 38%;
    right: 50%;
}

.machine-responsedownArrow {
    position: absolute;
    bottom: 3px;
    z-index: 999;
}

.response-gradient-line {
    max-height: 3.5px;
    width: 100%;
    position: absolute;
    border-radius: 50px 50px 0px 0px;
    top: 0px;
    left: 0px;
}

.response-section .suggested-question-title {
    font-weight: 600;
    font-size: 13px;
    color: #B5C0D3;
}

.genie-effect__content .suggested-header {
    font-family: "Inter";
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #b5c0d3;
}

.response-section .credit-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #DC4E5A;
}

.response-section .credit-lower-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #B5C0D3;
}
.response-section .credit-lower-text  span{
   color: #087CE1
}

.responseGeneratedText::-webkit-scrollbar {
    width: 5px;
}

.responseGeneratedText::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(248, 248, 248, 0.4); 
    border-radius: 10px;
}

.responseGeneratedText::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(247, 238, 238); 
}

/*Genie*/

.genie-effect {
    position: relative;
}

.genie-effect__content {
    position: absolute;
    bottom: 30vh !important;
    left: -22vw;
    height: 0;
    transform: translateY(100%);
    animation: genie 0.5s forwards;
}

@keyframes genie {
    from {
        height: 0;
        opacity: 0;
        transform: translateY(100%);
    }

    to {
        height: 100%;
        opacity: 1;
        transform: translateY(0%);
    }
}
.genie-effect-mob .askAgainCount{
    left: 20%;
}
.genie-effect-mob{
    width: 100vw;
}
.genie-effect-mob .genie-effect__content{
    left: 9%;
    width: 98%;
}
.genie-effect-mob .response-section {
    max-height: 313px;
    min-width: 300px;
    min-height: 75vh;
}
.genie-effect-mob .responseGeneratedText {
    overflow-y: auto;
    max-height: 178px;
}

@media(min-width:1580px) and (max-width:1700px) {
    .genie-effect__content {
        position: absolute;
        bottom: 210px;
        left: -287px;
        height: 0;
        transform: translateY(100%);
        animation: genie 0.5s forwards;
    }
}