.site-wide-chat-container {
  height: 0;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 0;
  overflow: visible;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: flex-end;
  font-family: "Inter", sans-serif;
}
.site-wide-chat-list-cntr {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  flex: 0 0 288px;
  width: 288px;
  min-width: 0;
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  transform: translateY(0);
  animation: fade-in 167ms cubic-bezier(0.4, 0, 1, 1);
  margin-left: 16px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.site-wide-chat-list-cntr-minimize {
  transform: translateY(100%) translateY(-40px);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 167ms;
}
.site-wide-chat-header {
  background: #3c3e4d;
  /* background: #F44D5E; */
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding: 6px 14px;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.site-wide-chat-header .heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #eeeef2;
}
.site-wide-chat-header .heading .beta-badge {
  background: rgb(255, 178, 61, 0.2);
  /* background-color: #ffb23d; */
  border-radius: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: #ffb23d;
  padding: 2px 10px;
}
.site-wide-chat-header .heading-controls .compose {
  width: 13px;
  height: 13px;
  margin: 8px;
}
.site-wide-chat-header .heading-controls .chevron {
  margin-left: 10px;
}
.search-messages {
  padding: 14px 14px 10px;
}
.search-messages .search-messages-input-hld {
  background: #f5f5f5;
  border-radius: 50px;
  padding: 6px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-messages .search-messages-input-hld input {
  background: #f5f5f5;
  border-radius: 50px;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #b1b2b8;
  padding-left: 10px;
}
.site-wide-chat-list-hld {
  height: inherit;
  flex: 1;
  overflow: auto;
}
.site-wide-chat-list-hld .message-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
  margin: 0 14px;
  border-bottom: 0.5px solid #e2e2e9;
}
.site-wide-chat-list-hld .message-card .image-hld {
  min-width: 48px;
}
.site-wide-chat-list-hld .message-card .image-hld img {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}
.message-card .user-details-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 16px;
}
.user-details-card .user-details-card-hld {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.user-details-card .user-details-card-hld .chatusername {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3c3e4d;
  text-overflow: ellipsis;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
}
.user-details-card .user-details-card-hld .chatlastmessagetime {
  margin: 0;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #b1b2b8;
}
.user-details-card .lastmessage {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 90%;
  word-break: break-word;
  margin-bottom: 0;
}
.newMessageHld {
  display: flex;
  justify-content: space-between;
}
.user-new-message-card {
  display: flex;
}
.newMessageHld .newmessagecount,
.user-details-card-hld .newmessagecount {
  background: #f44d5e;
  border-radius: 100px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 22px;
  height: 20px; */
  padding: 2px 10px;
  margin-left: 3px;
}
.site-wide-conversation-overlay {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  height: 400px;
  max-height: calc(100vh - 100px);
  width: 336px;
  background: #ffffff;
  transform: translateY(0);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 167ms;
  margin-left: 16px;
  border-radius: 10px 10px 0 0;
}
.site-wide-conversation-overlay-minimize {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 167ms;
  transform: translateY(100%) translateY(-47px);
  width: 216px;
}
.site-wide-conversation-overlay .site-wide-conversation-overlay-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 24px 6px 10px;
  position: relative;
  cursor: pointer;
  background: #3c3e4d;
  justify-content: space-between;
  border-radius: 10px 10px 0 0;
  min-height: 47px;
}
.site-wide-conversation-overlay
  .site-wide-conversation-overlay-highlight-header {
  background: #f44d5e;
}
.site-wide-conversation-overlay-header .conversation-user-image {
  width: 35px;
  height: 35px;
  border-radius: 50px;
}
.site-wide-conversation-overlay-header
  .site-wide-conversation-overlay-header-hld {
  display: flex;
  align-items: center;
  width: 100%;
}
.site-wide-conversation-overlay-header .conversation-user-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 0;
  margin-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 70%;
  overflow: hidden;
  text-transform: capitalize;
}
.site-wide-conversation-overlay-header .close-icon {
  width: 12px;
  height: 12px;
}
.site-wide-conversation-overlay-header .more-option {
  margin-right: 20px;
  width: 16px;
  height: 16px;
}
.converzation-messages-input-hld {
  flex-grow: 1;
  height: 0;
  flex-direction: column;
  display: flex;
  border: 1px solid #e2e2e9;
  border-top: none;
}
.converzation-messages-input-hld .conversations-hld {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  flex: 1 0 0px;
  z-index: 0;
  height: 100vh;
}
.converzation-messages-input-hld .input-hld {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  max-height: 100%;
  position: static;
  transition-duration: 132ms;
  transition-timing-function: cubic-bezier(0.34, 0, 0.21, 1);
  transition-property: all;
  width: 100%;
}
.conversations-hld-scrollabel {
  min-height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.converzation-messages-input-hld .emoji-picker {
  position: absolute;
  bottom: 30px;
  right: 12px;
  z-index: 1;
}
.converzation-messages-input-hld .emoji-mart-scroll {
  height: 200px;
}
/* .converzation-messages-input-hld .send-message {
  border: 1px solid #e2e2e9;
  
} */
.converzation-messages-input-hld .send-messagehld {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  max-height: 100%;
  position: static;
  transition-duration: 132ms;
  transition-timing-function: cubic-bezier(0.34, 0, 0.21, 1);
  transition-property: all;
  width: 100%;
  position: relative;
}
.converzation-messages-input-hld .send-message {
  border-top: 1px solid #e2e2e9;
  display: flex;
  width: 100%;
  /* position: relative; */
  /* max-height: 100px;
  overflow: auto;
  flex-grow: 1;
  height: 100%; */
}
.converzation-messages-input-hld .textareahld {
  max-height: 100px;
  overflow: auto;
  flex-grow: 1;
  height: 100%;
}
.converzation-messages-input-hld .textarea {
  /* -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea; */
  min-height: 20px;
  overflow: auto;
  flex-grow: 1;
  background: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* display: flex; */
  align-items: center;
  color: #000000;
  word-break: break-word;
  background: #ffffff;
  border-top: 1px solid #e2e2e9;
  padding: 10px;
  white-space: pre-wrap;
}
.converzation-messages-input-hld .placeholder::after {
  content: "Start typing...";
  cursor: text;
  color: #666666;
}
.converzation-messages-input-hld .smilehld {
  padding: 6px;
}
.converzation-messages-input-hld .send-message input {
  width: inherit;
  border: none;
}
.send-message .sendmessageinfo {
  width: 100%;
  text-align: right;
  margin-bottom: 0;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-right: 6px;
  margin-right: 14px;
  font-style: italic;
}
.send-message input::placeholder {
  font-size: 12px !important;
  font-weight: 400;
  color: #666666 !important;
}
.converzation-messages-input-hld .send-message .sendIcon-hld {
  background: #f1f1f4;
  border-left: 1px solid #e2e2e9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
.converzation-messages-input-hld .send-message .bg-color {
  background: #f44d5e;
}
.converzation-messages-input-hld .sender {
  background: #f2f2f7;
  border-radius: 20px 20px 0px 20px;
  color: #3c3e4d;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  /* padding: 4px 8px; */
  /* display: flex;
  flex-direction: column; */
  max-width: 87%;
  position: relative;
  clear: both;
  line-height: 18px;
  padding: 8px 14px 12px;
  position: relative;
  margin: 0px 0 2px;
  max-width: 85%;
  word-wrap: break-word;
  z-index: -1;
  float: left;
}
.converzation-messages-input-hld .sender h3 {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #3c3e4d;
}
.converzation-messages-input-hld .sender .chat-tip {
  position: absolute;
  top: 0;
  right: -8px;
}
.converzation-messages-input-hld .sender .time,
.converzation-messages-input-hld .receiver .time {
  text-align: right;
  line-height: 1;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #3c3e4d;
  /* display: inline-block; */
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -12px;
}
.converzation-messages-input-hld .receiver .time {
  color: #ffffff;
}
.converzation-messages-input-hld .senderHld {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 0 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  white-space: pre-wrap;
}
.converzation-messages-input-hld .receiver {
  background: #5a6575;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* display: flex;
  flex-direction: column; */
  color: #ffffff;
  border-radius: 20px 20px 20px 0px;
  margin-bottom: 0;
  /* padding: 4px 14px; */
  max-width: 90%;
  position: relative;
  /* color: #000; */
  clear: both;
  line-height: 18px;
  padding: 8px 14px 12px;
  position: relative;
  margin: 0px 0 2px;
  max-width: 85%;
  word-wrap: break-word;
  z-index: -1;
  float: left;
}
.converzation-messages-input-hld .receiver h3 {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
.converzation-messages-input-hld .receiverHld {
  display: flex;
  justify-content: flex-start;
  padding: 0 20px 0 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  white-space: pre-wrap;
}
.converzation-messages-input-hld .receiver .chat-tip {
  position: absolute;
  top: 0;
  left: -7px;
}
.converzation-messages-input-hld .receiverHld .userImg {
  width: 32px;
  height: 32px;
  border-radius: 50px;
  margin-right: 10px;
  margin-top: 2px;
}
.conversations-hld .searchUser {
  width: 100%;
  border: none;
  background: #f5f5f5;
  border-bottom: 1px solid #e2e2e9;
  margin: 0;
  padding: 10px 10px 9px 42px;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.conversations-hld .searchUser::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #b1b2b8;
}
.conversations-hld .searchUserImg {
  position: absolute;
  padding: 8px;
  width: 35px;
  margin-left: 10px;
}
.conversations-search-user-hld {
  position: relative;
}
.searuserlist {
  z-index: 3;
  clear: both;
  overflow: scroll;
  max-height: 235px;
  position: absolute;
  padding: 0;
  width: 100%;
  top: 40px;
}
.searuserlistHld {
  height: 235px;
  margin-top: 0;
}
.searuserlist .searchusercard {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid #e2e2e9;
  padding: 4px 18px;
  cursor: pointer;
}
.searuserlist .searchusercard .searchImgHld img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-right: 10px;
}
.searuserlist .searchusercard .searchusername {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3c3e4d;
  margin-bottom: 0px;
}
.searuserlist .searchusercard .searchUserdetails {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  width: 270px;
  text-overflow: ellipsis;
}
.searuserlist .searchusercard .searchUserdetails p {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  margin-bottom: 0px;
}
