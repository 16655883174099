.bootstrapisoauth .mainbody {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* height:100vh;
    width:100vw; */
	font-weight: 400;
	/* font-size: 1rem; */
}
/* #root
  {
      width:100%;
      height:100%;
  } */
.bootstrapisoauth code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.bootstrapisoauth .overview,
.overview > p,
ul,
li {
	font-size: 16px;
}

.bootstrapisoauth li a,
.dropbtn {
	display: inline-block;
	color: #868e96;
	text-align: center;
	/* padding: 14px 16px; */
	text-decoration: none;
	/* font-size: 14px; */
}

.bootstrapisoauth li a:hover,
.dropdown:hover .dropbtn {
	/* background-color: red; */
	text-decoration: none;
	color: #ff006a;
}

.bootstrapisoauth li.dropdown {
	display: inline-block;
}

.bootstrapisoauth .dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.bootstrapisoauth .dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	text-align: left;
}

.bootstrapisoauth .dropdown-content a:hover {
	background-color: #f1f1f1;
	color: #ff006a;
}

.bootstrapisoauth .dropdown:hover .dropdown-content {
	display: block;
}

.bootstrapisoauth .navbar {
	box-shadow: 0px 0px 1px 1px #e7e7e7;
}
.bootstrapisoauth .search-box {
	position: relative;
}
.bootstrapisoauth .search-box > input {
	border: none;
}
.bootstrapisoauth .search-box > input:enabled {
	outline: 0px;
}
.bootstrapisoauth .search-button {
	position: absolute;
	left: 0px;
	top: 10%;
}
.bootstrapisoauth .more-button {
	margin-left: 6%;
	margin-right: 3%;
}
.bootstrapisoauth .user-image-container {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}
.bootstrapisoauth .user-image-container > img {
	width: 100%;
	height: 100%;
	border-radius: inherit;
}
.bootstrapisoauth .bell-icon {
	font-size: 22px;
	position: relative;
}
.bootstrapisoauth .settings-icon {
	font-size: 22px;
	position: relative;
}
.bootstrapisoauth .dot1 {
	position: absolute;
	background-color: #ff006a;
	border-radius: 50%;
	width: 8px;
	border: 1px solid white;
	height: 8px;
	top: 0%;
	right: 0%;
}
.bootstrapisoauth .dot2 {
	position: absolute;
	background-color: #009b00;
	border-radius: 50%;
	width: 8px;
	border: 1px solid white;
	height: 8px;
	top: 0%;
	right: 0%;
}
.bootstrapisoauth .left-menu-item {
	height: 60px;
	margin-top: 3px;
	margin-bottom: 3px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding-left: 15%;
	box-sizing: border-box;
	cursor: pointer;
	transition: border-left ease-in-out 0.1s;
}
.bootstrapisoauth .left-menu-item > p {
	margin: 0px;
}
.bootstrapisoauth .left-menu-item:hover {
	/* border-left: 5px solid #ff717a; */
	background-color: #f4f5f8;
	/* color: #ff0080; */
}
.bootstrapisoauth .menu-ative {
	border-left: 5px solid #ff717a;
	background-color: #f4f5f8;
	color: #ff0080;
}
.bootstrapisoauth .banner-main {
	width: 100%;
	height: 230px;
	position: relative;
}

@media screen and (min-width: 992px) {
	.bootstrapisoauth .body-nav-sponsored {
		width: 100%;
		height: 420px !important;
		cursor: pointer;
	}
}

@media screen and (max-width: 1200px) {
	.bootstrapisoauth .banner-main {
		height: 150px;
	}
}
@media screen and (max-width: 984px) {
	.bootstrapisoauth .banner-main {
		height: 100px;
	}
}
@media screen and (max-width: 854px) {
	.bootstrapisoauth .banner-main {
		height: 180px;
	}
}
@media screen and (max-width: 768px) {
	.bootstrapisoauth .banner-main {
		height: 250px;
	}
}
@media screen and (max-width: 688px) {
	.bootstrapisoauth .banner-main {
		height: 230px;
	}
}
@media screen and (max-width: 600px) {
	.bootstrapisoauth .banner-main {
		height: 200px;
	}
}
@media screen and (max-width: 450px) {
	.bootstrapisoauth .banner-main {
		height: 170px;
	}
}
.bootstrapisoauth .pink-color-svg {
	width: 100%;
	height: 100%;
}
.bootstrapisoauth .pink-color-svg > img {
	width: 100%;
	height: 100%;
}
.bootstrapisoauth .space-man {
	position: absolute;
	top: 0%;
	right: -2%;
	width: 60%;
}
.bootstrapisoauth .space-man > img {
	width: 100%;
	height: 100%;
}
@media screen and (max-width: 768px) {
	.bootstrapisoauth .space-man {
		width: 50%;
		top: 0%;
		right: 0%;
	}
}
.bootstrapisoauth .banner-heading {
	position: absolute;
	/* top: 20%; */
	bottom: 25%;
	left: 5%;
	color: #fb4c6b;
	font-family: "Cabin", sans-serif;
	font-size: 35px;
}
@media screen and (min-width: 1000px) {
	.bootstrapisoauth .banner-heading {
		/* top: 40%; */
		font-size: 40px;
	}
}
@media screen and (max-width: 1050px) {
	.bootstrapisoauth .banner-heading {
		top: 40%;
	}
}
@media screen and (min-width: 1200px) {
	.bootstrapisoauth .banner-heading {
		font-size: 45px;
	}
}
@media screen and (min-width: 1400px) {
	.bootstrapisoauth .banner-heading {
		font-size: 55px;
	}
}
@media screen and (max-width: 767px) {
	.bootstrapisoauth .banner-heading {
		font-size: 55px;
		top: 30%;
	}
}
@media screen and (max-width: 728px) {
	.bootstrapisoauth .banner-heading {
		font-size: 50px;
	}
}
@media screen and (max-width: 654px) {
	.bootstrapisoauth .banner-heading {
		font-size: 45px;
	}
}
@media screen and (max-width: 598px) {
	.bootstrapisoauth .banner-heading {
		font-size: 40px;
	}
}
@media screen and (max-width: 523px) {
	.bootstrapisoauth .banner-heading {
		font-size: 35px;
	}
}
@media screen and (max-width: 451px) {
	.bootstrapisoauth .banner-heading {
		font-size: 30px;
		top: 25%;
	}
}
@media screen and (max-width: 400px) {
	.bootstrapisoauth .banner-heading {
		font-size: 25px;
	}
}
@media screen and (max-width: 329px) {
	.bootstrapisoauth .banner-heading {
		font-size: 20px;
	}
}
.bootstrapisoauth .banner-menu {
	/* overflow: auto; */
	width: 100%;
	height: 50px;
	background-color: white;
	/* position: absolute; */
	bottom: 4%;
	box-shadow: 0px 0px 4px 1px #a7a7a7;
}
.bootstrapisoauth .banner-menu-item {
	height: 100%;
	box-sizing: border-box;
	margin: 0px 20px;
	font-weight: 500;
	cursor: pointer;
	padding: 10px;
}
.bootstrapisoauth .banner-menu-item > p {
	transition: color ease-in-out 0.1s;
}
@media screen and (max-width: 400px) {
	.bootstrapisoauth .banner-menu-item {
		margin: 0px 10px;
	}
	.bootstrapisoauth .banner-menu-item > p {
		font-size: 12px;
	}
	.bootstrapisoauth .banner-menu {
		padding-left: 10px !important;
		overflow: scroll;
	}
}
.bootstrapisoauth .banner-menu-item:hover > p {
	color: #fb879a !important;
}
/* .bootstrapisoauth .active-nav > a > p {
	color: #fb879a !important;
} */
@media screen and (max-width: 992px) {
	.bootstrapisoauth .body-nav {
		width: 100%;
		height: 50px;
		margin-bottom: 10px;
		cursor: pointer;
	}
	.bootstrapisoauth .body-nav > div > p {
		margin: 0px;
	}
	.bootstrapisoauth .body-nav > div > p > i {
		font-size: 20px;
	}
	.bootstrapisoauth .body-nav > div > p > span {
		font-size: 12px;
	}
	.bootstrapisoauth .body-nav > div {
		height: 20px;
		width: 20px;
		border-radius: 5px;
		padding: 30px 40px 30px 40px;
		transition: background-color ease-in-out 0.1s;
	}
	.bootstrapisoauth .body-nav > div:hover {
		background-color: #f4f5f8;
		color: #b0b0b0;
	}
}
@media screen and (min-width: 992px) {
	.bootstrapisoauth .body-nav {
		width: 100%;
		height: 350px;
		cursor: pointer;
		background-color: #fff;
		box-shadow: 0px 0px 7px 2px #d1d1d1;
		margin-right: 14px;
		padding-top: 10px;
	}
	.bootstrapisoauth .body-nav > div > p {
		margin: 0px;
		text-align: center;
	}
	.bootstrapisoauth .body-nav > div > p > i {
		font-size: 20px;
	}
	.bootstrapisoauth .body-nav > div > p > span {
		font-size: 12px;
	}
	.bootstrapisoauth .body-nav > div {
		height: 20px;
		width: 20px;
		border-radius: 5px;
		padding: 30px 50px;
		transition: background-color ease-in-out 0.1s;
		margin: 10px 0px;
	}
	.bootstrapisoauth .body-nav > div:hover {
		background-color: #f4f5f8;
	}
}

@media screen and (max-width: 450px) {
	.bootstrapisoauth .banner-main {
		height: 240px;
	}
	.bootstrapisoauth .hack-name {
		font-size: 20px;
	}
	.bootstrapisoauth .hack-time {
		font-size: 14px;
	}
}

@media screen and (max-width: 600px) {
	.bootstrapisoauth .mobile {
		box-shadow: none;
	}

	.bootstrapisoauth .mobile-time-start-end {
		height: 175px;
	}

	.bootstrapisoauth .hackathon-status {
		margin-bottom: 10px;
	}

	.bootstrapisoauth .body-nav {
		width: 100%;
		height: 75px;
		cursor: pointer;
		box-shadow: 0px 0px 7px 2px #d1d1d1;
	}
	.bootstrapisoauth .body-nav > div > p {
		margin: 0px;
		text-align: center;
	}
	.bootstrapisoauth .body-nav > div > p > i {
		font-size: 12px;
	}
	.bootstrapisoauth .body-nav > div > p > span {
		font-size: 9px;
	}
	.bootstrapisoauth .body-nav > div {
		height: 40px;
		width: 40px;
		border-radius: 5px;
		padding: 25px 30px 25px 30px;
		transition: background-color ease-in-out 0.1s;
		margin: 10px 0px 10px 0px;
	}
	.bootstrapisoauth .body-nav > div:hover {
		background-color: #f4f5f8;
	}
}
.bootstrapisoauth .graph {
	height: 400px;
	box-sizing: border-box;
	box-shadow: 0px 0px 7px 2px #bebebe;
	position: relative;
}
.bootstrapisoauth .sorter {
	position: absolute;
	top: 5%;
	right: 5%;
}
.bootstrapisoauth .sorter > div > button {
	border-radius: 0px;
	height: 30px;
}
.bootstrapisoauth .sorter > div > button:hover {
	background-color: white;
	color: blue;
}
.bootstrapisoauth .ctt {
	box-shadow: 0px 0px 1px 1px #cecece;
	background-color: rgba(255, 255, 255, 0.8);
	font-size: 16px;
}
.bootstrapisoauth .ctt p {
	margin: 0px;
}
.bootstrapisoauth .rules {
	width: 100%;
	position: relative;
	/* box-shadow: 0px 0px 7px 2px #d1d1d1; */
	background-color: #ffffff;
	/* min-height: 500px; */
}
.bootstrapisoauth .rule-box {
	/* width: 100%; */
	/* position: relative; */
	/* box-shadow: 0px 0px 7px 2px #d1d1d1; */
	min-height: 500px;
}

.bootstrapisoauth .notebook {
	position: absolute;
	bottom: 10px;
	right: 10px;
	opacity: 0.9;
	/* z-index: -1; */
}
.bootstrapisoauth .notebook2 {
	position: absolute;
	bottom: 142px;
	right: 30px;
	opacity: 0.9;
	/* z-index: -1; */
}
.bootstrapisoauth .notebook3 {
	position: absolute;
	bottom: 25px;
	right: 30px;
	opacity: 0.9;
	/* z-index: -1; */
}
.bootstrapisoauth .thread {
	position: absolute;
	bottom: 113.4px;
	right: 9px;
	opacity: 0.9;
	/* z-index: -1; */
}
.bootstrapisoauth .thread2 {
	position: absolute;
	bottom: 209.4px;
	right: 9px;
	opacity: 0.9;
	/* z-index: -1; */
}
.bootstrapisoauth .rules-heading {
	width: 100px;
	position: relative;
}
.bootstrapisoauth .rules-pink {
	width: 100px;
	height: 50px;
}
.bootstrapisoauth .Evaluation-pink {
	width: 250px;
	height: 50px;
}
.bootstrapisoauth .rules-pink > img {
	height: 100%;
	width: 100%;
}
.bootstrapisoauth .rules-text {
	position: absolute;
	top: 18%;
	left: 21%;
	color: #eb3556;
}
.bootstrapisoauth .Evaluation-text {
	position: absolute;
	top: 18%;
	left: 10%;
	color: #eb3556;
	font-size: 1rem;
}
.bootstrapisoauth .rules-data > ul {
	list-style: none;
	font-size: 1rem;
}
.bootstrapisoauth ul > li {
	position: relative;
}
.bootstrapisoauth .li-dot {
	position: absolute;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background-color: #fa375a;
	top: 5px;
	left: -26px;
}
.bootstrapisoauth .left-line {
	width: 3px;
	height: 100%;
	position: absolute;
	top: 10px;
	background-color: #fa375a;
	left: -22.5px;
}
.bootstrapisoauth .right-stats {
	box-shadow: 0px 0px 7px 2px #dadada;
}
.bootstrapisoauth .pie-chart-container {
	height: 500px;
}
.bootstrapisoauth .circle {
	position: relative;
	height: 30px;
	width: 30px;
	border-radius: 50%;
}
.bootstrapisoauth .inner-circle {
	position: absolute;
	height: 15px;
	width: 15px;
	border-radius: inherit;
	background-color: white;
	top: 25%;
	left: 25%;
}
.bootstrapisoauth .circle ~ div > p {
	margin: 0px;
}
.bootstrapisoauth .single-pie-legend {
	position: relative;
}
.bootstrapisoauth .coverer {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-image: linear-gradient(
		0deg,
		rgba(255, 255, 255, 0.4) 25%,
		rgba(0, 0, 0, 0) 75%
	);
}

iframe {
	/* text-align: center; */
	height: 400px;
	width: 700px;
	display: block;
	border-style: none;
}

@media only screen and (max-width: 600px) {
	.bootstrapisoauth iframe {
		height: 150px;
		width: 270px;
		display: block;
		border-style: none;
	}
}

@media screen and (max-width: 450px) {
	.bootstrapisoauth .banner-main {
		height: 240px;
	}
	.bootstrapisoauth .hack-name {
		font-size: 20px;
	}
	.bootstrapisoauth .hack-time {
		font-size: 14px;
	}
}

.resume-drop-zone .MuiDialog-container{
	height: 173px !important;
}