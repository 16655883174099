
.noDiscussionImg{
  height: 100px !important;
  width: 86px;
}
.list-img-hld img {
  border-radius: 50%;
}

.darkTheme .dark-discussion-container .discussion-text,
.darkTheme .dark-discussion-container .discussion-text *,
.darkTheme .list-discussion-content *,
.darkTheme .list-discussion-content {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: "Inter", sans-serif !important;
  color: #b5c0d3 !important;
  background-color: transparent !important;
  background: transparent !important;
  border: none !important;
  text-decoration: none !important;
  margin: 0;
  padding: 0;
  width: auto !important;
  float: unset !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  white-space: unset !important;
  line-height: 28px !important;
}
.discussion-list-container{
  background: transparent;
  border: none;
}
.discussion-list-container .discussion-list-btn ,.single-inner-discussion .discussion-list-btn{
  background: #f44d5e;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
}

.discussion-list-container .discussion-list-btn p ,.single-inner-discussion .discussion-list-btn p{
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
}
.discussion-list-container .discussion-list-btn p{
  font-size: 14px;
}
.darkTheme .discussion-list-btn {
  border-radius: 50px !important;
  box-shadow: none;
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
  font-size: 14px;
  font-weight: 500;
}
.list-reaction-text{
  width: 25px;
}
.discussion-list-container .list-discussion-header {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.list-discussion-content {
  font-size: 16px !important;
  color: #000000;
  font-weight: 500;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 28px;
}

.darkTheme .list-discussion-content,
.darkTheme .discussion-list-container .list-discussion-header {
  color: #b5c0d3;
}

.list-discussion-gray-text ,.list-no-discussion-gray-text{
  font-weight: 400;
  font-size: 14px;
  line-height: 34px;
  color: #757575;
}
.list-no-discussion-gray-text{
  line-height:24px;
}
.list-discussion-gray-bold{
  font-weight: 600;
  font-size: 16px;
}
.darkTheme .list-discussion-gray-text ,.darkTheme .list-no-discussion-gray-text {
  color: #b5c0d3;
}

.list-dot {
  background: #757575;
  display: inline-block;
  border-radius: 50px;
  height: 4px;
  width: 4px;
}

.darkTheme .list-dot {
  background: #B5C0D3;
}
.discussion-list-container .discussion-list-item {
  border: none;
  border-top: 0.5px solid #D9D9D9;
}
.discussion-list-container .discussion-list-item:last-child {
  border-bottom: 0.5px solid #D9D9D9;
}
.darkTheme .discussion-list-container .discussion-list-item {
  border: none;
  border-top: 0.5px solid #263559;
}

.darkTheme .discussion-list-item:last-child {
  border-bottom: 0.5px solid #263559;
}

.discussion-list-img {
  height: 18px;
  width: 17px;
}

.darkTheme .mobile-discussion-list {
  background: #0c1431;
  margin-left: -4px;
  margin-right: -4px;
}

.darkTheme .mobile-discussion-action {
  border-radius: 5px;
  border: 0.5px solid #263559;
  background: #081030;
  border-left: none;
  border-right: none;
}

.darkTheme .discussion-list-container .mobile-discussion-listItem {
  border-radius: 5px;
  border: 0.5px solid #263559;
  background: #0C1431;
  border-bottom: none;
  margin-bottom: 20px;
}

.mobile-discussion-listItem .list-discussion-gray-text{
  font-size: 12px;
  line-height: 20px;
}
.mobile-discussion-listItem .list-dot{
  height: 2px;
  width: 2px;
}
.mobile-discussion-bold{
  font-weight: 500;
}