.hackathon-timer .hackathon-timer-hld {
  background: linear-gradient(
    90.4deg,
    #955334 0.11%,
    #7b396f 52.74%,
    #5b3786 99.83%
  );
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 330px;
  height: inherit;
  margin: auto;
  position: relative;
}
.hackathon-timer .hackathon-timer-hld h2 {
  margin-left: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  line-height: 32px;
  color: #ffffff;
}
