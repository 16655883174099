.searchModal {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 70px;
}
.searchModalHld {
  background: #f2f3f4;
  border-radius: 10px;
  width: 900px;
  /* padding: 20px; */
  height: fit-content;
}
.searchcard {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  margin: 20px;
}
.search-input {
  border: none;
  width: 90%;
  line-height: 20px;
  padding: 6px 6px 6px 0;
  background: transparent;
}
.search-input::placeholder {
  font-size: 12px;
  font-weight: 500;
}
.smallText-search {
  font-weight: 400;
  font-size: 13px;
  color: #757575;
  margin-bottom: 10px;
  display: block;
}
.modules {
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  border-radius: 50px;
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  margin-right: 10px;
  padding: 7px 14px;
  cursor: pointer;
}
.activeModules {
  background: #fbe3d7;
  border: 0.5px solid #fbe3d7;
}
.selectedModuleshld {
  display: flex;
  overflow: hidden;
  flex-direction: row-reverse;
  max-width: 500px;
}
.selectedModules {
  background: #fbe3d7;
  border: 0.5px solid #fbe3d7;
  border-radius: 50px;
  font-weight: 500;
  font-size: 11px;
  color: #000000;
  margin-right: 10px;
  padding: 0px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
}
.selectedModules img {
  margin-left: 4px;
}
.searchcard .searchButton {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
  border-radius: 50px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 74px;
}
.searchcard .activesearchButton {
  background: linear-gradient(88.78deg, #f04f5e 20.65%, #e97f3d 98.95%);
}
.queryHighlight {
  font-size: 14px;
  font-weight: 700;
}
.search-results-text {
  font-weight: 400;
  font-size: 13px;
}
.search-results-cntr {
  border-top: 0.5px solid #d9d9d9;
}
.leftabs {
  border-right: 0.5px solid #d9d9d9;
  width: 230px;
  /* max-height: 25rem;
  overflow-y: scroll; */
}
.leftabs ul {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  height: 26em;
  margin-bottom: 0;
}
.leftabs ul li {
  /* margin-bottom: 14px; */
  display: inline-grid;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 10px 10px 10px 20px;
  margin: 10px 0;
}
.leftabs ul .search-active-tab {
  background: linear-gradient(88.78deg, #f04f5e 20.65%, #e97f3d 98.95%);
  color: white;
}
.darkTheme .leftabs ul .search-active-tab {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
}
.darkTheme .leftabs ul .search-active-tab span {
  color: #ffffff;
}
.leftabs ul li span {
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  cursor: pointer;
}
.darkTheme .leftabs ul li span {
  color: #B5C0D3;
}
.searchCardhld {
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
}
.darkTheme .searchCardhld {
  background: #263559;
  border: 0.5px solid #253356;
}
.searchCardhld .searchCardcapsule {
  background: #f2f3f4;
  border-radius: 50px;
  display: inline-block;
  padding: 4px 16px;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 500;
}
.searchCardhld .searchTitle {
  font-weight: 700;
  font-size: 12px;
  color: #000000;
}
.searchCardhld .searchDescription,
.searchCardhld .searchDescription p {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.searchDatas {
  max-height: 30rem;
  overflow-y: scroll;
}
/* Dark Mode */
.darkTheme .searchModalHld {
  background: #1d2542;
}
.darkTheme .searchcard {
  background: #151d38;
  border: 1px solid #253356;
}
.darkTheme .searchcard .searchButton {
  background: #263559;
}
.darkTheme .modules {
  background: #263559;
  border: 0.5px solid #151d38;
}
.darkTheme .activeModules {
  background: #B5C0D3;
}
.darkTheme .selectedModules {
  background: rgba(181, 192, 211, 0.21);
  border: 0.5px solid transparent;
}
.darkTheme .searchCardhld .searchCardcapsule {
  background: #1D2542;
}
.darkTheme .searchcard .activesearchButton {
  background: linear-gradient(92.97deg, #445EB7 10.8%, #6540D7 94.39%);
}
.darkTheme .leftabs{
  border-right: 0.5px solid #253356;
}
.darkTheme .search-results-cntr {
  border-top: 0.5px solid #253356;
}
.darkTheme .search-input::placeholder {
  color: #B5C0D3;
}
/* .darkTheme .MuiModal-backdrop  {
  background-color: rgba(2, 5, 12, 0.8);
} */