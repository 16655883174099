/* .bootstrap-iso { */
/*-----------------------------------------------------------------------------------

	Theme Name : Lenoto
	Theme URI  : http://themeforest.net/user/zytheme
	Description: Lenoto is a pixel perfect creative html5 landing page  based on designed with great attention to details, flexibility and performance. It is ultra professional, smooth and sleek, with a clean modern layout.
	Author     : zytheme
	Author URI : http://themeforest.net/user/zytheme
	Version    : 1.0

-----------------------------------------------------------------------------------*/
/*!
// Contents
// ------------------------------------------------>

1.  Global Styles
2.  Typography
3.	Color
4.	Align
5.	Grid
6.	Background
7.	Buttons
8.	Forms
9.	Heading
10. Loading
11. Contact
12. Header
13. Menu
14. Clients
15. Hero
16. Video
17. Services
18. Thank You
19. Testimonial
20. Feature
21. Action
22. Pricing
23. Carousel
24. Landing

/*------------------------------------*\
    #GLOBAL STYLES
\*------------------------------------*/
/*
WARNING! DO NOT EDIT THIS FILE!

To make it easy to update your theme, you should not edit the styles in this file. Instead use 
the custom.css file to add your styles. You can copy a style from this file and paste it in 
custom.css and it will override the style in this file. You have been warned! :)
*/
/* Medium Devices, Desktops */
/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/* Heading Text */
/* Aligning Text */
/* Weight Text */
/* Text Color */
/* Custom, iPhone Retina */
/* Small Devices, Tablets */
/*------------------------------------*\
    #COLORS
\*------------------------------------*/
/*------------------------------------*\
    #Align
\*------------------------------------*/
/* All margin */
/* top margin */
/* bottom margin */
/* right margin */
/* left margin */
/* All padding */
/* top padding */
/* bottom padding */
/* right padding */
/* left padding */
/* Postions */
/* Zindex*/
/* Borders */
/* Display */
/*------------------------------------*\
    #Grid
\*------------------------------------*/
/* Custom, iPhone Retina */
/* Custom, iPhone Retina */
/* Postion Helpers */
/*------------------------------------*\
    #BACKGROUNDS
\*------------------------------------*/
/* Background Image */
/* Background Color Palettes */
/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
/* Button Primary */
/* Button Secondary*/
/* Button White */
/* Button Block */
/* Button Rounded */
/* Button Bordered */
/* Btn Arrows */
/* Button Sizes */
/*------------------------------------*\
    #FORMS
\*------------------------------------*/
/* Placeholder */
/*------------------------------------*\
    #Heading
\*------------------------------------*/
/* Custom, iPhone Retina */
/*------------------------------------*\
    #LOADING-SECTION
\*------------------------------------*/
/*------------------------------------*\
    #Contact Form
\*------------------------------------*/
/*------------------------------------*\
    #HEADER
\*------------------------------------*/
/*------------------------------------*\
    #Menu
\*------------------------------------*/
/* Small Devices, Tablets */
/* Large Devices, Wide Screens */
/*------------------------------------*\
    #Menu
\*------------------------------------*/
/*------------------------------------*\
    #Hero
\*------------------------------------*/
/*------------------------------------*\
    #Video
\*------------------------------------*/
/*------------------------------------*\
    #Services
\*------------------------------------*/
/*------------------------------------*\
    #Thanks
\*------------------------------------*/
/*------------------------------------*\
    #Testimonial
\*------------------------------------*/
/*------------------------------------*\
    #Features
\*------------------------------------*/
/* feature left circle  */
/* Custom, iPhone Retina */
/* Small Devices, Tablets */
/*------------------------------------*\
    #Call To Action
\*------------------------------------*/
/*------------------------------------*\
    #PRICING-SECTION
\*------------------------------------*/
/*------------------------------------*\
    #Footer
\*------------------------------------*/
/* Custom, iPhone Retina */
/*------------------------------------*\
    #Carousel
\*------------------------------------*/
/* Custom, iPhone Retina */
/*------------------------------------*\
    #Promo Page
\*------------------------------------*/
/* } */
.bootstrap-iso,
.bootstrap-iso {
	/* overflow-x: hidden; */
}
.bootstrap-iso {
	font-size: 13px;
}
.bootstrap-iso .wrapper {
	background-color: #ffffff;
}
.bootstrap-iso * {
	outline: none;
}
.bootstrap-iso ::-moz-selection {
	text-shadow: none;
}
.bootstrap-iso ::selection {
	background-color: #e8505b;
	color: #ffffff;
	text-shadow: none;
}
.darkTheme ::selection{
	background-color: #445eb7;
	color: #ffffff;
	text-shadow: none;
}
.bootstrap-iso a {
	-webkit-transition: 0.3s ease;
	-o-transition: 0.3s ease;
	transition: 0.3s ease;
	color: #e8505b;
}
.bootstrap-iso a:hover {
	color: #e22331;
	text-decoration: none;
}
.bootstrap-iso a:focus {
	color: #e22331;
	outline: none;
	text-decoration: none;
}
.bootstrap-iso a:active {
	color: #e22331;
	outline: none;
	text-decoration: none;
}
.bootstrap-iso textarea {
	resize: none;
}
.bootstrap-iso button.btn {
	margin-bottom: 0;
}
.bootstrap-iso section {
	background-color: #fff;
}
.bootstrap-iso .modal-backdrop {
	z-index: 1020;
	background-color: rgba(34, 34, 34, 0.95);
}
.bootstrap-iso .fullscreen {
	height: 100vh !important;
}
@media only screen and (max-width: 992px) {
	.bootstrap-iso .fullscreen {
		height: auto !important;
	}
}
.bootstrap-iso .h1,
.bootstrap-iso .h2,
.bootstrap-iso .h3,
.bootstrap-iso .h4,
.bootstrap-iso .h5,
.bootstrap-iso .h6,
.bootstrap-iso h1,
.bootstrap-iso h2,
.bootstrap-iso h3,
.bootstrap-iso h4,
.bootstrap-iso h5,
.bootstrap-iso h6 {
	color: #212529;
	/* font-family: "Roboto", sans-serif; */
	font-weight: 700;
	margin: 0 0 28px;
	line-height: 1;
}
.bootstrap-iso .h1,
.bootstrap-iso h1 {
	font-size: 52px;
}
.bootstrap-iso .h2,
.bootstrap-iso h2 {
	font-size: 42px;
}
.bootstrap-iso .h3,
.bootstrap-iso h3 {
	font-size: 38px;
}
.bootstrap-iso .h4,
.bootstrap-iso h4 {
	font-size: 32px;
}
.bootstrap-iso .h5,
.bootstrap-iso h5 {
	font-size: 24px;
}
.bootstrap-iso .h6,
.bootstrap-iso h6 {
	font-size: 18px;
}
.bootstrap-iso p {
	color: #868e96;
	font-size: 13px;
	font-weight: 400;
	line-height: 22px;
}
.bootstrap-iso .lead {
	font-size: 16px;
	line-height: 1.8;
}
.bootstrap-iso .text--left {
	text-align: left !important;
}
.bootstrap-iso .text--right {
	text-align: right !important;
}
.bootstrap-iso .text--center {
	text-align: center !important;
}
.bootstrap-iso .text--just {
	text-align: justify !important;
}
.bootstrap-iso .align--top {
	vertical-align: top;
}
.bootstrap-iso .align--bottom {
	vertical-align: bottom;
}
.bootstrap-iso .align--middle {
	vertical-align: middle;
}
.bootstrap-iso .align--baseline {
	vertical-align: baseline;
}
.bootstrap-iso .bold {
	font-weight: bold;
}
.bootstrap-iso .regular {
	font-weight: normal;
}
.bootstrap-iso .italic {
	font-style: italic;
}
.bootstrap-iso .break-word {
	word-wrap: break-word;
}
.bootstrap-iso .no-wrap {
	white-space: nowrap;
}
.bootstrap-iso .text-white {
	color: #ffffff !important;
}
.bootstrap-iso .text-gray {
	color: #fef6f6 !important;
}
.bootstrap-iso .text-black {
	color: #212529;
}
.bootstrap-iso .text-theme {
	color: #e8505b;
}
.bootstrap-iso .text--capitalize {
	text-transform: capitalize !important;
}
.bootstrap-iso .text--uppercase {
	text-transform: uppercase !important;
}
.bootstrap-iso .font-heading {
	font-family: "Roboto", sans-serif;
}
.bootstrap-iso .font-body {
	font-family: "Roboto", sans-serif;
}
.bootstrap-iso .font-18 {
	font-size: 24px;
}
.bootstrap-iso .font-16 {
	font-size: 16px;
}
.bootstrap-iso .font-20 {
	font-size: 20px;
}
.bootstrap-iso .font-40 {
	font-size: 40px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
	.bootstrap-iso text-center-xs {
		text-align: center !important;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.bootstrap-iso text-center-sm {
		text-align: center !important;
	}
}
.bootstrap-iso .higlighted-style1 {
	background-color: #e8505b;
	color: #fff;
	padding: 5px 0;
}
.bootstrap-iso .higlighted-style2 {
	background-color: #212529;
	color: #fff;
	padding: 5px 0;
}
.bootstrap-iso .higlighted-style3 {
	background-color: #fef6f6;
	color: #e8505b;
	padding: 5px 0;
}
.bootstrap-iso .color-heading {
	color: #212529 !important;
}
.bootstrap-iso .color-theme {
	color: #e8505b !important;
}
.bootstrap-iso .color-white {
	color: #ffffff !important;
}
.bootstrap-iso .color-gray {
	color: #fef6f6 !important;
}
.bootstrap-iso address,
.bootstrap-iso blockquote,
.bootstrap-iso dd,
.bootstrap-iso dl,
.bootstrap-iso fieldset,
.bootstrap-iso form,
.bootstrap-iso ol,
.bootstrap-iso p,
.bootstrap-iso pre,
.bootstrap-iso table,
.bootstrap-iso ul {
	margin-bottom: 20px;
}
.bootstrap-iso section {
	padding-top: 100px;
	padding-bottom: 100px;
}
.bootstrap-iso .m-0 {
	margin: 0 !important;
}
.bootstrap-iso .m-xs {
	margin: 10px;
}
.bootstrap-iso .m-sm {
	margin: 20px;
}
.bootstrap-iso .m-md {
	margin: 40px;
}
.bootstrap-iso .m-lg {
	margin: 80px;
}
.bootstrap-iso .mt-0 {
	margin-top: 0;
}
.bootstrap-iso .mt-xs {
	margin-top: 10px;
}
.bootstrap-iso .mt-10 {
	margin-top: 10px !important;
}
.bootstrap-iso .mt-20 {
	margin-top: 20px !important;
}
.bootstrap-iso .mt-30 {
	margin-top: 30px !important;
}
.bootstrap-iso .mt-40 {
	margin-top: 40px !important;
}
.bootstrap-iso .mt-50 {
	margin-top: 50px !important;
}
.bootstrap-iso .mt-60 {
	margin-top: 60px;
}
.bootstrap-iso .mt-70 {
	margin-top: 70px !important;
}
.bootstrap-iso .mt-80 {
	margin-top: 80px !important;
}
.bootstrap-iso .mt-90 {
	margin-top: 90px !important;
}
.bootstrap-iso .mt-100 {
	margin-top: 100px !important;
}
.bootstrap-iso .mt-150 {
	margin-top: 150px;
}
.bootstrap-iso .mb-0 {
	margin-bottom: 0 !important;
}
.bootstrap-iso .mb-15 {
	margin-bottom: 15px;
}
.bootstrap-iso .mb-10 {
	margin-bottom: 10px !important;
}
.bootstrap-iso .mb-20 {
	margin-bottom: 20px !important;
}
.bootstrap-iso .mb-30 {
	margin-bottom: 30px !important;
}
.bootstrap-iso .mb-50 {
	margin-bottom: 50px !important;
}
.bootstrap-iso .mb-40 {
	margin-bottom: 40px !important;
}
.bootstrap-iso .mb-60 {
	margin-bottom: 60px !important;
}
.bootstrap-iso .mb-70 {
	margin-bottom: 70px !important;
}
.bootstrap-iso .mb-80 {
	margin-bottom: 80px !important;
}
.bootstrap-iso .mb-90 {
	margin-bottom: 90px !important;
}
.bootstrap-iso .mb-100 {
	margin-bottom: 100px !important;
}
.bootstrap-iso .mb-150 {
	margin-bottom: 150px !important;
}
.bootstrap-iso .mr-0 {
	margin-right: 0;
}
.bootstrap-iso .mr-30 {
	margin-right: 30px !important;
}
.bootstrap-iso .mr-50 {
	margin-right: 50px;
}
.bootstrap-iso .mr-60 {
	margin-right: 60px;
}
.bootstrap-iso .mr-150 {
	margin-right: 150px;
}
.bootstrap-iso .ml-0 {
	margin-left: 0;
}
.bootstrap-iso .ml-xs {
	margin-left: 10px;
}
.bootstrap-iso .ml-sm {
	margin-left: 20px;
}
.bootstrap-iso .ml-md {
	margin-left: 40px;
}
.bootstrap-iso .ml-lg {
	margin-left: 80px;
}
.bootstrap-iso .ml-30 {
	margin-left: 30px !important;
}
.bootstrap-iso .ml-50 {
	margin-left: 50px;
}
.bootstrap-iso .ml-60 {
	margin-left: 60px;
}
.bootstrap-iso .ml-150 {
	margin-left: 150px;
}
.bootstrap-iso .p-0 {
	padding: 0 !important;
}
.bootstrap-iso .p-xs {
	padding: 10px;
}
.bootstrap-iso .p-sm {
	padding: 20px;
}
.bootstrap-iso .p-md {
	padding: 40px;
}
.bootstrap-iso .p-lg {
	padding: 80px;
}
.bootstrap-iso .pt-0 {
	padding-top: 0 !important;
}
.bootstrap-iso .pt-20 {
	padding-top: 20px !important;
}
.bootstrap-iso .pt-30 {
	padding-top: 30px !important;
}
.bootstrap-iso .pt-40 {
	padding-top: 40px !important;
}
.bootstrap-iso .pt-50 {
	padding-top: 50px;
}
.bootstrap-iso .pt-60 {
	padding-top: 60px;
}
.bootstrap-iso .pt-70 {
	padding-top: 70px !important;
}
.bootstrap-iso .pt-80 {
	padding-top: 80px;
}
.bootstrap-iso .pt-90 {
	padding-top: 90px;
}
.bootstrap-iso .pt-100 {
	padding-top: 100px !important;
}
.bootstrap-iso .pt-150 {
	padding-top: 150px !important;
}
.bootstrap-iso .pb-0 {
	padding-bottom: 0 !important;
}
.bootstrap-iso .pb-30 {
	padding-bottom: 30px;
}
.bootstrap-iso .pb-50 {
	padding-bottom: 50px;
}
.bootstrap-iso .pb-60 {
	padding-bottom: 60px;
}
.bootstrap-iso .pb-70 {
	padding-bottom: 70px !important;
}
.bootstrap-iso .pb-80 {
	padding-bottom: 80px;
}
.bootstrap-iso .pb-90 {
	padding-bottom: 90px;
}
.bootstrap-iso .pb-100 {
	padding-bottom: 100px !important;
}
.bootstrap-iso .pr-0 {
	padding-right: 0;
}
.bootstrap-iso .pr-xs {
	padding-right: 10px;
}
.bootstrap-iso .pr-sm {
	padding-right: 20px;
}
.bootstrap-iso .pr-md {
	padding-right: 40px;
}
.bootstrap-iso .pr-lg {
	padding-right: 80px;
}
.bootstrap-iso .pr-15 {
	padding-right: 15px !important;
}
.bootstrap-iso .pr-30 {
	padding-right: 30px !important;
}
.bootstrap-iso .pr-50 {
	padding-right: 50px;
}
.bootstrap-iso .pr-60 {
	padding-right: 60px;
}
.bootstrap-iso .pr-100 {
	padding-right: 100px !important;
}
.bootstrap-iso .pr-150 {
	padding-right: 150px;
}
.bootstrap-iso .pl-0 {
	padding-left: 0 !important;
}
.bootstrap-iso .pl-30 {
	padding-left: 30px;
}
.bootstrap-iso .pl-50 {
	padding-left: 50px;
}
.bootstrap-iso .pl-60 {
	padding-left: 60px;
}
.bootstrap-iso .pl-100 {
	padding-left: 100px !important;
}
.bootstrap-iso .pl-150 {
	padding-left: 150px;
}
.bootstrap-iso .fixed {
	position: fixed;
}
.bootstrap-iso .relative {
	position: relative;
}
.bootstrap-iso .absolute {
	position: absolute;
}
.bootstrap-iso .static {
	position: static;
}
.bootstrap-iso .zindex-1 {
	z-index: 1;
}
.bootstrap-iso .zindex-2 {
	z-index: 2;
}
.bootstrap-iso .zindex-3 {
	z-index: 3;
}
.bootstrap-iso .border-all {
	border: 1px solid #e8505b;
}
.bootstrap-iso .border-top {
	border-top: 1px solid #e8505b;
}
.bootstrap-iso .border-bottom {
	border-bottom: 1px solid #e8505b;
}
.bootstrap-iso .border-right {
	border-right: 1px solid #e8505b;
}
.bootstrap-iso .border-left {
	border-left: 1px solid #e8505b;
}
.bootstrap-iso .inline {
	display: inline;
}
.bootstrap-iso .block {
	display: block;
}
.bootstrap-iso .inline-block {
	display: inline-block;
}
.bootstrap-iso .hide {
	display: none;
}
.bootstrap-iso .flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
@media only screen and (max-width: 767px) {
	.bootstrap-iso section {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	.bootstrap-iso .text-center-xs {
		text-align: center !important;
	}
	.bootstrap-iso .pull-none-xs {
		float: none !important;
		text-align: center !important;
	}
	.bootstrap-iso .mb-15-xs {
		margin-bottom: 15px;
	}
	.bootstrap-iso .mb-30-xs {
		margin-bottom: 30px !important;
	}
	.bootstrap-iso .mb-50-xs {
		margin-bottom: 50px;
	}
	.bootstrap-iso .mb-60-xs {
		margin-bottom: 60px !important;
	}
	.bootstrap-iso .p-none-xs {
		padding-right: 0;
		padding-left: 0;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.bootstrap-iso .text-center-sm {
		text-align: center !important;
	}
	.bootstrap-iso .mb-0-sm {
		margin-bottom: 0;
	}
	.bootstrap-iso .mb-15-sm {
		margin-bottom: 15px;
	}
	.bootstrap-iso .mb-30-sm {
		margin-bottom: 30px !important;
	}
	.bootstrap-iso .mb-50-sm {
		margin-bottom: 50px;
	}
	.bootstrap-iso .mb-60-sm {
		margin-bottom: 60px;
	}
	.bootstrap-iso .pb-15-sm {
		padding-bottom: 15px;
	}
	.bootstrap-iso .pb-30-sm {
		padding-bottom: 30px;
	}
	.bootstrap-iso .pb-50-sm {
		padding-bottom: 50px;
	}
	.bootstrap-iso .pb-60-sm {
		padding-bottom: 60px;
	}
	.bootstrap-iso .p-none-sm {
		padding-right: 0;
		padding-left: 0;
	}
	.bootstrap-iso .pull-none-sm {
		float: none !important;
		text-align: center !important;
	}
}
@media screen and (min-width: 992px) {
	.bootstrap-iso .p-lg-60 {
		padding-right: 60px;
		padding-left: 60px;
	}
}
.bootstrap-iso .row-no-padding [class*="col-"] {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.bootstrap-iso .col-lg-5ths,
.bootstrap-iso .col-md-5ths,
.bootstrap-iso .col-sm-5ths,
.bootstrap-iso .col-xs-5ths {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}
.bootstrap-iso .col-xs-5ths {
	width: 20%;
	float: left;
}
@media (min-width: 768px) {
	.bootstrap-iso .col-sm-5ths {
		width: 20%;
		float: left;
	}
}
@media (min-width: 992px) {
	.bootstrap-iso .col-md-5ths {
		width: 20%;
		float: left;
	}
}
@media (min-width: 1200px) {
	.bootstrap-iso .col-lg-5ths {
		width: 20%;
		float: left;
	}
}
.bootstrap-iso .col-content {
	padding: 120px 100px 90px 120px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
	.bootstrap-iso .col-content {
		padding: 40px !important;
	}
}
.bootstrap-iso .col-img {
	height: 600px !important;
	padding: 60px;
	overflow: hidden;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
	.bootstrap-iso .col-img {
		height: auto;
		min-height: 600px;
	}
}
.bootstrap-iso .pos-fixed {
	position: fixed;
}
.bootstrap-iso .pos-relative {
	position: relative;
}
.bootstrap-iso .pos-absolute {
	position: absolute;
}
.bootstrap-iso .pos-static {
	position: static;
}
.bootstrap-iso .pos-top {
	top: 0;
}
.bootstrap-iso .pos-bottom {
	bottom: 0;
}
.bootstrap-iso .pos-right {
	right: 0;
}
.bootstrap-iso .pos-left {
	left: 0;
}
.bootstrap-iso .pos-vertical-center {
	position: relative;
	top: 50%;
	-webkit-transform: perspective(1px) translateY(-50%);
	transform: perspective(1px) translateY(-50%);
}
.bootstrap-iso .height-700 {
	height: 700px !important;
}
.bootstrap-iso .height-500 {
	height: 500px !important;
}
.bootstrap-iso .height-800 {
	height: 800px !important;
}
@media only screen and (min-width: 992px) {
	.bootstrap-iso .hidden-lg,
	.bootstrap-iso .hidden-md {
		display: none;
	}
}
.bootstrap-iso .bg-theme {
	background-color: #e8505b !important;
}
.bootstrap-iso .bg-gray {
	background-color: #fef6f6 !important;
}
.bootstrap-iso .bg-white {
	background-color: #ffffff !important;
}
.bootstrap-iso .bg-dark {
	background-color: #212529 !important;
}
.bootstrap-iso .bg-gradient {
	background: bottom center #ffffff;
}
.bootstrap-iso .bg-pink {
	background-color: #fef6f6;
}
.bootstrap-iso .bg-section {
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	width: 100%;
	height: 100%;
}
.bootstrap-iso .bg-overlay:before {
	content: "";
	display: inline-block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
}
.bootstrap-iso .bg-overlay-light:before {
	background-color: #ffffff;
	opacity: 0.8;
}
.bootstrap-iso .bg-overlay-dark:before {
	background-color: #222222;
	opacity: 0.9;
}
.bootstrap-iso .bg-overlay-theme:before {
	background-color: #e8505b;
	opacity: 0.7;
}
.bootstrap-iso .bg-parallex {
	background-attachment: fixed;
}
.bootstrap-iso .bg-ytvideo.bg-overlay:before {
	z-index: 1;
	opacity: 0.7;
}
.bootstrap-iso .bg-purple {
	color: #ffffff;
	background-color: #cc66cc !important;
}
.bootstrap-iso .bg-sun-flower {
	color: #ffffff;
	background-color: #f1c40f !important;
}
.bootstrap-iso .bg-orange {
	color: #ffffff;
	background-color: #f39c12 !important;
}
.bootstrap-iso .bg-carrot {
	color: #ffffff;
	background-color: #e67e22 !important;
}
.bootstrap-iso .bg-pumpkin {
	color: #ffffff;
	background-color: #d35400 !important;
}
.bootstrap-iso .bg-alizarin {
	color: #ffffff;
	background-color: #e74c3c !important;
}
.bootstrap-iso .bg-pomegranate {
	color: #ffffff;
	background-color: #c0392b !important;
}
.bootstrap-iso .bg-turquoise {
	color: #ffffff;
	background-color: #1abc9c !important;
}
.bootstrap-iso .bg-green-sea {
	color: #ffffff;
	background-color: #16a085 !important;
}
.bootstrap-iso .bg-emerald {
	color: #ffffff;
	background-color: #2ecc71 !important;
}
.bootstrap-iso .bg-nephritis {
	color: #ffffff;
	background-color: #27ae60 !important;
}
.bootstrap-iso .bg-peter-river {
	color: #ffffff;
	background-color: #3498db !important;
}
.bootstrap-iso .bg-belize-hole {
	color: #ffffff;
	background-color: #2980b9 !important;
}
.bootstrap-iso .bg-amethyst {
	color: #ffffff;
	background-color: #9b59b6 !important;
}
.bootstrap-iso .bg-wisteria {
	color: #ffffff;
	background-color: #8e44ad !important;
}
.bootstrap-iso .bg-wet-asphalt {
	color: #ffffff;
	background-color: #34495e !important;
}
.bootstrap-iso .bg-wet-asphalt {
	color: #ffffff;
	background-color: #34495e !important;
}
.bootstrap-iso .bg-midnight-blue {
	color: #ffffff;
	background-color: #2c3e50 !important;
}
.bootstrap-iso .bg-silver {
	color: #ffffff;
	background-color: #bdc3c7 !important;
}
.bootstrap-iso .bg-concrete {
	color: #ffffff;
	background-color: #859596 !important;
}
.bootstrap-iso .bg-asbestos {
	color: #ffffff;
	background-color: #7f8c8d !important;
}
.bootstrap-iso .bg-asbestos {
	color: #ffffff;
	background-color: #7f8c8d !important;
}
.bootstrap-iso .bg-graphite {
	color: #ffffff;
	background-color: #454545 !important;
}
.bootstrap-iso .bg-gray-0 {
	background-color: #eeeeee !important;
	color: #454545;
}
.bootstrap-iso .bg-gray-1 {
	background-color: #ececec !important;
	color: #454545;
}
.bootstrap-iso .bg-gray-2 {
	background-color: #bdc3c7 !important;
	color: #454545;
}
.bootstrap-iso .bg-gray-3 {
	background-color: #dadfe1 !important;
	color: #454545;
}
.bootstrap-iso .bg-gray-5 {
	background-color: #ecf0f1 !important;
	color: #454545;
}
.bootstrap-iso .bg-gray-4 {
	background-color: #f2f1ef !important;
	color: #454545;
}
.bootstrap-iso .bg-gray-6 {
	background-color: #d2d7d3 !important;
	color: #454545;
}
.bootstrap-iso .bg-gray-7 {
	background-color: #e6e6e6 !important;
	color: #454545;
}
.bootstrap-iso .bg-orange-1 {
	background-color: #f9690e !important;
	color: #ffffff;
}
.bootstrap-iso .bg-orange-2 {
	background-color: #d35400 !important;
	color: #ffffff;
}
.bootstrap-iso .bg-orange-3 {
	background-color: #f89406 !important;
	color: #ffffff;
}
.bootstrap-iso .divider-1 {
	height: 1px;
	background-color: #fce5e6;
}
.bootstrap-iso .divider-2 {
	height: 1px;
	width: 100%;
	background-color: #fdedee;
}
.bootstrap-iso .btn {
	font-family: "Roboto", sans-serif;
	display: inline-block;
	position: relative;
	z-index: 2;
	font-size: 16px;
	font-weight: 400;
	text-transform: capitalize;
	text-align: center;
	border-radius: 0;
	padding: 0;
	width: 221px;
	height: 51px;
	line-height: 51px;
	-webkit-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	border-radius: 50px;
	overflow: hidden;
}
.bootstrap-iso .btn.active,
.bootstrap-iso .btn:active,
.bootstrap-iso .btn:focus,
.bootstrap-iso .btn:active:focus,
.bootstrap-iso .btn.active:focus,
.bootstrap-iso .btn.focus,
.bootstrap-iso .btn.focus:active,
.bootstrap-iso .btn.active.focus {
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.bootstrap-iso .btn-video {
	width: 80px;
	height: 80px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #ffffff;
	border-radius: 50%;
}
.bootstrap-iso .btn-video i {
	font-size: 16px;
	color: #e8505b;
}
.bootstrap-iso a.back {
	position: absolute;
	top: 54px;
	left: 70px;
	font-size: 14px;
	font-weight: 500;
	line-height: 2.14;
	text-transform: capitalize;
	color: #ffffff;
}
.bootstrap-iso a.back.dark {
	color: #212529;
}
.bootstrap-iso a.back:hover span {
	-webkit-transform: rotate(-180deg) translateX(10px);
	-ms-transform: rotate(-180deg) translateX(10px);
	transform: rotate(-180deg) translateX(10px);
}
.bootstrap-iso a.back span {
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	display: inline-block;
	-webkit-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	transform: rotate(-180deg);
	margin-right: 15px;
}
.bootstrap-iso .btn--primary {
	background-color: #e8505b;
	color: #ffffff;
}
.bootstrap-iso .btn--primary:active,
.bootstrap-iso .btn--primary:focus,
.bootstrap-iso .btn--primary:hover {
	color: #ffffff;
}
.bootstrap-iso .btn--primary.btn-inversed {
	background-color: #ffffff;
	color: #e8505b;
}
.bootstrap-iso .btn--primary.btn-inversed:active,
.bootstrap-iso .btn--primary.btn-inversed:focus,
.bootstrap-iso .btn--primary.btn-inversed:hover {
	color: #e8505b;
}
.bootstrap-iso .bg-dark .btn--primary:active,
.bootstrap-iso .bg-dark .btn--primary:focus,
.bootstrap-iso .bg-dark .btn--primary:hover {
	background-color: #37b05d;
	color: #ffffff;
}
.bootstrap-iso .btn--secondary {
	background-color: #212529;
	color: #ffffff;
}
.bootstrap-iso .btn--secondary:active,
.bootstrap-iso .btn--secondary:focus,
.bootstrap-iso .btn--secondary:hover {
	background-color: #212529;
	color: #ffffff;
}
.bootstrap-iso .btn--white {
	background-color: #ffffff;
	color: #212529;
	border: 1px solid #ffffff;
	border-radius: 4px;
}
.bootstrap-iso .btn--white:active,
.bootstrap-iso .btn--white:focus,
.bootstrap-iso .btn--white:hover {
	background-color: #e8505b;
	color: #ffffff;
	border-color: #e8505b;
}
.bootstrap-iso .btn--block {
	width: 100%;
}
.bootstrap-iso .btn--auto {
	width: auto;
}
.bootstrap-iso .btn--rounded {
	border-radius: 50px;
}
.bootstrap-iso .btn--bordered {
	background-color: transparent;
}
.bootstrap-iso .btn--bordered.btn--primary {
	color: #e8505b;
	border-color: #e8505b;
}
.bootstrap-iso .btn--bordered.btn--primary:active,
.bootstrap-iso .btn--bordered.btn--primary:focus,
.bootstrap-iso .btn--bordered.btn--primary:hover {
	color: #ffffff;
	background-color: #e8505b;
	border-color: #e8505b;
}
.bootstrap-iso .btn--bordered.btn--secondary {
	color: #212529;
	border-color: #212529;
}
.bootstrap-iso .btn--bordered.btn--secondary:active,
.bootstrap-iso .btn--bordered.btn--secondary:focus,
.bootstrap-iso .btn--bordered.btn--secondary:hover {
	color: #ffffff;
	background-color: #212529;
	border-color: #212529;
}
.bootstrap-iso .btn--bordered.btn--white {
	color: #ffffff;
	border-color: #ffffff;
}
.bootstrap-iso .btn--bordered.btn--white:active,
.bootstrap-iso .btn--bordered.btn--white:focus,
.bootstrap-iso .btn--bordered.btn--white:hover {
	color: #e8505b;
	background-color: #ffffff;
	border-color: #ffffff;
}
.bootstrap-iso .btn--white.btn--secondary {
	color: #212529;
	border-color: #ffffff;
	background-color: #ffffff;
}
.bootstrap-iso .btn--white.btn--secondary:active,
.bootstrap-iso .btn--white.btn--secondary:focus,
.bootstrap-iso .btn--white.btn--secondary:hover {
	color: #ffffff;
	background-color: #e8505b;
	border-color: #e8505b;
}
.bootstrap-iso .btn--arrows {
	position: relative;
}
.bootstrap-iso .btn--arrows:before {
	position: absolute;
	top: -100%;
	left: 0;
	width: 100%;
	height: 100%;
	font-size: 26px;
	line-height: 51px;
	font-family: "icomoon";
	content: "\e901";
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.bootstrap-iso .btn--arrows span {
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	display: inline-block;
	height: 100%;
	width: 100%;
}
.bootstrap-iso .btn--arrows span i {
	margin-left: 7px;
}
.bootstrap-iso .btn--arrows:hover span {
	-webkit-transform: translateY(300%);
	-ms-transform: translateY(300%);
	transform: translateY(300%);
}
.bootstrap-iso .btn--arrows:hover:before {
	top: 0;
}
.bootstrap-iso .btn--combo {
	width: 170px !important;
}
.bootstrap-iso .form-control {
	color: #000000;
	font-family: "Inter";
	font-size: 14px;
	font-weight: 400;
	line-height: 57px;
	height: 57px;
	text-transform: none;
	border: 0;
	border-bottom: 1px solid #dee2e6;
	background: transparent;
	padding: 0;
	margin-bottom: 20px;
	position: relative;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.darkTheme .form-control {
	border: 0.5px solid #263559;
	color: #ffffff;
}
.bootstrap-iso .form-control:focus {
	background-color: #F6F6F8;
	border: 0.5px solid #D9D9D9;
	outline: 0;
}
.darkTheme .form-control:focus {
	background-color: #151D38;
	border: 0.5px solid #263559;
	outline: 0;
}
.bootstrap-iso .form-control::-webkit-input-placeholder {
	color: #868e96;
}
.bootstrap-iso .form-control:-moz-placeholder {
	color: #868e96;
}
.bootstrap-iso .form-control::-moz-placeholder {
	color: #868e96;
}
.bootstrap-iso .form-control:-ms-input-placeholder {
	color: #868e96;
}
.bootstrap-iso .input-group {
	border-radius: 0;
	border: 1px solid grey;
	background-color: transparent;
	overflow: hidden;
	padding: 3px;
}
.bootstrap-iso .input-group .btn {
	width: 160px;
}
.bootstrap-iso .input-group .input-group-btn {
	border-radius: 50px;
	overflow: hidden;
}
.bootstrap-iso .subscribe-alert {
	margin-top: 1.5rem;
	font-weight: 400;
	max-width: 500px;
	margin-right: auto;
	margin-left: auto;
}
.bootstrap-iso .subscribe-alert .alert {
	font-size: 18px;
	font-weight: 400;
	line-height: 22px;
	border-radius: 50px;
}
.bootstrap-iso .form-inline .form-control {
	background-color: #ffffff;
	font-size: 16px;
	font-weight: 400;
	width: 221px;
	height: 51px;
	border-radius: 50px;
	margin-right: 30px;
	padding-left: 20px;
	border-bottom: none;
	margin-bottom: 0;
}
.bootstrap-iso .heading {
	margin-bottom: 38px;
}
@media screen and (max-width: 767px) {
	.bootstrap-iso .heading {
		text-align: center;
	}
}
.bootstrap-iso .heading .heading-title {
	font-family: "Roboto", sans-serif;
	color: #212529;
	font-size: 30px;
	font-weight: 500;
	line-height: 1.5;
	margin-bottom: 10px;
	text-transform: capitalize;
}
.bootstrap-iso .heading .heading-title span {
	color: #e8505b;
}
.bootstrap-iso .heading .heading-desc {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #868e96;
	margin-bottom: 0;
	padding: 0;
}
.bootstrap-iso .heading.heading-light .heading-title {
	color: #ffffff;
}
.bootstrap-iso .heading.heading-light .heading-desc {
	color: #ffffff;
}
.bootstrap-iso .heading-1 {
	margin-bottom: 78px;
}
.bootstrap-iso .heading-2 {
	margin-bottom: 35px;
}
.bootstrap-iso .heading-3 {
	margin-bottom: 48px;
}
.bootstrap-iso .heading-3 .heading-title {
	font-size: 45px;
}
.bootstrap-iso .heading-3 .heading-desc {
	font-size: 18px;
	line-height: 1.5;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
	.bootstrap-iso .heading .heading-title {
		font-size: 24px;
	}
	.bootstrap-iso .heading .heading-title br {
		display: none;
	}
}
.bootstrap-iso .preloader {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	position: fixed;
	z-index: 99999;
	background-color: #ffffff;
	overflow: hidden;
}
@media screen and (max-width: 767px) {
	.bootstrap-iso .modal-dialog {
		max-width: 310px;
		border-radius: 4px;
		margin-right: auto;
		margin-left: auto;
	}
}
@media screen and (min-width: 768px) {
	.bootstrap-iso .modal-dialog {
		max-width: 470px;
		border-radius: 4px;
	}
}
.bootstrap-iso .popup-contact-form {
	background-color: #ffffff;
	text-align: center;
	padding: 40px;
}
@media screen and (max-width: 767px) {
	.bootstrap-iso .popup-contact-form {
		padding: 10px;
	}
}
.bootstrap-iso .popup-contact-form .heading .heading-title {
	font-size: 30px;
	margin-bottom: 4px;
}
@media screen and (max-width: 767px) {
	.bootstrap-iso .popup-contact-form .heading .heading-title {
		font-size: 26px;
	}
}
.bootstrap-iso .popup-contact-form .heading .heading-desc {
	line-height: 1.31;
}
.bootstrap-iso .popup-contact-form p.notice {
	font-size: 12px;
	line-height: 1.5;
}
@media screen and (min-width: 768px) {
	.bootstrap-iso .popup-contact-form p.notice {
		padding: 0 45px;
	}
}
.bootstrap-iso .connect {
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	color: #868e96;
	line-height: 1.5;
}
.bootstrap-iso .connect .connect-link {
	display: inline-block;
}
.bootstrap-iso .connect .connect-link a {
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	color: #212529;
	text-transform: capitalize;
	position: relative;
}
.bootstrap-iso .connect .connect-link a span {
	display: inline-block;
	position: relative;
}
.bootstrap-iso .connect .connect-link a span:before {
	content: "";
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 100%;
	height: 2px;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	background-color: #e9ecef;
}
.bootstrap-iso .connect .connect-link a i {
	margin-left: 8px;
}
.bootstrap-iso .connect .connect-link a:hover {
	color: #e8505b;
}
.bootstrap-iso .connect .connect-link a:hover span:before {
	background-color: #e8505b;
}
.bootstrap-iso .connect.connect-light {
	color: #ffffff;
}
.bootstrap-iso .connect.connect-light .connect-link a {
	color: #212529;
}
.bootstrap-iso .connect.connect-light .connect-link a span:before {
	background-color: #212529;
}
.bootstrap-iso .connect.connect-light .connect-link a:hover {
	color: #ffffff;
}
.bootstrap-iso .connect.connect-light .connect-link a:hover span:before {
	background-color: #ffffff;
}
.bootstrap-iso .header.header-transparent {
	background-color: transparent;
	position: absolute;
	top: 0;
	/* left: 0; */
	width: 100%;
	z-index: 1001;
}
@media screen and (min-width: 992px) {
	.bootstrap-iso .header-2 .navbar-expand-lg > .container,
	.bootstrap-iso .header-2 .navbar-expand-lg > .container-fluid {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 0 70px;
	}
}
.bootstrap-iso .header .navbar {
	height: 75px;
	margin-bottom: 0;
	border-radius: 0;
	min-height: 70px;
	border: none;
	padding: 0;
	background-color: #fff;
}
.bootstrap-iso .header .navbar .navbar-brand {
	margin-right: 0;
}
.bootstrap-iso .header .navbar .navbar-brand .logo-dark {
	display: none;
}
.bootstrap-iso .header .navbar .navbar-brand .logo-light {
	display: inline-block;
}
.bootstrap-iso .header .navbar .navbar-nav > li {
	margin-right: 30px;
	position: relative;
}
.bootstrap-iso .header .navbar .navbar-nav > li:last-child {
	margin-right: 0;
}
.bootstrap-iso .header .navbar .navbar-nav > li > a {
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 400;
	line-height: 80px;
	color: #212529;
	padding: 0;
	position: relative;
	display: block;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}
.bootstrap-iso .header .navbar .navbar-nav > li.active > a,
.bootstrap-iso .header .navbar .navbar-nav > li > a:focus,
.bootstrap-iso .header .navbar .navbar-nav > li > a:hover {
	color: #e8505b;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .header .navbar .navbar-nav > li {
		margin-right: 0;
	}
	.bootstrap-iso .header .navbar .navbar-nav > li a {
		margin-right: 0;
		padding: 15px 10px;
		color: #212529;
		line-height: 1;
	}
	.bootstrap-iso .header .navbar .navbar-nav > li.active {
		background-color: #e8505b;
	}
	.bootstrap-iso .header .navbar .navbar-nav > li.active a {
		color: #ffffff;
	}
}
.bootstrap-iso .header .navbar.navbar-fixed {
	top: 0;
	background: #ffffff;
	-webkit-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.1);
	box-shadow: 0 2px 4px rgba(3, 27, 78, 0.1);
	-webkit-animation: navbarFixed 0.8s;
	animation: navbarFixed 0.8s;
}
.bootstrap-iso .header .navbar.navbar-fixed .navbar-brand .logo-dark {
	display: inline-block;
}
.bootstrap-iso .header .navbar.navbar-fixed .navbar-brand .logo-light {
	display: none;
}
.bootstrap-iso .header .navbar.navbar-fixed .navbar-nav > li a {
	color: #212529;
	line-height: 1;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .header .navbar.navbar-fixed .navbar-nav > li a:hover {
		background-color: #e8505b;
		color: #ffffff;
	}
}
.bootstrap-iso .header .navbar.navbar-fixed .navbar-nav > li.active a {
	color: #e8505b;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .header .navbar.navbar-fixed .navbar-nav > li.active {
		background-color: #e8505b;
	}
	.bootstrap-iso .header .navbar.navbar-fixed .navbar-nav > li.active a {
		color: #ffffff;
	}
}
@-webkit-keyframes navbarFixed {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}
@keyframes navbarFixed {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}
@media only screen and (max-width: 991px) {
	.bootstrap-iso .navbar .navbar-brand {
		margin-left: 15px;
		/* height: 100px; */
		/* line-height: 100px; */
	}
	.bootstrap-iso .navbar .navbar-toggler {
		width: 30px;
		height: 30px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-transition: 0.3s ease-in-out;
		-o-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
		cursor: pointer;
		outline: none;
		margin-right: 30px;
		padding: 0;
	}
}
@media only screen and (max-width: 991px) and (min-width: 992px) {
	.bootstrap-iso .navbar .navbar-toggler {
		display: none;
	}
}
@media only screen and (max-width: 991px) {
	.bootstrap-iso
		.navbar
		.navbar-toggler:not(.collapsed)
		.navbar-toggler-icon {
		background-color: transparent;
	}
	.bootstrap-iso
		.navbar
		.navbar-toggler:not(.collapsed)
		.navbar-toggler-icon:before,
	.bootstrap-iso
		.navbar
		.navbar-toggler:not(.collapsed)
		.navbar-toggler-icon:after {
		top: 0;
	}
	.bootstrap-iso
		.navbar
		.navbar-toggler:not(.collapsed)
		.navbar-toggler-icon::before {
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	.bootstrap-iso
		.navbar
		.navbar-toggler:not(.collapsed)
		.navbar-toggler-icon::after {
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	.bootstrap-iso .navbar .navbar-toggler .navbar-toggler-icon {
		width: 100%;
		height: 2px;
		background-color: #e8505b;
		position: relative;
		-webkit-transition: 0.3s ease-in-out;
		-o-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
	}
	.bootstrap-iso .navbar .navbar-toggler .navbar-toggler-icon:before,
	.bootstrap-iso .navbar .navbar-toggler .navbar-toggler-icon:after {
		content: "";
		position: absolute;
		left: 0;
		height: 2px;
		width: 100%;
		background-color: #e8505b;
		-webkit-transition: 0.3s ease-in-out;
		-o-transition: 0.3s ease-in-out;
		transition: 0.3s ease-in-out;
	}
	.bootstrap-iso .navbar .navbar-toggler .navbar-toggler-icon:before {
		top: -10px;
	}
	.bootstrap-iso .navbar .navbar-toggler .navbar-toggler-icon:after {
		top: 10px;
	}
	.bootstrap-iso .navbar .navbar-collapse {
		background-color: #ffffff;
		padding: 15px;
	}
	.bootstrap-iso
		.navbar.navbar-fixed
		.navbar-toggler:not(.collapsed)
		.navbar-toggler-icon {
		background-color: transparent;
	}
	.bootstrap-iso .navbar.navbar-fixed .navbar-toggler .navbar-toggler-icon {
		background-color: #e8505b;
	}
	.bootstrap-iso
		.navbar.navbar-fixed
		.navbar-toggler
		.navbar-toggler-icon:before,
	.bootstrap-iso
		.navbar.navbar-fixed
		.navbar-toggler
		.navbar-toggler-icon:after {
		background-color: #e8505b;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.bootstrap-iso .navbar-nav > li {
		margin-right: 16px;
	}
	.bootstrap-iso .module .module-icon,
	.bootstrap-iso .module-consultation .btn {
		margin-right: 0;
	}
}
@media only screen and (min-width: 992px) {
	.bootstrap-iso .navbar-collapse {
		padding-right: 0;
		padding-left: 0;
	}
}
.bootstrap-iso .header .module-container {
	padding-right: 20px;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .header .module-container {
		padding-left: 0;
	}
}
.bootstrap-iso .header .module-container .module-cta .btn {
	width: 182px;
	height: 50px;
	padding: 0 17px;
	display: block;
	cursor: pointer;
	font-family: "Roboto", sans-serif;
	color: #ffffff;
	font-size: 16px;
	font-weight: 400;
	text-transform: capitalize;
	background-color: #e8505b;
	border: 0;
	border-radius: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	z-index: inherit;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	outline: none;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .header .module-container .module-cta .btn {
		width: 170px;
		height: 40px;
		line-height: 40px;
		border: 1px solid #e8505b;
		font-size: 13px;
	}
	.bootstrap-iso .header .module-container .module-cta .btn:before {
		line-height: 40px;
	}
}
.bootstrap-iso .header .navbar-fixed .module-container .module-cta .btn {
	background-color: #e8505b;
	color: #ffffff;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .header-2 .module-container .module-cta {
		margin-right: 15px;
	}
	.bootstrap-iso .header-2 .module-container .module-cta .btn {
		width: 120px;
	}
}
.bootstrap-iso .clients {
	padding-top: 0;
	padding-bottom: 0;
	background-size: cover;
}
.bootstrap-iso .clients .client {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 239px;
}
.bootstrap-iso .clients .client:hover img {
	opacity: 1;
}
.bootstrap-iso .clients .client img {
	cursor: pointer;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	opacity: 0.5;
	width: auto;
	max-width: 100%;
}
.bootstrap-iso .hero {
	padding-top: 140px;
	padding-bottom: 280px;
	background-color: #ffffff;
	overflow: visible;
	background-size: cover;
	background-position: center bottom;
	position: relative;
}
@media screen and (max-width: 767px) {
	.bootstrap-iso .hero {
		padding-top: 200px;
	}
}
@media screen and (min-width: 768px) and (max-width: 991px) {
	.bootstrap-iso .hero {
		padding-top: 190px;
	}
}
.bootstrap-iso .hero .hero-cotainer .hero-content {
	padding-top: 130px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .hero .hero-cotainer .hero-content {
		margin-bottom: 90px;
		padding-top: 20px;
	}
}
.bootstrap-iso .hero .hero-cotainer .hero-content .hero-headline {
	color: #212529;
	font-family: "Roboto", sans-serif;
	font-size: 50px;
	font-weight: 500;
	line-height: 1.15;
	margin-bottom: 20px;
	text-transform: capitalize;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .hero .hero-cotainer .hero-content .hero-headline {
		font-size: 45px;
	}
}
.bootstrap-iso .hero .hero-cotainer .hero-content .hero-bio {
	color: #868e96;
	font-family: "Roboto", sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 27px;
	margin-bottom: 42px;
}
.bootstrap-iso .hero .mockup {
	text-align: center;
}
.bootstrap-iso .hero .mockup img {
	max-width: 1000px;
	width: 100%;
	display: inline-block;
	margin: 0 auto;
}
.bootstrap-iso .hero.hero-lead {
	padding-top: 333px;
	padding-bottom: 233px;
	background-position: center bottom;
}
@media screen and (max-width: 767px) {
	.bootstrap-iso .hero.hero-lead {
		padding-top: 133px;
		padding-bottom: 133px;
	}
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .hero.hero-lead {
		text-align: center;
	}
	.bootstrap-iso .hero.hero-lead .popup-contact-form {
		max-width: 500px;
		margin-right: auto;
		margin-left: auto;
		padding: 20px;
	}
}
.bootstrap-iso .hero.hero-lead .hero-cotainer .hero-content {
	padding-top: 100px;
}
.bootstrap-iso .hero.hero-lead .hero-cotainer .hero-content .hero-headline {
	font-size: 55px;
}
@media screen and (max-width: 767px) {
	.bootstrap-iso .hero.hero-lead .hero-cotainer .hero-content .hero-headline {
		font-size: 35px;
	}
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .hero-2 {
		height: auto;
	}
}
.bootstrap-iso .hero-2 .hero-cotainer .hero-content {
	height: 100%;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .hero-2 .hero-cotainer .hero-content {
		margin-bottom: 80px;
	}
}
.bootstrap-iso .hero-2 .hero-cotainer .popup-contact-form {
	-webkit-box-shadow: 0 3px 6px 0 rgba(232, 80, 91, 0.16);
	box-shadow: 0 3px 6px 0 rgba(232, 80, 91, 0.16);
}
.bootstrap-iso .video {
	height: 390px;
	background-size: cover;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 4px;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .video {
		margin-bottom: 50px;
	}
}
@media screen and (min-width: 992px) {
	.bootstrap-iso .services .video {
		width: calc(100% - 40px);
	}
}
.bootstrap-iso .video-1 {
	margin-bottom: 50px;
}
.bootstrap-iso .services {
	padding-top: 120px;
	padding-bottom: 120px;
	position: relative;
}
.bootstrap-iso .services .service-card {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-flow: column;
	flex-flow: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 100%;
}
@media screen and (min-width: 992px) {
	.bootstrap-iso .services .service-card .heading .heading-desc {
		padding-right: 70px;
	}
}
.bootstrap-iso .services .service-card .counters-card {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
@media screen and (max-width: 450px) {
	.bootstrap-iso .services .service-card .counters-card {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
.bootstrap-iso .services .service-card .service-list li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 16px;
	line-height: 1.5;
	color: #868e96;
	font-weight: 400;
	margin-bottom: 8px;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .services .service-card .service-list li {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
.bootstrap-iso .services .service-card .service-list li:last-child {
	margin-bottom: 0;
}
.bootstrap-iso .services .service-card .service-list li span {
	margin-top: 4px;
	color: #e8505b;
	margin-right: 15px;
}
.bootstrap-iso .services .service-img {
	margin-top: 30px;
}
@media screen and (min-width: 768px) {
	.bootstrap-iso .services .service-img {
		margin-top: 0;
	}
}
.bootstrap-iso .thank-you {
	padding: 150px 0;
}
.bootstrap-iso .thank-you .slogan {
	width: 85px;
	height: 85px;
	margin-bottom: 50px;
}
.bootstrap-iso .thank-you .slogan img {
	width: 100%;
	height: 100%;
}
.bootstrap-iso .testimonials {
	padding-top: 120px;
	padding-bottom: 120px;
}
.bootstrap-iso .testimonials .testimonial-panel {
	max-width: 1000px;
	margin: 0 auto;
	padding: 0 40px;
	text-align: center;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
	position: relative;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .testimonials .testimonial-panel {
		padding: 0 20px;
	}
}
.bootstrap-iso .testimonials .testimonial-panel:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background: url("../images/background/bg-map.svg") center center no-repeat;
	background-size: contain;
}
.bootstrap-iso .testimonials .testimonial-panel .testimonial-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.bootstrap-iso
	.testimonials
	.testimonial-panel
	.testimonial-body
	.testimonial-img {
	width: 90px;
	height: 90px;
	background-color: #ffffff;
	border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 auto 38px;
	position: relative;
}
.bootstrap-iso
	.testimonials
	.testimonial-panel
	.testimonial-body
	.testimonial-img
	img {
	width: auto;
	max-width: 100%;
	display: inline-block;
}
.bootstrap-iso
	.testimonials
	.testimonial-panel
	.testimonial-body
	.testimonial-img
	.testimonial-icon {
	position: absolute;
	top: 0;
	right: 0;
}
.bootstrap-iso
	.testimonials
	.testimonial-panel
	.testimonial-body
	.testimonial-img
	.testimonial-icon
	span {
	font-size: 12px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	width: 30px;
	height: 30px;
	line-height: 59px;
	border-radius: 50px;
	color: #ffffff;
	background-color: #e8505b;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.bootstrap-iso .testimonials .testimonial-panel .testimonial-body p {
	color: #212529;
	font-size: 30px;
	line-height: 1.5;
	margin-bottom: 17px;
	font-weight: 500;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .testimonials .testimonial-panel .testimonial-body p {
		font-size: 20px;
	}
}
.bootstrap-iso
	.testimonials
	.testimonial-panel
	.testimonial-body
	.testimonial-author
	p {
	font-size: 16px;
	line-height: 1.5;
	font-weight: 400;
	color: #868e96;
}
.bootstrap-iso
	.testimonials
	.testimonial-panel
	.testimonial-body
	.testimonial-author
	p::before {
	content: "-";
	font-size: 12px;
	margin-right: 5px;
}
.bootstrap-iso .features {
	padding-top: 100px;
	padding-bottom: 95px;
	background-color: #ffffff;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .features {
		padding-top: 10px;
		padding-bottom: 50px;
	}
}
.bootstrap-iso .features .feature-panel {
	position: relative;
	margin-bottom: 25px;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .features .feature-panel {
		max-width: 370px;
		margin: 0 auto 50px auto;
	}
}
@media screen and (min-width: 992px) {
	.bootstrap-iso .features .feature-panel {
		margin-right: 20px;
	}
}
.bootstrap-iso .features .feature-panel .feature-icon {
	color: #e8505b;
	line-height: 1;
	margin-right: 36px;
	position: relative;
}
.bootstrap-iso .features .feature-panel .feature-icon::before {
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 70px;
	height: 59px;
	background-image: url("../images/background/bg-icon.svg");
}
.bootstrap-iso .features .feature-panel .feature-icon i {
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
	display: inline-block;
}
.bootstrap-iso .features .feature-panel .feature-icon i::before {
	font-size: 50px;
	margin: 0;
}
.bootstrap-iso .features .feature-panel .feature-icon:hover i {
	-webkit-animation: pulse 0.9s infinite;
	animation: pulse 0.9s infinite;
}
.bootstrap-iso .features .feature-panel .feature-content h3 {
	font-family: "Roboto", sans-serif;
	color: #212529;
	font-size: 20px;
	font-weight: 500;
	line-height: 1.5;
	margin-bottom: 14px;
	text-transform: capitalize;
}
.bootstrap-iso .features .feature-panel .feature-content p {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 0;
	color: #868e96;
}
.bootstrap-iso .feature-left .feature-panel {
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	margin-bottom: 18px;
}
.bootstrap-iso .feature-left .feature-panel:hover {
	-webkit-transform: translateY(-4px);
	-ms-transform: translateY(-4px);
	transform: translateY(-4px);
}
.bootstrap-iso .feature-left .feature-panel .feature-icon {
	position: absolute;
	left: 0;
	top: 0;
	margin-bottom: 0;
}
.bootstrap-iso .feature-left .feature-panel .feature-content {
	padding-left: 68px;
}
.bootstrap-iso .feature-left-circle .feature-content h3 {
	font-size: 22px;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	line-height: 29px;
	margin-bottom: 15px;
}
.bootstrap-iso .feature-left-circle .feature-content p {
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
	padding: 0;
}
.bootstrap-iso .feature-left-circle .feature-icon {
	width: 45px;
	height: 45px;
	line-height: 42px;
	border-radius: 50%;
	color: #e8505b;
	font-family: "Roboto", sans-serif;
	font-size: 24px;
	border: 2px solid #e8505b;
	-webkit-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}
.bootstrap-iso .feature-left-circle:hover .feature-icon {
	background: #e8505b;
	border-color: #e8505b;
	color: #ffffff;
}
.bootstrap-iso .feature-divider {
	margin-top: 0;
	margin-bottom: 0;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
	.bootstrap-iso .feature-panel {
		margin-bottom: 20px;
	}
	.bootstrap-iso .feature-panel .feature-icon {
		margin-bottom: 10px;
	}
	.bootstrap-iso .feature img {
		max-width: 100%;
		height: auto;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.bootstrap-iso .feature-panel {
		margin-bottom: 20px;
	}
}
.bootstrap-iso .cta {
	padding-top: 355px;
	padding-bottom: 360px;
	position: relative;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .cta {
		padding-top: 220px;
		padding-bottom: 220px;
	}
}
.bootstrap-iso .cta h3 {
	color: #fffefe;
	font-size: 45px;
	font-weight: 500;
	line-height: 60px;
	margin-bottom: 15px;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .cta h3 {
		font-size: 28px;
		line-height: 35px;
	}
}
.bootstrap-iso .cta p {
	color: #ffffff;
	font-size: 18px;
	font-weight: 400;
	line-height: 1.5;
	margin-bottom: 38px;
}
.bootstrap-iso .pricing {
	padding-top: 110px;
	padding-bottom: 110px;
	position: relative;
}
.bootstrap-iso .pricing .pricing-panel {
	background-color: #ffffff;
	-webkit-box-shadow: 0px 30px 50px 0 rgba(232, 80, 91, 0.1);
	box-shadow: 0px 30px 50px 0 rgba(232, 80, 91, 0.1);
	margin-bottom: 35px;
	-webkit-transition: all 0.35s ease-in-out;
	-o-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
	position: relative;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .pricing .pricing-panel {
		max-width: 370px;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 70px;
	}
}
.bootstrap-iso .pricing .pricing-panel .pricing-img img {
	max-width: 100%;
}
.bootstrap-iso .pricing .pricing-panel .pricing-body {
	/* text-align: center; */
	padding: 10px 27px 0px;
}
.bootstrap-iso .pricing .pricing-panel .pricing-body .pricing-link {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #e8505b;
	position: absolute;
	bottom: 0;
	left: 50%;
	-webkit-transform: translate(-50%, 50%);
	-ms-transform: translate(-50%, 50%);
	transform: translate(-50%, 50%);
	color: #ffffff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}
.bootstrap-iso .pricing .pricing-panel .pricing-body .pricing-link:hover {
	font-size: 16px;
	width: 60px;
	height: 60px;
}
.bootstrap-iso .pricing .pricing-panel .pricing-body .pricing-heading {
	font-family: "Roboto", sans-serif;
	font-size: 20px;
	font-weight: 500;
	line-height: 1.5;
	color: #212529;
	text-transform: capitalize;
	margin-bottom: 10px;
}
.bootstrap-iso .pricing .pricing-panel .pricing-body .pricing-desc {
	font-size: 16px;
	line-height: 1.5;
	padding: 0;
}
.bootstrap-iso .footer .footer-widgets-container {
	padding-top: 0px;
	padding-bottom: 0px;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .footer .footer-widgets-container {
		padding-top: 30px;
	}
}
.bootstrap-iso .footer .footer-widgets-container .footer-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 20px;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .footer .footer-widgets-container .footer-widget {
		text-align: center;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
.bootstrap-iso .footer .footer-widgets-container .footer-widget .list-unstyled {
	margin-bottom: 0;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso
		.footer
		.footer-widgets-container
		.footer-widget
		.navbar-brand {
		margin-bottom: 40px;
	}
}
.bootstrap-iso .footer .footer-widgets-container .footer-widget .navigation {
	margin-bottom: 0;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso
		.footer
		.footer-widgets-container
		.footer-widget
		.navigation {
		text-align: center;
	}
}
.bootstrap-iso .footer .footer-widgets-container .footer-widget .navigation li {
	display: inline-block;
}
.bootstrap-iso
	.footer
	.footer-widgets-container
	.footer-widget
	.navigation
	li
	.nav-link {
	font-size: 16px;
	line-height: 1.19;
	color: #868e96;
	font-weight: 400;
	font-family: "Roboto", sans-serif;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	text-transform: capitalize;
}
.bootstrap-iso
	.footer
	.footer-widgets-container
	.footer-widget
	.navigation
	li
	.nav-link:hover {
	color: #e8505b;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso
		.footer
		.footer-widgets-container
		.footer-widget
		.footer-contact {
		margin-bottom: 50px;
	}
}
.bootstrap-iso
	.footer
	.footer-widgets-container
	.footer-widget
	.footer-contact
	li {
	margin-bottom: 10px;
}
.bootstrap-iso
	.footer
	.footer-widgets-container
	.footer-widget
	.footer-contact
	li:last-child {
	margin-bottom: 0;
}
.bootstrap-iso
	.footer
	.footer-widgets-container
	.footer-widget
	.footer-contact
	li
	a {
	font-size: 18px;
	line-height: 1.22;
	font-weight: 400;
	color: #868e96;
}
.bootstrap-iso
	.footer
	.footer-widgets-container
	.footer-widget
	.footer-social
	li {
	display: inline-block;
	margin-right: 15px;
}
.bootstrap-iso
	.footer
	.footer-widgets-container
	.footer-widget
	.footer-social
	li:last-child {
	margin-right: 0;
}
.bootstrap-iso
	.footer
	.footer-widgets-container
	.footer-widget
	.footer-social
	li
	a {
	font-size: 14px;
	color: #e8505b;
}
.bootstrap-iso
	.footer
	.footer-widgets-container
	.footer-widget
	.footer-social
	li
	a:hover {
	color: #212529;
}
.bootstrap-iso .footer .footer-copyright {
	padding-top: 30px;
	padding-bottom: 30px;
	color: #999999;
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	text-transform: capitalize;
	color: #868e96;
}
.bootstrap-iso .footer .footer-copyright a {
	color: #e8505b;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
	.bootstrap-iso .footer-copyright {
		font-size: 12px;
	}
}
.bootstrap-iso .carousel-dots .owl-dots {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-top: 20px;
}
.bootstrap-iso .carousel-dots .owl-dots .owl-dot {
	width: 10px;
	height: 10px;
	border: solid 1px #e8505b;
	background-color: #e8505b;
	border-radius: 50%;
	margin-right: 13px;
}
.bootstrap-iso .carousel-dots .owl-dots .owl-dot.active {
	background-color: #ffffff;
	border: 2px solid #e8505b;
}
.bootstrap-iso .carousel-dots .owl-dots .owl-dot:hover,
.bootstrap-iso .carousel-dots .owl-dots .owl-dot:focus {
	outline: none;
}
.bootstrap-iso .carousel-navs .owl-nav [class*="owl-"] {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	outline: none;
}
.bootstrap-iso .carousel-navs .owl-nav [class*="owl-"] span {
	display: none;
}
.bootstrap-iso .carousel-navs .owl-nav [class*="owl-"]::before {
	font-family: "icomoon";
	font-size: 30px;
	color: #e8505b;
}
.bootstrap-iso .carousel-navs .owl-nav [class*="owl-"]:hover:before {
	color: #212529;
}
.bootstrap-iso .carousel-navs .owl-nav .owl-prev {
	left: -40px;
	-webkit-transform: rotate(-180deg) translateY(50%);
	-ms-transform: rotate(-180deg) translateY(50%);
	transform: rotate(-180deg) translateY(50%);
}
.bootstrap-iso .carousel-navs .owl-nav .owl-prev::before {
	content: "\e901";
}
.bootstrap-iso .carousel-navs .owl-nav .owl-next {
	right: -40px;
}
.bootstrap-iso .carousel-navs .owl-nav .owl-next::before {
	content: "\e901";
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
	.bootstrap-iso .carousel-dots .owl-controls {
		margin-top: 20px;
	}
}
.bootstrap-iso .landing-hero {
	padding: 125px 0;
}
.bootstrap-iso .landing-hero .landing-content {
	padding-top: 100px;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .landing-hero .landing-content {
		padding-top: 50px;
		margin-bottom: 50px;
		text-align: center;
	}
}
.bootstrap-iso .landing-hero .landing-content h1 {
	font-size: 45px;
	font-weight: 500;
	line-height: 1.5;
	margin-bottom: 33px;
}
@media screen and (max-width: 767px) {
	.bootstrap-iso .landing-hero .landing-content h1 {
		font-size: 34px;
		line-height: 50px;
	}
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .landing-hero .landing-content {
		font-size: 50px;
		line-height: 55px;
	}
}
.bootstrap-iso .landing-hero .landing-content p {
	font-family: "Roboto", sans-serif;
	font-size: 18px;
	line-height: 27px;
}
.bootstrap-iso .landing-hero .landing-content .scroll-to i {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	display: inline-block;
	color: #e8505b;
	font-size: 42px;
	cursor: pointer;
	-webkit-animation: 1s Up-Down ease-in-out infinite;
	animation: 1s Up-Down ease-in-out infinite;
}
.bootstrap-iso .landing-demos {
	padding: 120px 0 40px 0;
}
.bootstrap-iso .landing-demos .showcase-card {
	-webkit-transition: all 300ms;
	-o-transition: all 300ms;
	transition: all 300ms;
	margin: 0 auto 80px auto;
	font-size: 16px;
	font-weight: 500;
	color: #212529;
	text-align: center;
	text-transform: capitalize;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .landing-demos .showcase-card {
		max-width: 500px;
	}
}
.bootstrap-iso .landing-demos .showcase-card .showcase-card-img {
	-webkit-box-shadow: 0px 30px 50px rgba(33, 37, 40, 0.1);
	box-shadow: 0px 30px 50px rgba(33, 37, 40, 0.1);
	margin-bottom: 30px;
}
.bootstrap-iso .landing-demos .showcase-card .showcase-card-img img {
	max-width: 100%;
	height: auto;
	-webkit-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}
.bootstrap-iso .landing-demos .showcase-card a {
	color: #212529;
	text-align: center;
	display: block;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}
.bootstrap-iso .landing-demos .showcase-card a:hover {
	color: #e8505b;
}
.bootstrap-iso .landing-demos .showcase-card:hover {
	-webkit-transform: translateY(-6px);
	-ms-transform: translateY(-6px);
	transform: translateY(-6px);
}
.bootstrap-iso .landing-action {
	padding: 120px 0;
}
.bootstrap-iso .landing-action h3 {
	color: #fffefe;
	font-size: 45px;
	font-weight: 500;
	line-height: 60px;
	margin-bottom: 20px;
}
@media screen and (max-width: 991px) {
	.bootstrap-iso .landing-action h3 {
		font-size: 28px;
		line-height: 35px;
	}
}
.bootstrap-iso .landing-action p {
	color: #ffffff;
	font-size: 18px;
	font-weight: 400;
	line-height: 1.5;
	margin-bottom: 5px;
}
.bootstrap-iso .landing-featues {
	padding-bottom: 50px;
}
.bootstrap-iso .landing-featues .feature-box {
	margin-bottom: 50px;
}
@-webkit-keyframes Up-Down {
	0% {
		-webkit-transform: translateY(0) rotate(90deg);
		transform: translateY(0) rotate(90deg);
	}
	50% {
		-webkit-transform: translateY(20px) rotate(90deg);
		transform: translateY(20px) rotate(90deg);
	}
	100% {
		-webkit-transform: translateY(0) rotate(90deg);
		transform: translateY(0) rotate(90deg);
	}
}
@keyframes Up-Down {
	0% {
		-webkit-transform: translateY(0) rotate(90deg);
		transform: translateY(0) rotate(90deg);
	}
	50% {
		-webkit-transform: translateY(20px) rotate(90deg);
		transform: translateY(20px) rotate(90deg);
	}
	100% {
		-webkit-transform: translateY(0) rotate(90deg);
		transform: translateY(0) rotate(90deg);
	}
}
body {
	margin: 0;
	padding: 0;
}

#background {
	margin-left: 0px;
	margin-right: 0px;
	position: relative;
	width: 1519px;
	height: 1636px;
	overflow: hidden;
	z-index: 0;
}

#Layer0 {
	position: absolute;
	height: 2000px;
	z-index: 1;
	width: 1600px;
}

#Rectangle4 {
	left: -191px;
	top: -175px;
	position: absolute;
	width: 1700px;
	height: 1044px;
	z-index: 2;
}

#Rectangle3 {
	left: -44px;
	top: -65px;
	position: absolute;
	width: 1680px;
	height: 139px;
	z-index: 6;
}

#Competeagainsthundre {
	left: 93px;
	top: 569px;
	position: absolute;
	width: 480px;
	height: 77px;
	z-index: 7;
}

#TestPractiseYourMach {
	left: 91px;
	top: 432px;
	position: absolute;
	width: 472px;
	height: 152px;
	z-index: 8;
}

#TestPractiseYourMach_0 {
	left: 93px;
	top: 372px;
	position: absolute;
	width: 515px;
	height: 151px;
	z-index: 9;
}

#machinehacklogo102 {
	left: 67px;
	top: 63px;
	position: absolute;
	width: 327px;
	height: 30px;
	z-index: 10;
}

#GLOBALRANKINGSALLHA {
	left: 746px;
	top: 71px;
	position: absolute;
	width: 534px;
	height: 13px;
	z-index: 11;
}

#Capture1 {
	left: 1332px;
	top: 54px;
	position: absolute;
	width: 101px;
	height: 44px;
	z-index: 12;
}

#Capture1copy {
	left: 1335px;
	top: 59px;
	position: absolute;
	width: 95px;
	height: 34px;
	z-index: 13;
}

#machinehacklogo102_0 {
	left: 67px;
	top: 63px;
	position: absolute;
	width: 327px;
	height: 30px;
	z-index: 14;
}

#GLOBALRANKINGSALLHA_0 {
	left: 746px;
	top: 71px;
	position: absolute;
	width: 534px;
	height: 13px;
	z-index: 15;
}

#Capture1_0 {
	left: 1332px;
	top: 54px;
	position: absolute;
	width: 101px;
	height: 44px;
	z-index: 16;
}

#Capture1copy_0 {
	left: 1335px;
	top: 59px;
	position: absolute;
	width: 95px;
	height: 34px;
	z-index: 17;
}

#manwalkingonthestree {
	left: 655px;
	top: 767px;
	position: absolute;
	width: 344px;
	height: 344px;
	z-index: 18;
}

#Ellipse1copy4 {
	left: 1056px;
	top: 754px;
	position: absolute;
	width: 185px;
	height: 185px;
	z-index: 20;
}

#Ellipse1copy6 {
	left: 1084px;
	top: 278px;
	position: absolute;
	width: 67px;
	height: 67px;
	z-index: 21;
}

#Ellipse1copy5 {
	left: 1357px;
	top: 161px;
	position: absolute;
	width: 81px;
	height: 81px;
	z-index: 22;
}

#VectorSmartObject1 {
	left: 1150px;
	top: 123px;
	position: absolute;
	width: 188px;
	height: 195px;
	z-index: 23;
}

#VectorSmartObjectcop {
	left: 865px;
	top: 1121px;
	position: absolute;
	width: 189px;
	height: 196px;
	z-index: 24;
}

#youngbearedmanagains {
	left: 657px;
	top: 183px;
	position: absolute;
	width: 408px;
	height: 408px;
	z-index: 25;
}

#pexelsdanielxavier12 {
	left: 945px;
	top: 368px;
	position: absolute;
	width: 344px;
	height: 344px;
	z-index: 27;
}

#asianmantakingaselfi {
	left: 795px;
	top: 531px;
	position: absolute;
	width: 260px;
	height: 260px;
	z-index: 29;
}

#BG {
	left: 141px;
	top: 375px;
	position: absolute;
	width: 444px;
	height: 623px;
	z-index: 31;
}

#bg {
	left: 184px;
	top: 454px;
	position: absolute;
	width: 352px;
	height: 48px;
	z-index: 32;
}

#EmailId {
	left: 184px;
	top: 438px;
	position: absolute;
	width: 49px;
	height: 8px;
	z-index: 33;
}

#bg_0 {
	left: 184px;
	top: 541px;
	position: absolute;
	width: 352px;
	height: 48px;
	z-index: 34;
}

#bg_1 {
	left: 184px;
	top: 716px;
	position: absolute;
	width: 352px;
	height: 128px;
	z-index: 35;
}

#message {
	left: 184px;
	top: 701px;
	position: absolute;
	width: 54px;
	height: 8px;
	z-index: 36;
}

#button {
	left: 285px;
	top: 894px;
	position: absolute;
	width: 176px;
	height: 50px;
	z-index: 37;
}

#submit {
	left: 346px;
	top: 914px;
	position: absolute;
	width: 53px;
	height: 10px;
	z-index: 38;
}

#bg_2 {
	left: 184px;
	top: 629px;
	position: absolute;
	width: 352px;
	height: 48px;
	z-index: 39;
}

#PhoneNumber {
	left: 184px;
	top: 524px;
	position: absolute;
	width: 91px;
	height: 8px;
	z-index: 40;
}

#Subject {
	left: 184px;
	top: 613px;
	position: absolute;
	width: 51px;
	height: 8px;
	z-index: 41;
}

#buttoncopy {
	left: 1122px;
	top: 1417px;
	position: absolute;
	width: 246px;
	height: 50px;
	z-index: 42;
}

#99f03fdee90d871d3d1e {
	left: 1240px;
	top: 1427px;
	position: absolute;
	width: 34px;
	height: 30px;
	z-index: 43;
}

#JustSayHi {
	left: 190px;
	top: 181px;
	position: absolute;
	width: 251px;
	height: 179px;
	z-index: 45;
}

#Layer640copy {
	left: 1130px;
	top: 1515px;
	position: absolute;
	width: 222px;
	height: 70px;
	z-index: 46;
}

#linkedinicon1logopng {
	left: 1311px;
	top: 1542px;
	position: absolute;
	width: 15px;
	height: 14px;
	z-index: 47;
}

#HomeFeaturesSer {
	left: 152px;
	top: 1542px;
	position: absolute;
	width: 519px;
	height: 13px;
	z-index: 48;
}

#CopyrightMachineHack {
	left: 170px;
	top: 1667px;
	position: absolute;
	width: 204px;
	height: 16px;
	z-index: 49;
}

#Copyrightsvgcopy {
	left: 151px;
	top: 1666px;
	position: absolute;
	width: 16px;
	height: 16px;
	z-index: 50;
}

#2802ndfloor5thMain15 {
	left: 176px;
	top: 1106px;
	position: absolute;
	width: 379px;
	height: 326px;
	z-index: 51;
}

#LetsTalk {
	left: 146px;
	top: 1253px;
	position: absolute;
	width: 81px;
	height: 11px;
	z-index: 52;
}

#DropinforaCoffee {
	left: 146px;
	top: 1078px;
	position: absolute;
	width: 179px;
	height: 11px;
	z-index: 53;
}

#Forma1 {
	left: 144px;
	top: 1410px;
	position: absolute;
	width: 300px;
	height: 25px;
	z-index: 54;
}

#Forma1_0 {
	left: 149px;
	top: 1275px;
	position: absolute;
	width: 300px;
	height: 25px;
	z-index: 55;
}

#Forma1_1 {
	left: 147px;
	top: 1103px;
	position: absolute;
	width: 300px;
	height: 100px;
	z-index: 56;
}

@media (min-width: 769px) {
	#form-contact {
		z-index: 60;
		position: absolute;
		left: 130px;
		top: 380px;
	}
}

@media (max-width: 769px) {
	#form-contact {
		position: absolute;
		margin-top: -930px;
		z-index: 1;
	}
}

#sm-background {
	height: 1027px;
	width: auto;
	background-color: #fef6f6;
	z-index: 0;
}
