.poll-header {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}

.poll-section {
  background-color: #ffffff;
  position: absolute;
  border: none;
  max-width: 600px;
  min-width: 370px;
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  min-height: 530px;
  max-height: 590px;
  z-index: 99999;
}

.poll-section .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(255, 255, 255, 0.3);
}

.addPollTextBottom {
  background: #e4950f;
  border-radius: 50px;
  height: 3px;
  position: absolute;
  top: 24px;
  width: 104px;
}

.poll-modal-saperator {
  border: 1px solid #d9d9d9;
}

.question-poll-input label {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.question-poll-input input,
.polls-input {
  border: 0.5px solid #d9d9d9;
  border-radius: 4px;
  min-height: 44px;
  max-height: 44px;
  padding: 0px 6px;
}

.question-poll-input input::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #757575;
}

.polls-btn-hld .polls-cancel-btn {
  border: 0.5px solid #d9d9d9;
  border-radius: 50px !important;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding: 7px 45px;
  background: transparent;
}

.poll-section .polls-preview-btn {
  background: linear-gradient(
    121.13deg,
    #dc4e5a 20.17%,
    #e2595b 31.41%,
    #f57f5f 71.17%,
    #fd8e61 92.78%
  );
  border-radius: 50px !important;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border: none;
  padding: 7px 45px;
}
.poll-section .disabled-btn {
  background: #757575;
}
.darkTheme .polls-preview-btn {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
}
.darkTheme .disabled-btn {
  background: #263559;
  border: 0.5px solid #263559;
}
.darkTheme .polls-preview-btn {
  border-radius: 50px !important;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border: none;
  padding: 7px 45px;
}

.add-option-text {
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  border: 0.5px solid #d9d9d9;
  border-radius: 4px;
  background: transparent;
}

.dark-add-option-text {
  border: 0.5px solid #263559;
  color: #b5c0d3;
}

.poll-scroll-section {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 550px);
  min-height: 315px;
}

.add-option-text button {
  all: unset;
}

.pollmodal-action-section {
  border-top: 0.5px solid #d9d9d9;
  border-bottom: 0.5px solid #d9d9d9;
}

/*dark*/
/* .dark-poll-section .MuiModal-backdrop {
    background-color: rgba(21, 29, 56, 0.65);
} */

.dark-poll-section {
  position: absolute;
  background-color: #1d2542;
  border: none;
  max-width: 600px;
  width: 600px;
  min-width: 370px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  max-height: 600px;
  z-index: 99999;
}

.dark-pollmodal-action-section {
  border-top: 0.5px solid #263559;
  border-bottom: 0.5px solid #263559;
}

.dark-highlighter {
  background: linear-gradient(
    90.01deg,
    rgb(20, 161, 223) 0.5%,
    rgb(120, 107, 249) 101.98%
  );
  box-shadow: rgb(0 197 191) 0px 4px 20px;
}

.dark-poll-modal-saperator {
  border: 1px solid #263559;
}

.dark-question-poll-input label {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.dark-question-poll-input input,
.dark-polls-input {
  border: 0.5px solid #263559;
  border-radius: 4px;
  min-height: 44px;
  max-height: 44px;
  background: #1b234a;
  color: #ffffff;
  padding: 0px 6px;
}

.dark-poll-section .dark-polls-input,
.poll-section .polls-input {
  text-align: left;
  padding: 8px 10px;
}

.polls-dropdown {
  position: absolute;
  top: 40px;
  left: 8%;
  border-radius: 5px;
  cursor: pointer;
  width: 30%;
  display: table;
}

.poll-section .polls-dropdown {
  background: #fff;
  border: 0.5px solid #d9d9d9;
}

.poll-section .polls-dropdown .polls-dropdown-item {
  border-bottom: 0.5px solid #d9d9d9;
}

.polls-section .polls-dropdown .polls-dropdown-item:last-child {
  border-bottom: none;
}

.darkTheme .polls-dropdown {
  background: #263559;
}

.darkTheme .polls-dropdown .polls-dropdown-item:hover {
  background: #1b234a;
}

.polls-dropdown .polls-dropdown-item {
  padding: 10px 20px;
  text-align: left;
}

.dark-question-poll-input input::placeholder,
.dark-polls-input p {
  font-weight: 400;
  font-size: 14px;
  color: #b5c0d3;
}

.bootstrap-iso .polls-btn-hld .dark-cancel-btn {
  border-radius: 50px;
  border: 1.41px solid #594ccb;
  color: #6244d4;
}

/*dark*/

/*mobile*/
.mobiledark-poll-section {
  background-color: #1d2542;
  border: none;
  max-width: 328px;
  min-width: 382px;
  position: absolute;
  top: 20%;
  left: 4%;
  border-radius: 10px;
  min-height: 522px;
  max-height: 533px;
}

.mobile-poll-section {
  background-color: #ffffff;
  border: none;
  max-width: 600px;
  min-width: 370px;
  position: absolute;
  top: 20%;
  left: 4%;
  border-radius: 10px;
  min-height: 530px;
  max-height: 530px;
}
.feed-poll-modal-overflow {
  overflow: auto;
  height: 450px;
}
