.discussion-title {
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 500 !important;
}
.discussion-title-mobile {
  font-size: 22px !important;
  color: #000 !important;
  font-weight: 500 !important;
}
.right-title {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #000 !important;
}
.title-container {
  background-color: #f3f3f3;
  border-radius: 10px 10px 0px 0px;
}
.title-container-no-border {
  background-color: #f3f3f3;
  /* border-radius: 10px 10px 0px 0px; */
}
.content-block {
  border: 1px solid #f3f3f3;
}
.SDfix {
  overflow-x: hidden;
  overflow-y: scroll;
}
.bookmarkBack {
  background: url("../../assets/img/DiscussionSite/bookmarkBack.svg") no-repeat;
  background-size: cover;
  width: 33px;
  height: 34px;
}
.bookmarkStar {
  top: 4px;
  right: 5.9px;
}
.right-side-content {
  color: #000 !important;
  font-size: 14px !important;
}
.btn-view-more {
  border: 1px solid #f3f3f3;
  border-radius: 50px !important;
  background-color: #fff;
  padding: 3px 28px;
}
.like-details-label {
  color: #f44f60;
  border: solid 1px #f44d5e;
  border-radius: 5px;
  font-size: 14px !important;
  padding: 5px 20px;
}
.img-circle {
  border-radius: 50%;
  height: 55px;
}
.black-small-text-bold {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}
.grey-text-small {
  color: #a5a6aa;
  font-size: 14px;
}
.like-dislike-div {
  display: flex;
  /* border: solid 0.5px #c4c4c4; */
  border-radius: 5px;
  max-height: 35px;
}
.isSelectedLikeDislike {
  background-color: #fff8f9;
  border-color: #f77a86 !important;
  color: #f87a85 !important;
  font-weight: bold;
  border: solid 1.5px #f77a86;
}
.like-div {
  border-radius: 5px 0px 0px 5px;
  font-size: 14px !important;
  font-weight: bold;
  color: #c4c4c4;
  border: solid 1px #c4c4c4;
  cursor: pointer;
}
.dislike-div {
  border-radius: 0px 5px 5px 0px;
  font-weight: bold;
  color: #c4c4c4;
  font-size: 14px !important;
  border: solid 1px #c4c4c4;
  cursor: pointer;
}
.reply-post-div {
  height: 100px;
  background-image: #000;
}
.post-btn {
  background-color: #babbbd;
  position: absolute;
  right: 16px;
  bottom: 0;
  border: none;
  padding: 7px 23px;
  border-radius: 2px !important;
}
.post-btn-active {
  background-color: #f44f60;
  position: absolute;
  right: 16px;
  bottom: 0;
  border: none;
  padding: 7px 23px;
  color: #fff;
  border-radius: 2px !important;
}
.post-comment-btn {
  background-color: #babbbd;
  position: absolute;
  right: 16px;
  bottom: 0;
  border: none;
  padding: 8px 23px;
  border-radius: 2px !important;
}
.post-comment-btn-active {
  background-color: #f44f60;
  position: absolute;
  right: 16px;
  bottom: 0;
  border: none;
  padding: 8px 23px;
  color: #fff;
  border-radius: 2px !important;
}
.img-circle-reply {
  border-radius: 50%;
  height: 45px;
}
.replyLine {
  background: #e4e4e4;
  width: 1px;
  height: 100px;
  margin: 6px auto;
}
.commentLine {
  background: #e4e4e4;
  width: 1px;
  height: 58%;
  margin: 6px auto;
}
.darkTheme .commentLine,
.darkTheme .replyLine {
  background: #263559;
}
.moreReplies {
  background: #e4e4e4;
  color: #5c5c5d;
  padding: 3px 16px;
  margin-top: 16px;
  width: max-content;
  border-radius: 50px !important;
  margin-left: -15px;
}
.viewMoreThread {
  font-weight: normal;
  font-size: 12px;
  line-height: 34px;
  color: #a5a6aa;
}
.viewMoreThread .viewmoreIcon {
  width: 25px;
  vertical-align: middle;
}
.deleted-user-hld {
  background: #f1f1f1;
  border-radius: 5px;
  padding: 5px 10px;
  width: max-content;
}
.darkTheme .deleted-user-hld {
  background: #262938;
}
.darkTheme .confirmDeleteModal .MuiDialogContent-dividers {
  border-top: 1px solid #303242;
}
.deleted-user-hld img {
  width: 10px;
  vertical-align: text-top;
}
.deleted-user-hld .deletedtxt {
  font-weight: 500;
  font-size: 12px;
  color: #000;
}
.discussionDesc img {
  max-width: 100%;
  height: auto;
}
.discussionDesc h1,
.discussionDesc h2,
.discussionDesc h3,
.discussionDesc h4,
.discussionDesc h5 {
  font-size: 18px;
}
.commentDiscussionDesc p {
  margin-bottom: 0px;
}
.discussion-filter img {
  width: 25px;
  margin-right: 10px;
  vertical-align: text-bottom;
}
.discussion-filter {
  text-align: center;
}
.discussion-filter h2 {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #000;
}
.confirmDeleteModal .MuiDialogContent-dividers {
  border-top: 1px solid #e8e8e8;
  border-bottom: none;
  padding: 20px 75px 10px;
  text-align: center;
}
.confirmDeleteModal .MuiDialogContentText-root {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
  color: #000;
  margin-bottom: 0;
}
.confirmDeleteModal .MuiDialogActions-root {
  justify-content: center;
  margin-bottom: 20px;
}
.dummyLine {
  border-left: 1px solid #e4e4e4;
  width: 1px;
  height: 100%;
  position: absolute;
  left: -46px;
  top: 10px;
}
.postTopic.ck.ck-placeholder:before,
.ck .ck-placeholder:before {
  font-size: 16px;
  color: #c4c4c4;
  font-family: "Roboto";
}
.commentHighlight {
  background: #f8f8f8;
  width: 100%;
  padding: 10px 30px;
  margin: 0 -18px;
  border-radius: 20px;
}
.darkTheme .commentHighlight{
  background: #151D38;
}
.ck.ck-balloon-panel {
  z-index: 999999 !important;
}

/* dark mode */
.dangerouslyText,
.dangerouslyText p,
.dangerouslyText ul li 
.dangerouslyText h1, .dangerouslyText h2, .dangerouslyText h3, .dangerouslyText h4{
  color: #b5c0d3 !important;
}
.dangerouslyText pre{
  background: #262938 !important;
  color: #f4f4f4;
}
.borderCards {
  border-radius: 5px 5px 0px 0px !important;
}
.darkTheme .like-details-label {
  color: #6540d7;
  border: solid 1px #6540d7;
}
.darkComment img {
  width: 30px;
  height: 20px;
}
.darkTheme .dislike-div img {
  width: 18px;
  margin-top: 3px;
}
.darkTheme .like-div img{
  margin-top: -5px;
}

.darkTheme .darkText p {
  color: #b5c0d3 !important;
}
.darkBorder {
  border: 1px solid #303242;
}
.marginBottomFixDark {
  margin-bottom: 0px !important;
}
.commenterFeatureSection .discusSubText{
  font-size: 14px;
  font-weight: 400;
}
.SDfix .right-side-container .contentBoxDis{
  border-radius: 0px 0px 5px 5px !important;
}
.darkTheme .singleDiscussionTextHover .dangerouslyText li, .darkTheme .singleDiscussionTextHover .dangerouslyText a{
  color: #ffffff;
}