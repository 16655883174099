.cross-img:hover {
  cursor: pointer;
}

.loginSeprator {
  background: #e7e7e7;
  height: 0.5px;
  width: 69%;
}
.mandatory-hint-astrick {
  color: #ff6a79;
  font-size: 14px;
  font-weight: 400;
}
.input-margin-container {
  margin-top: 5px;
}
.input-hint {
  position: absolute;
  top: 50%;
  left: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #858585;
  transform: translate(0%, -50%);
}
.darkmainFrame .input-hint {
  color: rgba(181, 192, 211, 0.65);
}
.error-hidden {
  padding-bottom: 26px;
}
.button {
  padding: 16px;
  border: none;
  border-radius: 5px !important;
  background-color: #f2f3f4;
  width: 110px;
  height: 70px;
}
.darkmainFrame .studyplansignup .errorInput {
  border: 1px solid #dc0f0f;
}
.darkmainFrame .studyplansignup {
  height: 100vh;
}
.darkmainFrame .login-dark-input-box {
  border-radius: 5px;
  border: 1px solid #B5C0D3;
  background: #1d2542;
}
.darkmainFrame .login-dark-input-box:focus-within {
  border: 1px solid #145b99 !important;
  background: rgba(38, 53, 89, 0.5) !important;
}
.darkmainFrame .login-dark-input-box input::placeholder {
  color: #b5c0d3;
}

.darkmainFrame.mainFrame .inputFilled {
  border: 1px solid #263559;
  background: rgba(38, 53, 89, 0.5);
}

a {
  all: unset;
}

a:hover {
  cursor: pointer;
}

.button:hover {
  cursor: pointer;
  border: 1px solid #f44d5e;
  background-color: #fff0f0;
}

.icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.register-container {
  text-align: center;
}

.login-icon {
  margin: 0px 10px;
}

.login-dark-card-bg-color {
  background-color: #263559;
}

.login-dark-card-bg-color:hover {
  transition: background 1s linear;
  border: 1.5px solid rgba(86, 79, 200, 0.3);
  background: linear-gradient(
    121deg,
    rgba(68, 94, 183, 0.3) 0%,
    rgba(101, 64, 215, 0.3) 100%
  );
}

.register-btn {
  background-color: #f2f3f4;
  color: #3a3a3a;
  font-weight: 700;
  font-size: 18px;
  border: none;
  border-radius: 50px !important;
  width: 100%;
  height: 50px;
  transition: background-color 1s linear;
}

.login-container {
  font-weight: 400;
  padding-right: 50px;

  :nth-child(5) {
    font-size: 13px;
    padding-left: 2px;
  }
}

.border1 {
  width: 20%;
  height: 1px;
  background-color: #e7e7e7;
}

.loginThinText {
  color: #4f4f4f;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
}

.border {
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.loginPopup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-rows {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: flex-end;
}

.col-second {
  padding-right: 50px;
}

.newRegister {
  padding-right: 50px;

  b {
    text-align: center;
    margin-top: 15px;
    margin-bottom: -10px;
  }

  font-weight: 400;
}

.MuiInputBase-root {
  padding: 0;

  ::placeholder {
    font-size: 14px;
  }
}

.MuiInput-underline::after,
.MuiInput-underline::before {
  border-bottom: none !important;
}

.message-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  padding: 10px 10px 12px 20px;
  margin: 0 auto 0 auto;
  width: 300px;
  height: min-content;
  background: #ffffff;
  box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.03);
  border-radius: 5px;

  div {
    span {
      font-weight: 400;
    }
  }
}

.sucessfull-message {
  color: #28b446;

  span {
    margin-left: 5px;
  }
}

.message-close-arrow > button > img {
  height: 12px;
  width: 12px;
}
.message-close-arrow > button {
  width: auto !important;
}

.snackBar {
  display: flex;
  justify-content: center;
}

.visibilityIcon {
  color: #f44d5e;
}

.darkTheme .visibilityIcon {
  color: #6540d7;
}

.darkTheme .darkVisible {
  color: #b5c0d3;
}

.password-lable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;

  a {
    font-size: 13px;
  }
}

.error-message {
  color: #f44d5e;
  font-size: 13px;
  display: block;
  height: 26px;
}

.error-input-snackBar {
  color: #f44d5e;
  font-size: 13px;
}

.error-input {
  height: fit-content;
  border: 1px solid #f44d5e;
  border-radius: 3px;
  margin-bottom: 0px;
}

.darkmainFrame .error-input {
  border: 1px solid #f44d5e;
}
.sucessfull-input-snackBar {
  color: #28b446;
  font-size: 13px;
}

.input,
.inputFilled {
  height: fit-content;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  position: relative;
}

.mainFrame .inputFilled {
  border: 1px solid #d9d9d9;
  background: rgba(242, 243, 244, 0.3);
}

.mainFrame .MuiInputBase-input {
  padding: 14px 16px 14px 16px !important;
  height: 16px !important;
  font-size: 14px;
  font-weight: 400;
  background: transparent;
  border-radius: 5px;
}

.darkmainFrame .MuiInputBase-input {
  color: #fff;
}

.reset-password-container {
  padding-right: 50px;

  :nth-child(5) {
    font-size: 13px;
  }
}

.email-input {
  margin-top: 34px;
  margin-bottom: 41px;
}

.register-btn-container {
  text-align: center;
}

.mainFrame .login-btn {
  border-radius: 50px;
  background: #f2f3f4;
  color: #000;
  font-size: 15px;
  font-weight: 700;
  border: none;
  height: 50px;
  width: 100%;
  text-align: center;
  transition: background 1s linear;
}

.register-btn-container {
  .login-btn-active {
    background: #f44d5e;
    color: #fff;
  }

  .termsAndConditionsText {
    text-decoration: underline;
    color: #4f4f4f;
  }
  .termsAndConditionsText:hover {
    color: #4f4f4f;
  }
}

.mainFrame {
  background-color: #ffffff;
  // width: 440px;
  // border-radius: 10px;
  font-family: "Inter";
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  // border: 1px solid #d9d9d9;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-page-cntr {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-page-cntr .login-left-cntr {
  background: #3c3e4d;
  text-align: center;
}
.darkTheme .login-page-cntr .login-left-cntr {
  background: transparent;
}

.login-page-cntr .loginHeadText {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #ffff;
}
.login-page-cntr .loginText {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 4px;
}
.login-page-cntr .left-cntr-list {
  font-family: "Inter";
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
}

.darkmainFrame {
  // border-radius: 10px;
  background: #1d2542;
  // border: 1px solid #1D2542;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.cross-img {
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.text {
  color: #000000;
  font-size: 13px;
  font-weight: 500;
}

.mainFrame {
  .reset-password-header {
    color: #292929;
    font-size: 18px;
    font-weight: 700;
    margin: 45px 0px 35px 0px;
  }

  .login-middle-section {
    margin: 50px 0px;
  }

  .login-register-text {
    color: #f44d5e;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
  }

  .link-text {
    color: #4f4f4f;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  }
}

.password-lable {
  a {
    font-size: 13px;
  }
}
.signupDropDownItem,.signupDropdownSelect{
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
.signupDropDownItem:hover{
  background-color: #1d2542;
}
.signupDropdown{
  position: absolute;
  z-index: 1;
  bottom: 0px;
  border-radius: 5px;
  padding: 8px 20px;
  background: #fff;
  max-height: 200px;
  overflow-x: scroll;
}
.signupDropdownSelect{
  padding: 8px 10px;
  background: transparent;
  border-radius: 5px;
  border: 1px solid #b8b8b8;
  width: 100%;
}
.darkTheme .signupDropdownSelect{
  border: 1px solid #B5C0D3;
  color: #fff;
}
.darkTheme .signupDropdown{
  background: #000726;
  color: #fff;
}
// .password-lable> :nth-child(1) {
//   color: #3a3a3a;
//   font-weight: 400;
// }

.password-lable > :nth-child(2) {
  color: #27c1f2 !important;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.bootstrapisoauth.darkmainFrame .login-inactive-btn {
  background-color: #b5c0d3;
  color: #1d2542;
}
.newRegister .register-btn {
  margin: 40px 0px 40px 0px;
}
@media only screen and (min-width: 576px) {
  .hello {
    display: none;
  }

  .mainFrame {
    padding: 0 35px;
  }

  .col-second {
    padding-right: 0px;
  }

  .reset-password-container {
    padding-right: 0px;
  }

  .newRegister {
    padding-right: 0px;
  }

  .login-container {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 100px) and (max-width: 1050px) {
  .hello {
    display: none;
  }

  .mainFrame {
    padding: 0 35px;
  }

  .col-second {
    padding-right: 0px;
  }

  .reset-password-container {
    padding-right: 0px;
  }

  .col-second {
    padding-right: 0px;
  }

  .reset-password-container {
    padding-right: 0px;
  }

  .newRegister {
    padding-right: 0px;
  }

  .login-container {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 992px) {
  .hello {
    display: none;
  }

  .mainFrame {
    padding: 0 25px;
  }

  .col-second {
    padding-right: 0px;
  }

  .reset-password-container {
    padding-right: 0px;
  }

  .col-second {
    padding-right: 0px;
  }

  .reset-password-container {
    padding-right: 0px;
  }

  .newRegister {
    padding-right: 0px;
  }

  .login-container {
    padding-right: 0px;
  }
}

@media (min-width: 1200px) {
  .hello {
    display: none;
  }

  .mainFrame {
    padding: 0 25px;

    .col-second {
      padding-right: 0px;
    }

    .reset-password-container {
      padding-right: 0px;
    }

    .col-second {
      padding-right: 0px;
    }

    .reset-password-container {
      padding-right: 0px;
    }

    .newRegister {
      padding-right: 0px;
    }

    .login-container {
      padding-right: 0px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .mainFrame {
    width: 100%;
    padding: 0px 23px;
    position: fixed;
    bottom: 0;
    border-radius: 30px 30px 0px 0px;
  }
  .mainFrame .button {
    padding: 12px;
    width: 68px;
    height: 60px;
  }
  .border1 {
    width: 16%;
  }
}

.Success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
}

.Success-btn-container {
  width: 200px;
}

.info-input-snackBar {
  color: rgb(0, 122, 255);
  font-size: 14px;
}

.MuiInputBase-root .no-hover:hover {
  background-color: transparent;
}

.register-btn {
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.darkTheme .register-btn {
  background: #b5c0d3;
  color: #1d2542;
}
.register-btn-tansition {
  position: absolute;
  background-size: 150% 150%;
  height: 100%;
  width: 100%;
  border-radius: 50px;
  z-index: -1;
  transition: all 0.4s ease;
  top: 0;
  left: 0;
}
.register-btn:hover .register-btn-tansition {
  background-image: linear-gradient(
    113deg,
    #dc4e5a 0%,
    #e2595b 13%,
    #f57f5f 59%,
    #fd8e61 84%
  );
}
.bootstrapisoauth .register-btn:hover {
  color: #fff;
}
.darkTheme .register-btn:hover .register-btn-tansition {
  background-image: linear-gradient(to right, #e040fb, #5350c6, #6640d7);
  -webkit-animation: buttonHoverAnimation 2s ease infinite;
  -moz-animation: buttonHoverAnimation 2s ease infinite;
  animation: buttonHoverAnimation 2s ease infinite;
  background-repeat: no-repeat;
}
.mob-drag {
  border-radius: 50px;
  background: #d9d9d9;
  width: 60px;
  height: 3px;
  margin: 10px auto;
  text-align: center;
  cursor: grab;
}
.mainFrame .active-login-btn {
  background: linear-gradient(
    113deg,
    #dc4e5a 0%,
    #e2595b 13%,
    #f57f5f 59%,
    #fd8e61 84%
  );
  color: #ffffff !important;
}

@-webkit-keyframes buttonHoverAnimation {
  0% {
    background-position: 0% 49%;
  }
  50% {
    background-position: 100% 52%;
  }
  100% {
    background-position: 0% 49%;
  }
}
@-moz-keyframes buttonHoverAnimation {
  0% {
    background-position: 0% 49%;
  }
  50% {
    background-position: 100% 52%;
  }
  100% {
    background-position: 0% 49%;
  }
}
@keyframes buttonHoverAnimation {
  0% {
    background-position: 0% 49%;
  }
  50% {
    background-position: 100% 52%;
  }
  100% {
    background-position: 0% 49%;
  }
}
