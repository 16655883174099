.feedArticleSection {
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  border-radius: 10px;
}
.articleChatbtn {
  background: #0fd68d;
  border: 1px solid #ffffff;
  padding: 4px;
  border-radius: 50px;
  position: absolute;
  bottom: 3px;
  right: 5px;
}

.artcile-user-img {
  max-width: 60px;
  max-height: 60px;
  border-radius: 50px;
}

.userArticle-user-name {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}

.userArticle-Url {
  font-weight: 500;
  font-size: 13px;
  color: #757575;
  max-width: 160px;
}

.userArticle-dot {
  background: #757575;
  border-radius: 50px;
  padding: 3px !important;
}
.darkTheme .userArticle-dot {
  background: #b5c0d3;
}
.dark-userArticle-dot {
  background: #b5c0d3;
  border-radius: 50px;
  padding: 3px;
}

.userArticle-premiumBtn {
  max-width: 17px;
  max-height: 17px;
}

.userArticle-content,
.userArticle-content * {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: "Inter", sans-serif !important;
  color: #000000 !important;
  background-color: transparent !important;
  background: transparent !important;
  border: none !important;
  text-decoration: none !important;
  margin: 0;
  padding: 0;
  width: auto !important;
  float: unset !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  white-space: unset !important
}
.userArticle-content h1,
.userArticle-content h2,
.userArticle-content h3,
.userArticle-content h4,
.userArticle-content h5 {
  font-size: 20px !important;
}
.bootstrap-iso .userArticle-content ul,
.bootstrap-iso .userArticle-content ol {
  margin-left: 40px !important;
}
.userArticle-content img {
  max-width: 100%;
  height: auto;
}
.threeLines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.darkTheme .userArticle-content,
.darkTheme .userArticle-content * {
  color: #fff !important;
}

.darkTheme .userArticle-content a {
  color: #2183f5 !important;
}
.darkTheme .userArticle-content pre{
  background-color: #19203D;
  margin: 5px;
}
.moible-userArticle-content {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.userArticle-actionSection {
  border-top: 0.5px solid #d9d9d9;
  /* border-bottom: 0.5px solid #d9d9d9; */
}
.userActionBorder {
  border-top: 0.5px solid #d9d9d9;
}
.darkTheme .userActionBorder {
  border-top: 0.5px solid #263559;
}

.userArticleActionText {
  font-weight: 500;
  font-size: 14px;
  color: #757575;
  line-height: 22px;
  min-width: 60px;
  /* width: max-content; */
}
.dark-userArticle-actionSection {
  border-top: 0.5px solid #263559;
  /* border-bottom: 0.5px solid #263559; */
}

/*forMobile*/
.mobile-ArticlePop-section-section .MuiBox-root {
  background-color: #ffffff;
  border-radius: 10px;
  border: none;
}

.mobiledark-ArticlePop-section-section .MuiBox-root {
  width: 388px;
  background-color: #1d2542;
  border-radius: 10px;
  border: none;
}

/*forMobile*/

/*Modal*/

.ArticlePop-section .MuiBox-root {
  background-color: #ffffff;
  border-radius: 10px;
  border: none;
}

.dark-ArticlePop-section .MuiBox-root {
  background-color: #1d2542;
  border-radius: 10px;
  border: none;
}

.dark-article-pop-separator {
  border: 0.4px solid #263559;
}

.article-pop-separator {
  border: 0.4px solid #d9d9d9;
}
.article-pop-text {
  background: #f2f3f4;
  border: 0.5px solid #d9d9d9;
  border-radius: 4px;
  max-height: 600px;
  overflow: auto;
}
.darkTheme .article-pop-text {
  background: #151d38;
  border: 0.5px solid #263559;
}
.poll-post-btn,
.darkTheme .poll-post-btn {
  background: linear-gradient(
    121.13deg,
    #dc4e5a 20.17%,
    #e2595b 31.41%,
    #f57f5f 71.17%,
    #fd8e61 92.78%
  );
  border-radius: 50px;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  border: none;
  border-radius: 50px !important;
  padding: 5px 55px;
  min-width: 130px;
}

/*Confirm*/
.ArticlePop-confirm .MuiBox-root {
  background-color: #ffffff;
  border-radius: 10px;
  border: none;
}

.dark-ArticlePop-confirm .MuiBox-root ,.mobiledark-ArticlePop-confirm-section .MuiBox-root{
  background-color: #1d2542;
  border-radius: 10px;
  border: none;
  min-width: 320px;
}

.confirmation-text {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #000000 !important;
  line-height: 0px !important;
  margin-bottom: 0px !important;
}

.dark-confirmation-text {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #ffffff !important;
  line-height: 0px !important;
  margin-bottom: 0px !important;
}

.confirmation-text-span {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.dark-confirmation-text-span {
  color: #b5c0d3;
  font-weight: 400;
  font-size: 16px;
}

.bootstrap-iso .confirmation-btn {
  background: linear-gradient(
    121.13deg,
    #dc4e5a 20.17%,
    #e2595b 31.41%,
    #f57f5f 71.17%,
    #fd8e61 92.78%
  );
  border-radius: 50px;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  border: none;
  border-radius: 50px !important;
  padding: 8px 30px;
  min-width: 130px;
}
.darkTheme .confirmation-btn {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
  color: #fff;
}

.bootstrap-iso .feed-discard-btn {
  border-radius: 50px !important;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  padding: 8px 30px;
  min-width: 130px;
  background: #757575;
  color: #ffffff;
}
.darkTheme .feed-discard-btn {
  background: #263559;
  border: 0.5px solid #263559;
}
.show-more {
  background-color: transparent;
  color: #757575;
  font-weight: 500;
  font-size: 14px;
  border: none;
  position: absolute;
  right: 18px;
  bottom: 0px;
  padding: 0;
  line-height: 1;
}
.darkTheme .show-more {
  background: #1b234a;
}
 .show-more{
  background: #fff;
}
.show-more:hover {
  text-decoration: underline;
}
.feedArticleSection .slick-dots {
  bottom: -20px;
}
.feedArticleSection .slick-dots li,
.slick-dots li button {
  width: 10px;
}
.feedArticleSection .slick-dots li button::before,
.feedArticleSection .slick-dots li.slick-active button:before {
  width: 6px;
  height: 6px;
  border-radius: 50px;
}
.feedArticleSection .slick-dots li.slick-active button:before {
  width: 8px;
  background-color: #dc4e5a;
  top: -1px;
  height: 8px;
}
.darkTheme .feedArticleSection .slick-dots li.slick-active button:before {
  background-color: #6540d7;
}
.user-options-hld {
  background: #f2f3f4;
  border: 0.5px solid #d9d9d9;
  border-radius: 6px;
  position: absolute;
  right: 0;
  top: 26px;
  width: 120px;
  z-index: 99;
}
.darkTheme .user-options-hld {
  background: #263559;
  border: 0.2px solid #263559;
}
.user-options-hld p {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0;
  padding-left: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
  border-bottom: 0.5px solid #d9d9d9;
}
.darkTheme .user-options-hld p {
  border-bottom: 0.5px solid #192038;
}
.user-options-hld p:last-child {
  border-bottom: 0.5px solid transparent;
}
.feed-image-hld {
  width: 50%;
  height: 250px;
}
.feed-image {
  object-fit: cover;
  width: 50%;
  height: 250px;
  border: 2px solid transparent !important;
}
.full-width {
  width: 100%;
  height: 60%;
}
.two-images {
  height: 400px;
}
.fit-three-images {
  width: 33.33%;
}
.feedArticleSection .profileCard {
  left: 70px;
}
.usersListCntr {
  position: absolute;
  z-index: 127264327;
  background: #000726;
  width: 360px;
}
/*confirm*/

/*Modal*/
