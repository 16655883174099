.feed-header-logo {
  width: 200px;
  object-fit: contain;
}
.feed-search {
  background: #ffffff;
  border-radius: 50px;
  border: 0.5px solid #d9d9d9;
}

.feed-search input {
  border: none;
  background: transparent;
  width: 100%;
  padding-left: 5px;
}

.feed-search input::placeholder {
  font-family: 'Inter',sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #757575;
  padding-left: 10px;
}

.feed-search img {
  max-width: 12px !important;
  min-width: 12px;
  min-height: 12px;
}

.feed-header-section {
  position: fixed;
  top: 0;
  width: 100%;
  background: #f6f6f7;
  z-index: 9;
}

.feed-header-section-main {
  max-width: 1200px;
  margin: 0 auto;
}
.feed-header-section-main .feed-Enterprise {
  border: 0.5px solid #d9d9d9;
  border-radius: 50px;
  padding: 8px 20px;
  cursor: pointer;
  background: transparent;
}
.feed-header-section-main .dark-feed-Enterprise {
  background: linear-gradient(#09112e, #09112e) padding-box,
    linear-gradient(45deg, #896ced, #0bdab1) border-box;
  border: 0.5px solid transparent;
}
.feed-header-section-main .feed-Enterprise img {
  margin-right: 4px;
}
.feed-header-section-main .feed-Enterprise span {
  background: linear-gradient(92.31deg, #af245f 4.46%, #fb6d7e 101.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
  font-size: 14px;
}
.feed-header-section-main .dark-feed-Enterprise span {
  background: linear-gradient(90.01deg, #896ced 0.01%, #0bdab1 99.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.feed-header-section-main .dark-feed-Enterprise span:hover {
  background: linear-gradient(90.01deg, #0bdab1 0.01%, #896ced 99.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.light-toogle-btn {
  background: #263559;
  border-radius: 50px;
  max-width: 30px !important;
  min-width: 30px;
  min-height: 30px;
  text-align: center;
}

.light-toogle-btn:hover {
  background: #232d51;
}

.feed-header-login {
  background: #010101;
  border: none;
  border-radius: 50px !important;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  padding: 5px 35px;
}

.feed-search-container {
  max-width: 652px;
  min-width: 827px;
}
.dark-user-info-btn {
  background: #1B234A;
  border-radius: 50px;
  border: 0.5px solid #1B234A;
}

.user-info-btn {
  background: #f2f3f4;
  border-radius: 50px;
  border: 0.5px solid #D9D9D9;
}

.user-info-btn img {
  max-width: 30px;
  max-height: 30px;
  border-radius: 50px;
}

.dark-user-info-btn img {
  max-width: 30px;
  max-height: 30px;
  border-radius: 50px;
}

.user-info-name-Text {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 55px;
  display: -webkit-box;
}

/*dark*/
.dark-feed-search {
  background: #1B234A;
  border-radius: 50px;
}

.dark-feed-search input {
  border: none;
  background: transparent;
  width: 100%;
  color: #ffffff;
  font-size: 15px;
  padding-left: 5px;
}

.dark-feed-search input::placeholder {
  font-weight: 400;
  font-size: 15px;
  color: #B5C0D3;
  padding-left: 10px;
}

.dark-feed-search img {
  max-width: 12px !important;
  min-width: 12px;
  min-height: 12px;
}

.dark-feed-header-section {
  position: fixed;
  top: 0;
  width: 100%;
  background: #000726;
  z-index: 9;
}
.gray-out-header{
  z-index: -99;
}

.dark-toogle-btn {
  background: #b5c0d3;
  border-radius: 50px;
  max-width: 30px !important;
  min-width: 30px;
  min-height: 30px;
  text-align: center;
}
.dark-toogle-btn:hover {
  background: #909fb9;
}
/*dark*/

/*navbar*/
.feed-nav-toggle {
  width: 80%;
}

.feed-nav-toggle .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  z-index: 99;
}

.feed-nav-toggle .MuiPaper-elevation {
  width: 100%;
  position: absolute;
}
.dark-feed-nav .MuiPaper-elevation{
  background-color: #19203d;
}

.dark-nav-bar-info-section {
  background: #F6F6F7;
  border: 0.2px solid #D9D9D9;
}

.darkTheme .dark-nav-bar-info-section {
  background: #0B1234;
  border: 0.2px solid #263559;
}

.dark-nav-user-text {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}

.nav-user-text {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.dark-nav-user-url {
  font-weight: 500;
  font-size: 12px;
  color: #757575;
}

.nav-coin-count {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}
.darkTheme .nav-coin-count {
  color: #ffffff;
}

.nav-user-img {
  max-width: 48px !important;
  min-width: 48px;
  min-height: 48px;
  border-radius: 50px;
}

.nav-route {
  background-color: #ffffff;
}
.darkTheme .nav-route {
  background-color: #19203d;
}
.nav-route-section {
  border-bottom: 0.2px solid #D9D9D9;;
}
.darkTheme .nav-route-section {
  border-bottom: 0.2px solid #263559;
}

.nav-route-span {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.nav-premium-btn {
  font-weight: 600;
  font-size: 14px;
  color: #E69811;
  background: rgba(255, 202, 40, 0.1);
  padding: 17px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.darkTheme .nav-premium-btn {
  background: #30313b;
}

.mobile-feed--navigation {
  background: #010725;
  border: 0.5px solid #263559;
  position: sticky;
  bottom: 0;
  width: 100%;

}

.feed-navigate-active {
  background: linear-gradient(92.97deg, #445EB7 10.8%, #6540D7 94.39%);
  border-radius: 10px;
  width: 86px;
  height: 5px;
}

.mobile-nac-section {
  max-width: 81px;
  min-width: 81px;
  max-height: 63px;
  min-height: 63px;
}
.dark-feed-header-section .profiledropdown ,.feed-header-section .profiledropdown{
  right: 117px;
  top: 60px;
}