.hexagonal-image-1,
.hexagonal-image-inner {
  width: 66px;
  height: 66px;
  background-size: cover;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(
    45% 1.33975%,
    46.5798% 0.60307%,
    48.26352% 0.15192%,
    50% 0%,
    51.73648% 0.15192%,
    53.4202% 0.60307%,
    55% 1.33975%,
    89.64102% 21.33975%,
    91.06889% 22.33956%,
    92.30146% 23.57212%,
    93.30127% 25%,
    94.03794% 26.5798%,
    94.48909% 28.26352%,
    94.64102% 30%,
    94.64102% 70%,
    94.48909% 71.73648%,
    94.03794% 73.4202%,
    93.30127% 75%,
    92.30146% 76.42788%,
    91.06889% 77.66044%,
    89.64102% 78.66025%,
    55% 98.66025%,
    53.4202% 99.39693%,
    51.73648% 99.84808%,
    50% 100%,
    48.26352% 99.84808%,
    46.5798% 99.39693%,
    45% 98.66025%,
    10.35898% 78.66025%,
    8.93111% 77.66044%,
    7.69854% 76.42788%,
    6.69873% 75%,
    5.96206% 73.4202%,
    5.51091% 71.73648%,
    5.35898% 70%,
    5.35898% 30%,
    5.51091% 28.26352%,
    5.96206% 26.5798%,
    6.69873% 25%,
    7.69854% 23.57212%,
    8.93111% 22.33956%,
    10.35898% 21.33975%
  );
}
.hexagonal-image-1 {
  background: linear-gradient(180deg, #23112e 0%, #000726 100%);
  width: 76px;
  height: 70px;
  padding-left: 5px;
  padding-top: 5px;
}
.hexagonal-image-inner img {
  height: inherit;
  width: inherit;
  object-fit: cover;
}
.hexagonal-image-2,
.hexagonal-image-inner {
  width: 66px;
  height: 66px;
  background-size: cover;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(
    45% 1.33975%,
    46.5798% 0.60307%,
    48.26352% 0.15192%,
    50% 0%,
    51.73648% 0.15192%,
    53.4202% 0.60307%,
    55% 1.33975%,
    89.64102% 21.33975%,
    91.06889% 22.33956%,
    92.30146% 23.57212%,
    93.30127% 25%,
    94.03794% 26.5798%,
    94.48909% 28.26352%,
    94.64102% 30%,
    94.64102% 70%,
    94.48909% 71.73648%,
    94.03794% 73.4202%,
    93.30127% 75%,
    92.30146% 76.42788%,
    91.06889% 77.66044%,
    89.64102% 78.66025%,
    55% 98.66025%,
    53.4202% 99.39693%,
    51.73648% 99.84808%,
    50% 100%,
    48.26352% 99.84808%,
    46.5798% 99.39693%,
    45% 98.66025%,
    10.35898% 78.66025%,
    8.93111% 77.66044%,
    7.69854% 76.42788%,
    6.69873% 75%,
    5.96206% 73.4202%,
    5.51091% 71.73648%,
    5.35898% 70%,
    5.35898% 30%,
    5.51091% 28.26352%,
    5.96206% 26.5798%,
    6.69873% 25%,
    7.69854% 23.57212%,
    8.93111% 22.33956%,
    10.35898% 21.33975%
  );
}
.hexagonal-image-2 {
  background: linear-gradient(180deg, #01242f 0%, #000726 100%);
  width: 76px;
  height: 70px;
  padding-left: 5px;
  padding-top: 5px;
}
.hexagonal-image-inner img {
  height: inherit;
  width: inherit;
  object-fit: cover;
}
.hexagonal-image-3,
.hexagonal-image-inner {
  width: 66px;
  height: 66px;
  background-size: cover;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(
    45% 1.33975%,
    46.5798% 0.60307%,
    48.26352% 0.15192%,
    50% 0%,
    51.73648% 0.15192%,
    53.4202% 0.60307%,
    55% 1.33975%,
    89.64102% 21.33975%,
    91.06889% 22.33956%,
    92.30146% 23.57212%,
    93.30127% 25%,
    94.03794% 26.5798%,
    94.48909% 28.26352%,
    94.64102% 30%,
    94.64102% 70%,
    94.48909% 71.73648%,
    94.03794% 73.4202%,
    93.30127% 75%,
    92.30146% 76.42788%,
    91.06889% 77.66044%,
    89.64102% 78.66025%,
    55% 98.66025%,
    53.4202% 99.39693%,
    51.73648% 99.84808%,
    50% 100%,
    48.26352% 99.84808%,
    46.5798% 99.39693%,
    45% 98.66025%,
    10.35898% 78.66025%,
    8.93111% 77.66044%,
    7.69854% 76.42788%,
    6.69873% 75%,
    5.96206% 73.4202%,
    5.51091% 71.73648%,
    5.35898% 70%,
    5.35898% 30%,
    5.51091% 28.26352%,
    5.96206% 26.5798%,
    6.69873% 25%,
    7.69854% 23.57212%,
    8.93111% 22.33956%,
    10.35898% 21.33975%
  );
}
.hexagonal-image-3 {
  background: linear-gradient(180deg, #262427 0%, #000726 100%);
  width: 76px;
  height: 70px;
  padding-left: 5px;
  padding-top: 5px;
}
.hexagonal-image-inner img {
  height: inherit;
  width: inherit;
  object-fit: cover;
}

.rank-section {
  top: 37px;
  z-index: 0;
  position: absolute;
}
.dark_top-activity-hld {
  background-image: url(../img/dark_ranker_bg.svg);
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: contain;
}

.dark_top-activity-hld > div {
  margin-top: -40px;
}
.light_top-activity-hld {
  background-image: url(../img/light_rank_bg.svg);
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: contain;
}
.light_top-activity-hld > div {
  margin-top: -40px;
}
.top-ranker-name-text-one {
  font-weight: 700;
  font-size: 13px;
  color: #000000;
  padding-top: 130px;
  padding-left: 6px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80px;
}
.darkTheme .top-ranker-name-text-one {
  color: #b5c0d3;
}
.rank-text {
  bottom: -22%;
  left: 33%;
  width: 32px;
  height: 30px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding-top: 6px;
  color: #ffffff;
  text-shadow: 2px 2px 8px #091d01;
  background-size: cover;
  clip-path: polygon(
    40% 7.67949%,
    43.1596% 6.20615%,
    46.52704% 5.30384%,
    50% 5%,
    53.47296% 5.30384%,
    56.8404% 6.20615%,
    60% 7.67949%,
    81.65064% 20.17949%,
    84.50639% 22.17911%,
    86.97152% 24.64425%,
    88.97114% 27.5%,
    90.44449% 30.6596%,
    91.34679% 34.02704%,
    91.65064% 37.5%,
    91.65064% 62.5%,
    91.34679% 65.97296%,
    90.44449% 69.3404%,
    88.97114% 72.5%,
    86.97152% 75.35575%,
    84.50639% 77.82089%,
    81.65064% 79.82051%,
    60% 92.32051%,
    56.8404% 93.79385%,
    53.47296% 94.69616%,
    50% 95%,
    46.52704% 94.69616%,
    43.1596% 93.79385%,
    40% 92.32051%,
    18.34936% 79.82051%,
    15.49361% 77.82089%,
    13.02848% 75.35575%,
    11.02886% 72.5%,
    9.55551% 69.3404%,
    8.65321% 65.97296%,
    8.34936% 62.5%,
    8.34936% 37.5%,
    8.65321% 34.02704%,
    9.55551% 30.6596%,
    11.02886% 27.5%,
    13.02848% 24.64425%,
    15.49361% 22.17911%,
    18.34936% 20.17949%
  );
}
.rank-text-1 {
  background: linear-gradient(177.46deg, #ffc600 1.99%, #f55555 73.94%);
}
.rank-text-2 {
  background: linear-gradient(184.46deg, #ffd800 -18.44%, #00d79e 96.87%);
}
.rank-text-3 {
  background: linear-gradient(0deg, #fa148b -5%, #f7764c 91.67%);
}
.hexagon {
  position: relative;
  margin: 1em auto;
  width: 10em;
  height: 17.32em;
  border-radius: 1em/0.5em;
  background: orange;
  transition: opacity 0.5s;
}

.hexagon:before,
.hexagon:after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: "";
}

.hexagon:before {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  box-shadow: 0px 3px 6px #02cd5d;
}

.hexagon:after {
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg);
}

.dark-rankers-list {
  background: #151D38;
  border-bottom: 0.5px solid #263559;
}
.dark-rankers-list-current {
  background: transparent;
  border: 0.5px solid #263559;
}
.table-heading-text {
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
}
.table-header {
  background: #121A35;
  border-radius: 5px 5px 0px 0px;
  border: 0.5px solid #263559;
}
.dark-ranker-text {
  background: #263559;
  font-weight: 700;
  font-size: 12px;
  color: #b5c0d3;
  padding: 0px 10px;
  border-radius: 0px 15px 15px 0px;
  min-width: 34px;
  max-height: 24px;
  position: absolute !important;
  top: 11px;
}
.dark-ranker-text-cuurent {
  background: #786bf9;
}
.ranker-img-section-img {
  width: 28px;
  max-height: 28px;
  border-radius: 50px;
}
.increment-text {
  left: 32px;
  position: absolute;
}
.increment-text-img {
  width: 71px;
}
.green-arrow-right {
  position: relative;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  color: #0fd68d;
}
/* .green-arrow-right:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 10px solid transparent;
  border-left: 10px solid #16273b;
}
.green-arrow-right:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  right: 100%;
  top: 0;
  border: 10px solid transparent;
  border-right: 10px solid #16273b;
} */
/* .green-arrow-right  img {
  max-width: 8.5px !important;
} */
.dark-ranker-name-text {
  font-weight: 700;
  font-size: 12px;
  color: #b5c0d3;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 90px;
  overflow: hidden;
}
.ranker-name-text {
  color: #000000;
  font-weight: 700;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 70px;
  overflow: hidden;
}
.dark-rank-time-text {
  font-weight: 500;
  font-size: 10px;
  color: #b5c0d3;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 70px;
  overflow: hidden;
}
.rank-time-text {
  font-weight: 500;
  font-size: 10px;
  color: #757575;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 70px;
  overflow: hidden;
}
.dark-chat-notification {
  background: #0fd68d;
  border: 1px solid #ffffff;
  border-radius: 50px;
  left: 20px;
  top: 20px;
}
.ranker-image-chat {
  position: absolute;
  z-index: 1;
}
.ranker-fireLottie {
  width: 15px;
  vertical-align: baseline;
}
.sword-text {
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}
.dark-sword-text {
  background: linear-gradient(90.01deg, #896ced 0.01%, #0bdab1 99.24%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 18px;
}
.dark-table-header {
  background-color: #1b1d2e;
  border-radius: 5px 5px 0px 0px;
}
.rank-list {
  background-color: transparent;
  border-left: 0.5px solid #d9d9d9;
  border-right: 0.5px solid #d9d9d9;
}
.ranker-list-current {
  background: #feebed;
  border-left: 0.5px solid #d9d9d9;
  border-right: 0.5px solid #d9d9d9;
}
.ranker-current-text {
  background: #f44d5e;
}
.light_showbtn {
  background: #ddddde;
  border-radius: 50px !important;
  font-weight: 500;
  font-size: 12px !important;
  border: none;
  width: 100%;
  color: #757575;
  padding: 8px 19px;
}
.dark_showBtn {
  background: #151d38;
  border-radius: 50px;
  padding: 8px 19px;
  color: #b5c0d3;
}
.light_stepper {
  border: 0.5px solid #d9d9d9;
}
/*Loader*/
.top-loader {
  height: 30px;
  width: 30px;
}
/*Loader*/
.topRankHld {
  min-width: 50px;
  text-align: center;
}
.hackathonTopperscard .profileCard {
  top: 20px;
  left: 15px;
}
