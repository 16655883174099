.top-header {
    background: #000000;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.darkTop-header {
    background: linear-gradient(269.97deg, #0088FF 1.76%, #E607B0 107.84%);
}

.top-header-logo-hld {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 4px 20px;
    margin-right: 18px;
}

.top-header .top-header-btn {
    border: none;
    background: linear-gradient(121.13deg, #DC4E5A 20.17%, #E2595B 31.41%, #F57F5F 71.17%, #FD8E61 92.78%);
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    padding: 9px 20px;
}

.top-header .top-header-btn:hover {
    transform: translateY(-0.33em);
    border-bottom: 4px solid #000000;
}

.darkTop-header .top-header-btn {
    background: linear-gradient(283.18deg, #7554EC -10.6%, #E75996 63.41%, #FF8841 97.5%);
}

.main-top-header{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
}
 .main-top-header-mob{
    padding: 5px 10px;
    flex-direction: column;
    align-items: flex-start;
}
.main-top-header-mob .top-header-logo-hld{
    width: 180px;
    padding: 0px 8px;
}
.main-top-header-mob .top-header-logo-hld img{
    width: 162px;
}
.main-top-header-mob .top-header-hackathon-img{
    height: 13px;
    width: 158px;
    margin-top: 5px;
}
.main-top-header-mob .top-header-btn {
    position: absolute;
    right: 0;
    top: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.flipUnitContainer .flipUnitContainerText{
  position: absolute;
  bottom: -19px;
  font-weight: 700;
  font-size: 10px;
  width: inherit;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0px;
}
.flipClock {
    display: flex;
    justify-content: space-between;
    margin: 0px 48px 14px 48px;
  }
  
  .flipUnitContainer {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    perspective-origin: 50% 50%;
    perspective: 100px;
    background-color: rgba(30,30,30,1);
    border-radius: 3px;
    box-shadow: 0px 10px 10px -10px grey;
  }
  
  .upperCard, .lowerCard {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    height: 50%;
    overflow: hidden;
    border: 1px solid rgba(30,30,30,1);
  }
  .upperCard span, .lowerCard span {
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    color: #FFFFFF;
  }
  
  .upperCard {
    align-items: flex-end;
    border-bottom: 0.5px solid rgba(30,30,30,1);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .upperCard span {
    transform: translateY(50%);
  }
  
  .lowerCard {
    align-items: flex-start;
    border-top: 0.5px solid rgba(30,30,30,1);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .lowerCard span {
    transform: translateY(-50%);
  }
  
  .flipCard {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
  .flipCard span {
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    color: #FFFFFF;
  }
  .flipCard.unfold {
    top: 50%;
    align-items: flex-start;
    transform-origin: 50% 0%;
    transform: rotateX(180deg);
    background-color: rgba(30,30,30,1);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 0.5px solid rgba(30,30,30,1);
    border-top: 0.5px solid rgba(30,30,30,1);
  }
  .flipCard.unfold span {
    transform: translateY(-50%);
  }
  .flipCard.fold {
    top: 0%;
    align-items: flex-end;
    transform-origin: 50% 100%;
    transform: rotateX(0deg);
    background-color: rgba(30,30,30,1);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 0.5px solid rgba(30,30,30,1);
    border-bottom: 0.5px solid rgba(30,30,30,1);
  }
  .flipCard.fold span {
    transform: translateY(50%);
  }
  
  .fold {
    -webkit-animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
            animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    transform-style: preserve-3d;
  }
  
  .unfold {
    -webkit-animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
            animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    transform-style: preserve-3d;
  }
  
  @-webkit-keyframes fold {
    0% {
      transform: rotateX(0deg);
    }
    100% {
      transform: rotateX(-180deg);
    }
  }
  
  @keyframes fold {
    0% {
      transform: rotateX(0deg);
    }
    100% {
      transform: rotateX(-180deg);
    }
  }
  @-webkit-keyframes unfold {
    0% {
      transform: rotateX(180deg);
    }
    100% {
      transform: rotateX(0deg);
    }
  }
  @keyframes unfold {
    0% {
      transform: rotateX(180deg);
    }
    100% {
      transform: rotateX(0deg);
    }
  }
@media (max-width: 600px) {
  .main-top-header {
    height: 65px;
  }
  .multi-header-container{
    top: 65px;
  }
  .main-top-header-mob .top-header-logo-hld {
    border-radius: 5px;
  }
  .main-top-header-mob .top-header-btn {
    top: 16px;
  }
  .main-top-header-mob .top-header-hackathon-img {
    margin-top: 7px;
  }
}
@media (max-width: 320px) {
  .top-header .top-header-btn {
    padding: 9px 10px;
  }
}