.machineAI-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.machine-AI-Assistant {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 100px;
}
.globe-img {
  position: absolute;
  left: 235px;
  bottom: 241px;
}
.machinehack-ai-text {
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  margin-top: 50px;
}
.machineAiModal .css-79ws1d-MuiModal-root {
  bottom: -37px !important;
}
.globe-bg {
  width: 100%;
  height: 97px;
  filter: blur(90px);
  border-radius: 50px;
  position: absolute;
  bottom: 100px;
  background: #ff084bb3;
}
.machineAI-response {
  position: fixed;
  /* or absolute, depending on your layout needs */
  bottom: -118px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 36;
}
.mini-ai-text {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}
.machineAiresponseModal .css-qgoqd0 {
  position: absolute;
  bottom: -12%;
  left: 50%;
}
.halfglobePractive {
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 0%);
  margin: 0 auto;
  z-index: 9999;
  cursor: pointer;
}
.prac-ask-again {
  border-radius: 50px;
  background-image: url(assets/img/MachineAi/askBtn.svg);
  border: none;
  font-weight: 700;
  font-size: 11.6683px;
  color: #ffffff;
  max-width: 137px;
  min-width: 137px;
  max-height: 31px;
  min-height: 31px;
}
.practice-action-btn {
  padding: 10px 20px;
}
.closed-half-globe-semiCircle {
  border-radius: 50px;
  min-width: 165px;
  min-height: 172px;
  max-width: 165px;
  position: absolute;
  bottom: 0px;
}
.darkTheme .practiceSnackBar .MuiPaper-root {
  background: #c93c31;
  position: relative;
  top: 30px;
}
.machine-ai-lottie {
  background: linear-gradient(135deg, #087ce1 0%, #e607b0 100%);
  border-radius: 50px;
  width: 83px;
  height: 83px;
}
