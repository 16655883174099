.bootstrapisoauth .hiring-company {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
}

.light-hackathon-layout-spacing {
  padding: 0 80px !important;
  max-width: 1400px;
}

.bootstrapisoauth .hiring-banner-div-h2 {
  font-size: 27px;
}

.bootstrapisoauth .overview,
.overview>p,
ul,
li {
  font-size: 15px;
  color: #111111;
  font-weight: 400;
  line-height: 25px;
}

.overview h4,
.overview h5,
.overview h3 {
  font-size: 16px;
}

.overview h3 strong,
.overview h4 strong,
.overview h5 strong,
.overview h3,
.overview h4,
.overview h5 {
  font-weight: 500;
  font-size: 16px;
}

.overviewHackathonCntr .overview h4,
.overviewHackathonCntr .overview h5,
.overviewHackathonCntr .overview h3 {
  font-size: 16px;
}

.prizeOverview img {
  width: 100%;
}

.overview img {
  max-width: 100%;
  height: auto;
}

.overview h3 strong,
.overview h4 strong,
.overview h5 strong,
.overview h3,
.overview h4,
.overview h5 {
  font-weight: 500 !important;
  font-size: 16px !important;
}

.usersubmissionInfo h5 {
  font-size: 16px;
  color: #000;
}

.pink-color-svg {
  min-height: 150px;
}

.overview img {
  max-width: 100%;
  height: auto;
}

.hackathon-banner {
  border-radius: 10px;
  border: solid 1px #dadce0;
  background-color: #fff;
}

.hackathon-banner .hackathon-hosted-logo {
  position: absolute;
  width: 120px;
  height: 130px;
  border-radius: 6px;
  border: solid 3px #d3d2d2;
  top: 60%;
  left: 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hackathon-banner .hackathon-hosted-logo img {
  /* width: 80px;
  height: 80px; */
  border-radius: 4px;
}

.hackathon-banner .hack-name {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}

.hackathonRunningStatus {
  border-bottom: 4px solid #f2f1f1;
  width: 100%;
  position: relative;
}

.hackathonRunningStatus div {
  border-bottom: 4px solid #5cb85c;
  position: absolute;
}

.overviewCntr {
  border-radius: 10px;
  border: solid 1px #dadce0;
  background-color: #fff;
}

.participateCntr {
  background: #ffffff;
  border: 1px solid #dadce0;
  border-radius: 10px;
  padding: 20px;
}

.participateCntr .calender-icon {
  width: 30px;
  height: 30px;
}

.participateCntr .calenderhld {
  border-bottom: 1px solid #dadce0;
}

.userRankCntr .evntDuration {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  display: block;
}

.userRankCntr .evntDate {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #000;
}

.userRankCntr .beginnerTxt {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #000;
}

.userRankCntr .beginnerTxt img {
  width: 15px;
  vertical-align: middle;
}

.userRankCntr .codetxt img {
  width: 15px;
  vertical-align: middle;
}

.userRankCntr .codetxt {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #000;
}

.userRankCntr .price {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}

.userRankCntr .price img {
  width: 15px;
  vertical-align: middle;
}

.userRankCntr .btn {
  background-color: #f44d5e !important;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 900;
  color: #fff !important;
  border-radius: 50px !important;
}

.participateCntr .btn img {
  width: 24px;
}

.userRankHld {
  border-radius: 5px;
  background-color: #f4f5f4;
}

.userRankHld .head {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  padding: 16px;
  border-bottom: 1px solid #dadce0;
}

.userRankHld .userRank {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.userRankHld .userScore {
  font-size: 14px;
  font-weight: 500;
  color: #232020;
}

.userRankHld .userScore img {
  width: 16px;
  height: 16px;
}

.userRankHld .improveRankInfo {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #232020;
  background-color: #eaebec;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.userRankHld button {
  bottom: -15px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 46%;
  transform: translate(-46%, 0);
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #f54d5e;
  border: none;
  background: #fff;
  border-radius: 5px;
}

.userRankHld button img {
  width: 15px;
  height: 15px;
}

.top-rankers-hld {
  background-color: #fff;
  border-radius: 10px;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  border: 1px solid #dadce0;
}

.top-rankers-hld .heading {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  padding: 16px;
  border-bottom: 1px solid #dadce0;
}

.top-rankers-hld .dark-heading {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  text-align: left;
  color: #b5c0d3;
  padding: 16px;
  border-bottom: 1px solid #303242;
}

.top-rankers-hld .userImage {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50px;
}

.top-rankers-hld .user-Image-Hld {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50px;
}

.top-rankers-hld .user-Image-Hld .user-Image {
  width: 50px;
  height: auto;
  cursor: pointer;
  border-radius: 50px;
}

.top-rankers-hld .userData .name {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #232020;
  margin-bottom: 0;
  cursor: pointer;
  display: inline-block;
}

.top-rankers-hld .userData .dark-name {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  margin-bottom: 0;
  cursor: pointer;
  display: inline-block;
}

.top-rankers-hld .userData .toppersScore {
  font-size: 12px;
  text-align: left;
  color: #232020;
}

.top-rankers-hld .userData .dark-toppersScore {
  font-size: 12px;
  text-align: left;
  color: #b5c0d3;
  font-weight: 400;
}

.top-rankers-hld .userData .rank {
  font-size: 14px;
  color: #000;
}

.top-rankers-hld .userData .dark-rank {
  font-size: 12px;
  color: #b5c0d3;
  font-weight: 400;
}

.top-rankers-hld .userData .rank .rank-icon {
  width: 14px;
  height: 14px;
}

.top-rankers-hld .borderbottom {
  border-bottom: 1px solid #dadce0;
}

.top-rankers-hld .dark-borderbottom {
  border-bottom: 1px solid #303242;
}

.top-rankers-hld .telegramHld {
  background-color: #ecedf0;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.top-rankers-hld .onlytelegram {
  border-radius: 10px;
}

.telegramHld p {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: #232020;
}

.telegramHld a {
  background: #fff !important;
  border-radius: 50px;
  padding: 6px 12px;
  font-weight: 500;
  font-size: 16px;
  color: #000;
}

.telegramHld a .telegram-icon {
  width: 20px;
  height: 20px;
}

.prizeMoneyTxt {
  font-size: 18px;
  color: #000;
  font-weight: 400;
}

.hackathonHeading {
  font-size: 22px;
  font-weight: 500;
  color: #f54d5e;
}

.hackathonData p {
  font-size: 15px;
  color: #848e96;
  font-weight: 400;
}

.hackathonData .hackathonDatalist {
  list-style: none;
  font-size: 15px;
  color: #848e96;
  font-weight: 400;
}

.hackathonData .dataDownloadHld {
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
}

.hackathonData .dataDownloadHld h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  text-align: center;
  border-bottom: 1px solid #dadce0;
}

.hackathonData .datadarkDownloadHld .borderBottom {
  border-bottom: 1px solid #dadce0;
}

.darkTheme .hackathonData .datadarkDownloadHld .borderBottom {
  border-bottom: 1px solid #263559
}

.hackathonData .datadarkDownloadHld .borderTop {
  border-top: 1px solid #dadce0;
}

.darkTheme .hackathonData .datadarkDownloadHld .borderTop {
  border-top: 1px solid #263559;
}

/* .hackathonData .dataDownloadHld button {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #101010;
  background: #f4f5f4;
  border-radius: 5px;
  width: 200px;
} */
.hackathonData .dataDownloadHld button img {
  width: 16px;
  height: 16px;
}

.black-title-medium {
  color: #212529;
  font-weight: bold;
  font-size: 18px;
}

.notebookHld {
  border-radius: 10px;
  border: solid 1px #e5e5e5 !important;
}

.notebookHld .username {
  font-size: 16px;
  font-weight: 500;
  color: #f44d5e;
  width: 150px;
}

.hackathonRulesList h6 {
  font-size: 14px;
  text-transform: capitalize;
}

.descHeading {
  font-size: 17px !important;
}

.hackathonDataDesc>p,
ul,
li {
  font-size: 15px;
  color: #111111;
  font-weight: 400;
  line-height: 25px;
}

.hackathonDataDesc h1,
.hackathonDataDesc h2,
.hackathonDataDesc h3,
.hackathonDataDesc h4,
.hackathonDataDesc h5,
.hackathonDataDesc h6 {
  font-size: 18px;
  font-weight: 500;
  color: #f54d5e;
}

.bootstrapisoauth .pink-color-svg>img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 150px;
  object-fit: cover;
  object-position: 50% 0%;
}

.bootstrapisoauth .pink-color-svg-cover>img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 150px;
  object-fit: cover;
  object-position: 50% 20%;
}

.usersubmissionInfo button {
  background: linear-gradient(121.13deg, #DC4E5A 20.17%, #E2595B 31.41%, #F57F5F 71.17%, #FD8E61 92.78%);
  display: block;
  font-size: 14px;
  font-weight: 900;
  color: #fff;
  border-radius: 4px;
  border: none;
  padding: 5px 20px;
}

.darkTheme .usersubmissionInfo .dark-button {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
  border-radius: 50px;
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  border: none;
}

.reviewername {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.reviewerDesignation {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #848e96;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  min-height: 38px;
}

.reviewerSMIcon {
  width: 30px;
  height: 30px;
}

.rulesUnorderedlist {
  list-style-type: disc;
}

.solutionCntr .winnerhld {
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 20px rgb(102 102 102 / 5%),
    0px 2px 8px rgb(221 221 221 / 4%);
  border-radius: 5px;
  min-height: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  cursor: pointer;
  position: relative;
}

.solutionCntr .dark-winnerHld {
  background: #262938;
  border: 0.5px solid #2f3242;
}

.solutionCntr .heading {
  font-weight: 500 !important;
  font-size: 22px !important;
  line-height: 24px;
  color: #f54d5e;
}

.solutionCntr .winnerhld:hover {
  background: #f2faff;
  border: 0.5px solid #52c1ff;
  box-shadow: 0px 10px 20px rgba(32, 160, 225, 0.05);
}

.solutionCntr .dark-winnerHld:hover {
  background: #343847;
  border: 0.5px solid #2f3242;
}

.solutionCntr .activeWinnerhld .underline {
  height: 4px;
  width: 55px;
  background: #f44d5e;
  position: absolute;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  bottom: -27px;
  content: "";
  transform: translate(-50%, -50%);
  left: 50%;
}

.solutionCntr .dark-activeWinnerhld .underline {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
}

.solutionCntr .activeWinnerhld .underline::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 45%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #f44d5e transparent;
}

.solutionCntr .dark-activeWinnerhld .underline::after {
  border-color: transparent transparent #6540d7 transparent;
}

.solutionCntr .activeWinnerhld {
  background: #f2faff;
  border: 0.5px solid #52c1ff;
  box-shadow: 0px 10px 20px rgba(32, 160, 225, 0.05);
}

.solutionCntr .dark-activeWinnerhld {
  background: #343847;
  border: 0.5px solid #2f3242;
}

.solutionCntr .dark-solution-hr {
  background-color: #303242;
}

.winnerhld:hover .premiumOverlay {
  visibility: visible;
  opacity: 0.8;
}

.solutionCntr .premiumOverlay {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.winnerhld sub {
  vertical-align: text-top;
  font-size: smaller;
}

.winnerhld p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.SolutionNotebook {
  padding-bottom: 1293px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.SolutionNotebook iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.HomeTabActive {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  display: inline-block;
  color: #ffffff;
  border-radius: 5px;
  padding: 4px 20px;
  cursor: pointer;
  background: linear-gradient(121.13deg, #DC4E5A 20.17%, #E2595B 31.41%, #F57F5F 71.17%, #FD8E61 92.78%);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  min-width: 120px
}

.HomeTabInActive {
  padding: 4px 20px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.darkHomeTabActive {
  display: inline-block;
  color: #ffffff;
  border-radius: 5px;
  padding: 4px 20px;
  cursor: pointer;
  background: #6442d6;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  min-width: 120px
}

.darkHomeTabActive p {
  font-size: 14px;
  font-weight: 600;
}

.labelDropZone {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  display: block;
  color: #000;
}

.darkHomeTabInActive p {
  font-weight: 500;
  font-size: 14px;
}

.darkHomeTabInActive {
  color: #b5c0d3;
  padding: 4px 34px 4px 20px;
  cursor: pointer;
}

.routesHld .darkTheme .dark-hackathon-layout-spacing {
  padding: 0 80px;
  max-width: 1400px;
}

.bootstrapisoauth .dark-overview,
.dark-overview p,
.dark-overview ul,
.dark-overview ul li,
.dark-overview ol li {
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  line-height: 28px;
}

.dark-overview h4,
.dark-overview h5,
.dark-overview h3 {
  font-size: 16px;
}

.dark-overview h3 strong,
.dark-overview h4 strong,
.dark-overview h5 strong,
.dark-overview h3,
.dark-overview h4,
.dark-overview h5 {
  font-weight: 500;
  font-size: 16px;
}

.dark-overview .MuiAlert-standardInfo {
  color: #ffffff;
  background-color: #262938;
}

.dark-overview .MuiAlert-message li b {
  color: #ffffff;
}

.dark-overview>h3 strong,
.dark-overview>h4 strong,
.dark-overview>h5 strong,
.dark-overview>h3,
.dark-overview>h4 {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #ffffff !important;
}

.dark-overview .table td,
.bootstrapisoauth .table th {
  border-top: 1px solid #303242;
  color: #ffffff;
}

.dark-overview img {
  max-width: 100%;
  height: auto;
}

.darkTheme .purplebg {
  background-color: #6442d6 !important;
}

.darkTheme .purpleText {
  color: #6442d6;
}

.bootstrapisoauth .redText {
  color: #e9505c;
}

.hackathonData .datadarkDownloadHld {
  box-sizing: border-box;
}

.hackathonData .datadarkDownloadHld h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  text-align: center;
  border-bottom: 1px solid #D9D9D9;
}

.darkTheme .hackathonData .datadarkDownloadHld h5 {
  color: #b5c0d3;
  border-bottom: 0.5px solid #263559;
}

.hackathonData .datadarkDownloadHld button {
  color: #101010;
  background: #f4f5f4;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  border-radius: 50px;
  padding: 4px 30px;
}

.darkTheme .hackathonData .datadarkDownloadHld button {
  background: #263559;
  border: 1px solid #263559;
  color: #fff;
}

.hackathonData .datadarkDownloadHld .dark-download-white {
  width: 16px;
  height: 16px;
}

.darkTheme .hackathonData .datadarkDownloadHld .dark-download-white {
  filter: invert(100%) sepia(84%) saturate(0%) hue-rotate(299deg) brightness(107%) contrast(101%);
}

.bottom-border-grey {
  border-bottom: 1px solid #303242;
}

.postedbyUsername {
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
}

.uploadnotebookPopup .MuiDialog-paperScrollPaper {
  border: none;
  background: #1D2542;
  border-radius: 10px;
}

.uploadnotebookPopup .MuiDialog-paperScrollPaper h6 ,.uploadnotebookPopupLight .MuiDialog-paperScrollPaper h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: rgb(181, 192, 211);
  text-transform: capitalize;
}
.uploadnotebookPopupLight .MuiDialog-paperScrollPaper h6 {
  color: #000;
}
.uploadnotebookPopup .MuiDialog-paperScrollPaper .file-upload-label {
  display: block;
}

.uploadnotebookPopup .MuiDialog-paperScrollPaper .MuiOutlinedInput-notchedOutline {
  border: none;
}

.uploadnotebookPopup .MuiDialog-paperScrollPaper .MuiInputBase-input {
  color: #ffffff !important;
}

.dark-file-submission .MuiDropzoneArea-root {
  min-height: 150px;
  background-color: transparent;
  border-radius: 10px;
  border: 2px dashed #2f3242;
  align-items: center;
  display: flex;
  justify-content: center;
}

.dark-file-submission .file-upload-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  display: block;
}

.hackathoncenterTabs {
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  border-radius: 5px;
}

.darkTheme .hackathoncenterTabs {
  background: #0c1431;
  border: 0.5px solid #263559;
}

.shareinsoacialmediaicon {
  background: rgba(242, 243, 244, 0.4);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.darkTheme .shareinsoacialmediaicon {
  background: rgba(38, 53, 89, 0.4);
  border: 0.5px solid #0c1431;
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .userRankHld button {
    left: 44%;
  }
}

@media only screen and (max-width: 1300px) {
  .bootstrapisoauth .hiring-banner-div-h2 {
    font-size: 35px;
  }

  .hiring-banner-image {
    height: 200px;
  }
}

@media only screen and (max-width: 800px) {
  .bootstrapisoauth .hiring-banner-div-h2 {
    font-size: 20px;
  }

  .hiring-banner-image {
    height: 200px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .hackathon-banner .hack-name {
    font-size: 16px;
  }

  .participateCntr {
    padding: 10px;
  }

  .top-rankers-hld .heading {
    padding: 10px;
    font-size: 15px;
  }

  .mobileUserScore {
    flex-direction: column;
  }

  .top-rankers-hld .mobToppersHld {
    margin: 10px !important;
  }

  .top-rankers-hld .telegramHld {
    padding: 10px !important;
  }

  .top-rankers-hld .telegramHld a {
    padding: 6px 8px;
    font-size: 12px;
  }

  .top-rankers-hld .telegramHld a .telegram-icon {
    width: 15px;
  }

  .top-rankers-hld .userData .name {
    font-size: 14px;
  }

  .hackathoncenterTabs {
    white-space: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

@media only screen and (max-width: 600px) {

  /* .hiring-banner-div {
    height: 300px;
    background: rgb(233, 80, 92);
  } */
  /* .hiring-banner-image {
    display: none;
  } */
  .hackathonData .usersubmissionInfo .dark-button{
    font-size: 14px;
  }
  .active-nav::after {
    bottom: -2px;
    left: 20px;
    transform: none;
  }
  .hackathoncenterTabs {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: pre;
  }
  .darkTheme .homePageTabs{
    border-radius: 50px;
    background: #000726;
    border: none;
    display: flex;
    justify-content: space-around;
    padding: 6px  0px;
  }
  .darkHomeTabActive{
    padding: 4px 12px;
    background: #34297B;
    border-radius: 50px;
    min-width: auto;
  }
  .darkHomeTabActive p ,.darkHomeTabInActive ,.darkHomeTabInActive p ,.bootstrapisoauth .prizeActiveTab p ,.prizeInactiveTab p{
    font-size: 12px;
    line-height: 24px;
  }
  .darkHomeTabInActive ,.prizeActiveTab ,.prizeInactiveTab{
    padding: 4px 12px 4px 12px;
  }
  .darkTheme .prizeActiveTab{
    border-radius: 50px;
    border: 1px solid transparent;
  }
  .darkTheme .prizeInactiveTab{
    background: none;
    border-radius: 50px;
  }
  .bootstrapisoauth .dark-overview ,.hackathon-participateCntr .beginnerTxt ,.hackathon-participateCntr .codetxt {
    font-size: 14px
  }
  .hackathonBannerCntr .mob-hackathon-ai-search-hld{
    position: fixed;
    z-index: 999;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%,0%);
  }
  .darkTheme .hackathon-content-border{
    background: transparent;
    border: none;
  }
  .darkTheme .hackathoncenterTabs {
    background: #263559;
    border: none;
    border-radius: 0px;
}
  .hackathoncenterTabs::-webkit-scrollbar {
    display: none;
  }

  .active-nav::after {
    bottom: -4px;
  }

  .homePageTabsMob {
    flex-wrap: wrap;
  }

  .userRankCntr .participateCntr,
  .userRankCntr .top-rankers-hld {
    margin: 10px 16px;
  }

  .hackathon-banner .hackathon-hosted-logo {
    width: 50px;
    height: 50px;
    top: 80%;
  }

  .hackathondetailsMob {
    padding-left: 10px !important;
    margin-top: 20px;
  }

  .bootstrapisoauth .banner-menu-item {
    margin: 2px 0px;
    padding: 10px 20px;
    display: inline-flex !important;
    min-width: 90px;
  }

  .bootstrapisoauth .shareinsoacialmediaicon {
    margin-left: 14px !important;
  }

  .winnerhld p {
    font-size: 12px;
  }

  .routesHld .darkTheme .dark-hackathon-layout-spacing {
    padding: 0px;
    max-width: 100%;
    background: #0C1431;
  }

  .hackathonBannerCntr {
    text-align: center;
    padding: 10px 0;
  }
}