.notificationModal {
  display: flex;
  justify-content: flex-end;
  margin-top: 64px;
  z-index: 1 !important;
}
.notificationModalHld {
  background: #ffffff;
  width: 400px;
  height: fit-content;
}
.pushNotificationHeadingHld {
  border-bottom: 0.5px solid #e4e8ee;
}
/* .notificationModal .MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.1);
} */
.pushNotificationHeadingHld img {
  width: 18px;
  height: 18px;
  vertical-align: sub !important;
}
.pushNotificationHeadingHld .notificationTitle {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}
.pushNotificationHeadingHld .notificationTitle .unreadNotificationCount {
  background: #feedef;
  border-radius: 50px;
  color: #f44d5e;
  font-weight: 600;
  font-size: 12px;
  padding: 2px 10px;
}
.pushNotificationHeadingHld .markAllread {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  cursor: pointer;
}
.darkTheme .pushNotificationHeadingHld {
  border-bottom: 0.5px solid #263559;
}
.defaultnotifyImg {
  width: 32px;
  height: 32px;
  border-radius: 50px;
}
.NotificationHld {
  padding-right: 20px;
}
.NotificationHld .acceptBtn {
  background: #f44d5e;
  border-radius: 5px;
  border: 1px solid #f44d5e;
  color: #fff;
  padding: 0px 8px;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
}
.NotificationHld .acceptedBtn {
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #67bb55;
  color: #67bb55;
  padding: 0px 8px;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
}
.NotificationHld .refusedBtn {
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #f44d5e;
  color: #f44d5e;
  padding: 0px 8px;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
}
.NotificationHld .refuseBtn {
  background: #000;
  border-radius: 50px;
  color: #ffffff;
  padding: 0px 16px;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  border: none;
}
.NotificationHld .viewDetailsBtn {
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  padding: 4px 16px;
  background: #f2f3f4;
  border: 0.5px solid #d9d9d9;
  border-radius: 50px;
  color: #000000;
}
.NotificationHld .viewDetailsBtn:hover {
  color: #ffffff;
  text-decoration: none;
}
.NotificationHld .viewDetailsBtn img {
  width: 18px;
  height: 18px;
}
/* .newNotificationHld .notificationType {
  background: #e4e4e4 !important;
} */
.NotificationHld .notificationType {
  background: #feedef;
  text-align: center;
  border-radius: 50px;
  padding: 4px 15px;
}
.NotificationHld .notificationTypeText {
  font-weight: 600;
  font-size: 12px;
  color: #f44d5e;
}
.NotificationHld .notificationCTA {
  background: linear-gradient(
    121.13deg,
    #dc4e5a 20.17%,
    #e2595b 31.41%,
    #f57f5f 71.17%,
    #fd8e61 92.78%
  );
  border-radius: 50px;
  border: transparent;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  padding: 9px 16px;
  cursor: pointer;
}
.NotificationHld .notificationCTA:hover {
  color: #ffffff;
  text-decoration: none;
}
.NotificationHld .notificationCTA img {
  width: 15px;
}
.newNotificationHld {
  background-color: #fff4ef;
}
.NotificationHld .date {
  font-weight: 300;
  font-size: 14px;
  color: #3a3a3a;
  display: inline-block;
}
.NotificationHld .notificationTime {
  font-size: 12px;
  color: #757575;
  font-weight: 500;
}
.NotificationHld .notificationTitle {
  font-weight: 500;
  font-size: 12px;
  color: #3a3a3a;
  line-height: 1.3;
}
.NotificationHld .notificationBody span {
  font-weight: 700;
}
.NotificationHld .greyText,
.NotificationHld .greyText p {
  font-weight: normal;
  font-size: 12px;
  color: #a5a6aa;
  line-height: 1.5;
}
.NotificationHld .darkGreyText {
  font-weight: normal;
  font-size: 12px;
  color: #3a3a3a;
  line-height: 1.5;
}
.NotificationHld .notificationMessage {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}
.NotificationHld .notificationBody {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  margin: 10px 0 0px;
}
.notificationcntr {
  border-bottom: 0.5px solid #e4e8ee;
  padding: 10px 0;
}
.timingsList {
  display: inline-block;
}
.timingsList li {
  list-style: none;
  display: inline;
  font-weight: normal;
  font-size: 12px;
  color: #3a3a3a;
}
.timingsList li:after {
  content: "\00b7";
  padding: 0;
  vertical-align: middle;
  font-size: xx-large;
  line-height: 0;
  color: #757575;
}
.darkTheme .timingsList li::after {
  color: #B5C0D3;
}
.timingsList li:last-child:after {
  content: none;
}
.darkTheme .NotificationHld .acceptBtn {
  border: none;
}
.darkTheme .NotificationHld .notificationCTA:hover {
  color: #b5c0d3;
}
/* dark mode */
.darkTheme .pushNotificationHeadingHld {
  background: #000726;
}
.darkTheme .notificationcntr {
  background: #000726;
  border-bottom: 0.5px solid #263559;
}
.darkTheme .NotificationHld .notificationType {
  background: #131656;
  color: #6641d7;
}
.darkTheme .NotificationHld .notificationCTA {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
}
.darkTheme .NotificationHld .refuseBtn {
  background: #b5c0d3;
  color: #000726;
}
.darkTheme .NotificationHld .viewDetailsBtn {
  border: 1px solid #b5c0d3;
  background: transparent;
  color: #b5c0d3;
}
.darkTheme
  .pushNotificationHeadingHld
  .notificationTitle
  .unreadNotificationCount {
  background: #131656;
}
.darkTheme .newNotificationHld {
  background: #1f1629;
  border-bottom: 0.5px solid rgba(253, 142, 97, 0.5);
}
.darkTheme .NotificationHld .acceptedCta {
  background: #6641d7;
}