.bootstrap-iso .monk {
  position: relative;
}

.bootstrapisoauth .monk {
  background: url("../../Hackathon/Reuseable/img/lbpagehack.png") no-repeat
    bottom center;
  /* background-color: #cccccc; */
  height: 180px;
  /* width: auto; */
}

.bootstrapisoauth .monk-hackathon {
  position: absolute;
  left: 0;
  bottom: 0;
}
.bootstrapisoauth .lb-hack {
  height: 214px;
}
.scoreHld {
  max-width: 12%;
  min-width: 12%;
}
/* @media only screen and (max-width: 1000px) {
	.bootstrapisoauth .lb-hack {
		display: none;
	}
} */
@media only screen and (max-width: 991px) {
  .bootstrapisoauth .lb-hack {
    display: block;
    text-align: center;
    height: auto;
    width: 100%;
  }
}

.bootstrapisoauth .mobile-view {
  display: none;
}

.bootstrapisoauth .web-view {
  display: block;
}

.bootstrapisoauth .page-item.active .page-link {
  background-color: #e9505c;
  border-color: #e9505c;
}

.lederborad-head {
  background-color: #000;
  border-radius: 5px;
}

.lederborad-body {
  background-color: #f4f4f4;
}
.body-text {
  color: #80878c;
  font-size: 13px;
  font-weight: normal;
}

.body-text-bold {
  color: #455461;
  font-size: 14px;
  font-weight: 500;
}

.body-text-bold a {
  color: #455461;
  font-size: 14px;
  font-weight: 500;
}

.head-text {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.bootstrapisoauth .btn-download {
  border-radius: 50px !important;
  border: solid 1px #abb2b8 !important;
  background-color: #fff !important;
  color: #a5acb2 !important;
  font-size: 14px !important;
}
.bootstrapisoauth .btn-download:hover {
  color: #a5acb2 !important;
}
.bootstrapisoauth .publicbtn:hover {
  color: #000 !important;
}
.bootstrapisoauth .btn-download img {
  width: 10px;
}

.custom-list li {
  background: url("../../Hackathon/Reuseable/img/dark-leaderboard-star.svg")
    no-repeat left center;
  padding: 5px 0px 5px 40px;
  list-style: none;
  margin: 0;
  font-size: 14px;
}

/* @media only screen and (max-width: 780px) {
	.bootstrapisoauth .mobile-view {
		display: block;
	}
	.bootstrapisoauth .web-view {
		display: none;
	}
} */
.myPerformance {
  border-radius: 5px;
  background-color: #f4f5f4;
}
.myPerformance .heading {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  border-bottom: 1px solid #dadce0;
  line-height: 2;
}
.myPerformance .myscoretxt {
  font-size: 14px;
  font-weight: normal;
  color: #000;
}
.myPerformance .myscorevalue {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #000;
}
.publicPrivatebtn {
  color: #ffffff !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  width: 100%;
  border-radius: 0px !important;
  font-weight: normal !important;
  box-shadow: none;
}
.bootstrapisoauth .publicPrivatebtn:hover {
  color: #ffffff !important;
}
.publicPrivateActivebtn .publicPrivatebtn:hover {
  color: #000 !important;
}
.publicPrivateActivebtn {
  background-color: #f44d5e !important;
  color: #fff !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  width: 100%;
  border-radius: 2px !important;
  font-weight: 500 !important;
  box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 991px) {
  .bootstrapisoauth .mobile-view {
    display: block;
  }
  .bootstrapisoauth .web-view {
    display: none;
  }
}

/* .MuiPagination-ul li{
	background-color: #fff;
} */

.MuiPagination-ul button:hover,
button:focus {
  outline: none !important;
}

.MuiPagination-ul button:hover {
  background-color: #fff;
}
.leaderboard th:first-child,
.leaderboard td:first-child {
  border-radius: 0px;
  -moz-border-radius: 0px;
}

.leaderboard th:last-child,
.leaderboard td:last-child {
  border-radius: 0px;
  -moz-border-radius: 0px;
}
.leaderboard thead {
  background: #303242;
  border: 1px solid #303242;
  color: #fff;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}
.leaderboard th,
.leaderboard td {
  padding: 16px 11px;
}
.leaderboard tbody tr:nth-child(odd) {
  background-color: rgb(244, 244, 244);
}
.darkTheme .dark-leaderboard tbody tr {
  background-color: transparent;
  border-bottom: 1px solid #263559;
}
.darkTheme .dark-leaderboard tbody {
  border: 1px solid #263559;
  border-bottom: none;
}
.leaderboard tbody::before {
  all: unset;
}
.darkPublicActive {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
  border-radius: 50px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
  padding: 8px 26px !important;
}
.darkPublic {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
  padding: 8px 26px !important;
}
.darkPrivateActive {
  background: linear-gradient(92.97deg, #445eb7 10.8%, #6540d7 94.39%);
  border-radius: 50px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
  padding: 8px 26px !important;
}
.darkPrivate {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
  padding: 8px 26px !important;
}
.privatepublicbtnHld {
  display: flex;
  background: #303242;
  border-radius: 50px;
  width: fit-content;
}
.darklederborad-head {
  background: #303242;
  border: 0.5px solid #2f3242;
  padding: 16px 0;
  border-radius: 0;
}
.leaderboardHld {
  border: 1px solid #303242;
  /* border-bottom: none; */
}
@media only screen and (max-width: 768px) {
  .leaderboard {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
}
.profileHoverCardCss {
  z-index: 1;
  left: 44px;
  top: -10px;
}
