/* hackathon AI search */
.hackathon-ai-search-hld-inner{
    position: relative;
  }
  .hackathon-ai-search-hld {
    width: 42%;
    margin: 48px auto 0;
    /* margin-bottom: 42px; */
    background-image: linear-gradient(135deg, #087CE1 0%, #E607B0 100%);
    padding: 1.5px;
    border-radius: 50px;
  }
  .mob-hackathon-ai-search-hld{
    width: 90%;
  }
  .mob-hackathon-ai-search-hld   .hackathon-ask-btn{
    min-width: 100px;
    max-width: 120px;
  }
  .hackathon-ai-search-hld-inner {
    background: #fff;
    border-radius: 50px;
  }
  /* .hackathon-ai-search-hld-inner:hover {
    filter: drop-shadow(16px 16px 10px black);
  } */

  .darkTheme .hackathon-ai-search-hld-inner {
    background: #0C1431;}
  
  .hackathon-ai-search-hld input {
    width: 74%;
    border: none;
    background: transparent;
    padding: 12px 20px;
    border-radius: 50px;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
    caret-color: #AB29C5;
  }
  .mob-hackathon-ai-search-hld input{
    width: 58%;
  }
  .darkTheme   .hackathon-ai-search-hld input {
    color: #ffffff;
  }
  .darkTheme .hackathon-ai-search-hld input::placeholder {
    background-image: linear-gradient(135deg, #087CE1 0%, #E607B0 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .hackathon-ask-btn{
    position: absolute;
    border-radius: 50px;
    background-image: url(assets/img/MachineAi/askBtn.svg);
    border: none;
    font-weight: 700;
    font-size: 11.6683px;
    color: #FFFFFF;
    max-width: 137px;
    right: 0;
    top: 8px;
    min-width: 137px;
    max-height: 31px;
    min-height: 31px;
  }
  .darkTheme .hackathonSnackBar .MuiPaper-root {
      position: relative;
      top: 30px;
  }
  .machinehack-ai-input::selection{
    background: #445eb729;
  }
  .typewriter {
    overflow: hidden; 
    border: none; 
    outline: none; 
    animation: typewriter 1.8s steps(40) infinite; 
  }
  .typewriter:focus {
    animation: none; 
  }
  .powered-by-text{
    width: 42%;
    margin: 0 auto;
    text-align: right;
    background-image: linear-gradient(135deg, #087CE1 50%, #E607B0 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 500;
    padding-right: 10px;
  }
  @keyframes typewriter {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
 