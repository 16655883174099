.profileCard {
  background: #ffffff;
  border: 0.75px solid #f4f4f4;
  box-shadow: 2px 4px 50px rgba(91, 98, 105, 0.2);
  border-radius: 10px;
  min-width: 300px;
  max-width: 300px;
  z-index: 99999999;
  top: 0px;
  left: 45px;
}
.darkTheme .profileCard {
  background: #263559;
  border: none;
  box-shadow: unset;
}
.usernameProfilecardhld .profileCard {
  top: 30px;
  left: 0;
}
.userNameProfileDiscusshld .profileCard {
  top: 53px;
  left: 14px;
}
.userNameProfileCArdDiscussImg .profileCard {
  left: 75px;
}
.userProfileNotebookImgHover .profileCard {
  left: 79px;
}
.userProfileNotebookTextHover .profileCard {
  top: 18px;
  left: 14px;
}
.userProfileHoverImgLeaderBoard .profileCard {
  left: 56px;
}
.userProfileHoverTextLeaderBoard .profileCard {
  top: 20px;
  left: 0px;
}
.userProfileCourseReviewDiscussText .profileCard {
  top: 20px;
  left: 0px;
}
.userProfileCourseReviewDiscussText span {
  font-weight: 500;
}
.userProfileCourseReviewDiscussImg .profileCard {
  left: 65px;
}
.userProfileCourseDiscussImg .profileCard {
  left: 53px;
}
.userProfileCourseDiscussText .profileCard {
  top: 20px;
  left: 0px;
}
.userCardForLogUserText .profileCard {
  top: 20px;
  left: 13px;
}
.userCardForLogUserImg .profileCard {
  top: 25px;
}
.userCardAllDiscussionImg .profileCard {
  left: 85px;
}
.userCardAllDiscussionText .profileCard {
  top: 18px;
  left: 16px;
}
.singleDiscussionTextHover .profileCard {
  top: 42px;
  left: 21px;
}
.singleDiscussionImgHover .profileCard {
  left: 100px;
}
.userProfileCardMainLandingImg .profileCard {
  left: 40px;
}
.userProfileCardMainLandingText .profileCard {
  left: 0px;
  top: 21px;
}
.userProfileTopAuthorsHoverText .profileCard {
  top: 21px !important;
  left: 0px !important;
}
.userProfileTopAuthorsHoverImg .profileCard {
  left: 49px;
  top: 5px;
}
.blogInnerAuthorHoverCardText .profileCard {
  top: 23px;
  left: 0px;
}
.blogInnerCommentProfileHoverIng .profileCard {
  top: 0px;
  left: 31px;
}
.blogInnerCommentProfileHoverText .profileCard {
  top: 23px;
  left: 0px;
}
.blogInnerReplyImgHover .profileCard {
  left: 30px;
}
.blogInnerReplyTextHover .profileCard {
  top: 22px;
  left: 0px;
}
.blogInnerinnerReplyDataImgHover .profileCard {
  left: 50px;
}
.blogInnerinnerReplyDataTextHover .profileCard {
  top: 22px;
  left: 0px;
}
.blogLandingImgHover .profileCard {
  left: 25px;
  z-index: 2147483;
}
.blogLandingTextHover .profileCard {
  top: 21px;
  left: 0px;
}
.blogInnerReadNextImgHover .profileCard {
  left: 30px;
}
.blogInnerReadNextTextHover .profileCard {
  top: 25px;
  left: 0px;
}
.userDiscussingDiscussDEtailsImgHover .profileCard {
  left: 68px;
}
.userDiscussingDiscussDEtailsTextHover .profileCard {
  top: 40px;
  left: 0px;
}
.courseDiscussionInnerImgHover .profileCard {
  left: 75px;
}
 .darkTheme .discussion  table td, .discussion table th{
  color: #fff !important;
}
.courseDiscussionInnerTextHover .profileCard {
  top: 25px;
  left: 21px;
}
.courseDiscussionInnerImgHoverHack .profileCard {
  left: 80px;
}
.tournamentUserHoverImg .profileCard {
  left: 70px;
  top: 10px;
}
.tournamentUserHoverText .profileCard {
  top: 33px;
  left: 11px;
}
.badgesHld {
  background: #F7F7F8;
  border-radius: 0 0 10px 10px;
}
.darkTheme .badgesHld {
  background: #3C4D77;
}
.badgesImgHld {
  margin-left: -10px;
  cursor: pointer;
}
.cardFollowBtn {
  border: 1px solid #f44d5e;
  border-radius: 50px !important;
  background: transparent;
  font-family: "Inter";
  font-weight: 500 !important;
  font-size: 13px !important;
  color: #f44d5e;
  padding: 2px 25px !important;
}
.cardFollowBtn > img {
  vertical-align: baseline;
}
.darkTheme .cardFollowBtn {
  border: 1px solid #B5C0D3;
  color: #B5C0D3;
}
.profileCard .cardFollowBtnFollowed {
  background: #d9d9d9;
  border-radius: 50px !important;
  font-family: "Inter";
  font-weight: 500 !important;
  font-size: 13px !important;
  color: #23242a;
  padding: 2px 25px;
  border: 1px solid transparent;
}
.profileCard .dark-cardFollowBtnFollowed {
  border-radius: 50px !important;
  font-family: "Inter";
  font-weight: 500 !important;
  font-size: 13px !important;
  color: #23242a;
  padding: 2px 25px;
  border: 1px solid transparent;
}
.cardFollowBtnFollowed > img {
  vertical-align: baseline;
  padding-right: 10px;
}
.cardMessageBtn {
  background: #23242a;
  border-radius: 50px !important;
  font-family: "Inter";
  font-weight: 600 !important;
  font-size: 13px !important;
  color: #ffffff;
  border: 1px solid transparent;
  padding: 2px 30px !important;
}
.profileUserCardName > img {
  max-width: 17px !important;
}
.profileCardLocationText > img {
  max-width: 14px !important;
}
.profileCardName {
  z-index: 9999;
}
.userHoverImg {
  border-radius: 50px !important;
  max-width: 60px !important;
  max-height: 60px !important;
}
.profileCardDivider {
  border: 0.5px solid #d9d9d9;
}
.profileUserCardName {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #23242a;
  text-transform: none;
  text-align: initial;
}
.profileUserCardDesignation {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #666666;
  line-height: 16px !important;
  text-transform: none;
  text-align: initial;
}
.truncate-line-clamp-profile-card {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  width: 195px;
  text-overflow: ellipsis;
}
.truncate-line-clamp-profile-card-name {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  width: 195px;
  text-overflow: ellipsis;
}
.profileCardLocationText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #666666;
  max-width: 100px;
}
.profileCardInfoText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}
.profileCardInfoTextDesc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 10px !important;
  color: #666666 !important;
}
.profileCardTopRankers {
  z-index: 9999 !important;
  left: 50px;
  padding: 0px 7px;
}
.cardFollowBtn > img {
  height: 21px !important;
}
.cardMessageBtn > img {
  height: 21px !important;
}

.profileCard .cardMessageBtn .profileCardBtnImg{
  height: 21px !important;
  width: 21px !important;
}
.ProfileCardbadgeToolTip {
  background: #FFF6E2;
  color: #DB9911;
  border-radius: 3px 50px 50px 80px;
  font-weight: 500;
  font-size: 10px;
  line-height: normal;
  text-align: center;
  position: absolute;
  left: 10px;
  bottom: -20px;
  padding: 4px 10px 4px 12px;;
  white-space: pre;
  width: max-content;
}
.darkTheme .ProfileCardbadgeToolTip {
  background: #334378;
  color: #ffffff;
}