.hackathonHostModal {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 20px;
}
.hackathonHostModal .hackathonHostCntr {
  background: #fff;
  width: 1030px;
  border-radius: 4px;
  position: relative;
}
.hackathonHostCntr .requestHackathonHeading {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  color: #0f0506;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 0;
}
.hackathonHostModal .requesthackathonbody {
  padding: 20px 50px 30px;
}
.request-hackathon-form {
  overflow: auto;
  height: calc(100vh - 200px);
}
