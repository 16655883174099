.footer-container {
  font-family: "Inter", sans-serif;
  border-top: 0.75px solid #263559;
}

.footer-container-light {
  background-color: #000000;
  color: #ffffff;
}
.footer-container-dark {
  background: #000519;
  color: #b5c0d3;
}
.footer-container .footer-gray-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #757575 !important;
}
.footer-container .footer-gray-text:hover {
  color: #fff !important;
}
.footer-container-dark .footer-gray-text {
  color: #5f6886 !important;
}
.footer-gray-text > a {
  text-decoration-line: underline !important;
}

.footer-container .footer-wrapper .footer-bold-text,
.footer-container .footer-bold-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}
.footer-container .footer-bold-text {
  font-size: 16px;
}

.footer-wrapper {
  min-height: 300px;
  height: auto;
  width: 100%;
}

.footer-wrapper .footer-link-text,
.footer-short-container .footer-link-text,
.footer-subscribe-hld .footer-medium-text {
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  padding: 9px 0px;
  text-transform: initial;
  font-weight: 400;
  cursor: pointer;
}
.footer-subscribe-hld .footer-medium-text {
  cursor: auto;
}
.footer-container-dark .footer-wrapper .footer-link-text,
.footer-container-dark .footer-link-text,
.footer-container-dark .footer-wrapper .footer-bold-text,
.footer-container-dark .footer-subscribe-hld .footer-medium-text {
  color: #b5c0d3;
}

.footer-tabs {
  background: rgba(255, 255, 255, 0.1);
  border: 1.5px solid rgba(255, 255, 255, 0.15);
  border-radius: 50px;
  height: 50px;
  /* width: 250px; */
}

.footer-container .footer-top-section {
  padding: 36px 0px;
}

.footer-container-light .footer-top-section {
  background-color: #1e1e1e;
}
.footer-container-dark .footer-top-section {
  background: rgba(38, 53, 89, 0.1);
}
.footer-list li a {
  text-decoration: none;
}

.footer-container .astronaut-img {
  position: absolute;
  bottom: -98px;
  left: -10%;
  z-index: 11;
  background-image: radial-gradient(
    ellipse at center,
    #f32c3f4f -1.66%,
    #00000000 63.58%
  );
  background-size: cover;
  border-radius: 96px;
}

.footer-container .subscribe-btn {
  border: none;
  border-radius: 108px;
  padding: 8px 8px;
  text-align: center;
  width: 40%;
  background: #fff;
}

.footer-container-dark .subscribe-btn {
  background: #b5c0d3;
}
.footer-container-dark .subscribe-active-btn {
  background: #f2f3f4;
  font-weight: 500;
}
.footer-container .subscribe-input {
  background: transparent;
  border: none;
  width: 60%;
  padding: 8px 4px 8px 23px;
}
.footer-container .subscribe-input-hld .subscribe-input,
.footer-container .subscribe-input-hld .subscribe-input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}
.footer-container .footer-container-dark .subscribe-input-hld .subscribe-input {
  color: #b5c0d3;
}
.footer-container .subscribe-input-hld .subscribe-input::placeholder {
  color: rgba(255, 255, 255, 0.35);
}

.footer-container .subscribe-input-hld {
  background: #1f1f1f;
  border-radius: 108px;
  min-width: 235.75px;
  max-width: 300px;
}

.footer-container .dark-subscribe-input-hld {
  background: #1b234a;
  border-radius: 108px;
}
.footer-copyright,
.footer-middle-section {
  border-top: 0.75px solid #333333;
}
.footer-copyright-dark,
.footer-container-dark .footer-middle-section {
  border-top: 0.75px solid #263559;
}
.bootstrap-iso .footer-list .contactus-btn {
  background: linear-gradient(88.78deg, #f04f5e 20.65%, #e97f3d 98.95%);
  border-radius: 50px;
  padding: 1px;
  width: 50%;
  max-width: 150px;
}
.bootstrap-iso .darkTheme .footer-list .contactus-btn {
  background: linear-gradient(
    88.78deg,
    rgba(68, 94, 183, 1) 20.65%,
    rgba(101, 64, 215, 1) 98.95%
  );
}
.footer-list .contactus-btn-inner {
  background-color: #000000;
  border-radius: 50px;
  transition: background 1s ease;
}
.footer-container-dark .footer-list .contactus-btn-inner {
  background-color: #000519;
}
.footer-list .contactus-btn-text {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 20px;
  background: linear-gradient(88.78deg, #f04f5e 20.65%, #e97f3d 98.95%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  transition: background 1s ease;
}
.darkTheme .footer-list .contactus-btn-text {
  background: linear-gradient(
    88.78deg,
    rgba(68, 94, 183, 1) 20.65%,
    rgba(101, 64, 215, 1) 98.95%
  );
  -webkit-background-clip: text;
  background-clip: text;
}

.footer-list .contactus-btn:hover .contactus-btn-inner,
.footer-list .contactus-btn:hover .contactus-btn-inner .contactus-btn-text {
  background: none;
  color: #ffffff;
}

.dark-contactus-btn {
  filter: drop-shadow(0px 20px 40px rgba(77, 87, 191, 0.45));
}

.dark-placeholder-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b5c0d3;
}

.premium-section {
  background: linear-gradient(
    277.41deg,
    #755633 -9.16%,
    #e5ae68 66.9%,
    #f8d88d 114.08%
  );
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #000726;
}

.footer-semibold-text {
  font-weight: 600;
}

.footer-telegram-img {
  height: 14.5px;
}
.footer-premium-section {
  background: linear-gradient(
    278.03deg,
    #cc7740 7.68%,
    #e9ac44 58.84%,
    #ffca28 87.76%
  );
}
.footer-container-dark .footer-premium-section {
  background: linear-gradient(
    277.41deg,
    #755633 -9.16%,
    #e5ae68 66.9%,
    #f8d88d 114.08%
  );
}
.footer-premium-section .footer-buynow-btn {
  background: #000000;
  border-radius: 50px;
  border: none;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  border-radius: 50px;
  padding: 10px 46px;
  transition: all 0.8s ease-in;
}
.footer-premium-section .footer-buynow-btn:hover {
  transform: scale(1.05);
}
.footer-premium-section .footer-dark-text {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}
.footer-copyright .right-section a {
  text-decoration: underline;
}
.footer-copyright .right-section {
  max-width: 526px;
}
.facebook-img:hover {
  border-radius: 50px;
  cursor: pointer;
  background-color: #007fee;
}
.twitter-img:hover {
  border-radius: 50px;
  cursor: pointer;
  background-color: #00a8d9;
}
.linkedin-img:hover {
  border-radius: 50px;
  cursor: pointer;
  background-color: #0069b9;
}
.youtube-img:hover {
  border-radius: 50px;
  cursor: pointer;
  background-color: #ff0019;
}
.course-lottie-hld {
  background-image: linear-gradient(
    75.97deg,
    rgba(73, 102, 232, 0.098) -1.66%,
    rgba(0, 0, 0, 0.03) 63.58%
  );
}
.event-lottie-hld {
  background-image: linear-gradient(
    75.97deg,
    rgba(15, 214, 141, 0.098) -1.66%,
    rgba(0, 0, 0, 0.03) 63.58%
  );
}
.jobs-lottie-hld {
  background-image: linear-gradient(
    75.97deg,
    rgba(255, 0, 255, 0.098) -1.66%,
    rgba(0, 0, 0, 0.03) 63.58%
  );
}
.datasets-lottie-hld {
  background-image: linear-gradient(
    75.97deg,
    rgba(254, 185, 30, 0.15) -1.66%,
    rgba(0, 0, 0, 0.03) 63.58%
  );
}
.tournaments-lottie-hld {
  background-image: linear-gradient(
    75.97deg,
    rgb(191, 85, 40, 0.2) -1.66%,
    rgba(0, 0, 0, 0.03) 63.58%
  );
}

.website-menu-item:hover {
  color: #a5a6aa !important;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none !important;
}

.textimage {
  height: 140px;
}
.host-hackathon {
  background-color: #313233;
  color: #fff;
  border: none;
  padding: 8px 15px 8px 15px;
  border-radius: 5px !important;
}
.red-text-bold {
  color: #f44d5e !important;
  font-weight: bold !important;
  font-size: 22px !important;
}
.text-black-small {
  color: #000 !important;
  font-size: 14px !important;
}
.form-request .text-box-popup {
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  height: 30px;
  padding-left: 5px;
}
.textarea-popup {
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  padding-left: 5px;
}
.error-msg {
  color: #f44d5e;
  font-size: 12px;
}

.update-profile .MuiDialog-paperWidthXl {
  width: 1030px;
}
.chimp-gif {
  position: absolute;
  bottom: -49px;
  height: 400px;
}
.form-request input::placeholder {
  font-size: 12px !important;
}
.form-request textarea::placeholder {
  font-size: 12px !important;
}
.website-menu-item {
  color: #a5a6aa !important;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none !important;
}

.arrowImg {
  width: 27px;
}
.errorMsg {
  font-size: 12px;
  color: #e5b44a;
  font-weight: 500;
}

.successText {
  font-size: "21px";
  font-weight: 500;
  color: #f44d5e;
}
.social-media-row a {
  padding: 0 !important;
}

/* short footer css */
.bootstrap-iso .footer-short-container {
  margin-top: 25px;
  padding-top: 6px;
  padding-bottom: 6px;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.machine-hack-image {
  width: 180px;
  object-fit: contain;
}
.footer-short-container a {
  color: #757575;
}
.footer-short-container a:hover {
  color: #757575;
  text-decoration: underline;
}
.footer-container-dark > .footer-short-container a {
  color: #5f6886;
}
.footer-container-dark > .footer-short-container a:hover {
  color: #5f6886;
  text-decoration: underline;
}


.footer-top-section-mob .footer-tabs {
  max-height: 34px;
  background: transparent;
  border: none;
  border-radius: 0px;
}
.footer-top-section-mob .footer-tabs:first-child,
.footer-top-section .event-lottie-hld {
  border-right: 1.5px solid rgba(255, 255, 255, 0.15);
}
.footer-top-section-mob .footer-tabs:last-child {
  border-left: 1.5px solid rgba(255, 255, 255, 0.15);
}
.footer-top-section-mob .footer-container .footer-wrapper .footer-bold-text,
.footer-top-section-mob .footer-container .footer-bold-text {
  font-size: 14px;
  line-height: 15px;
}

.footer-container .footer-top-section-mob {
  padding: 15px 0px;
}
.footer-modal {
  z-index: 111;
}
.footer-modal-hld {
  position: absolute;
  margin: auto;
  width: 100%;
  bottom: 0;
}
.footer-modal-hld-close {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.footer-modal-hld-close img {
  height: 60px;
  width: 60px;
  cursor: pointer;
}
.footer-container-dark .footer-wrapper .footer-link-text:hover,
.footer-container-dark .footer-link-text:hover,
.footer-container-dark
  .highlighted-link:hover
  .footer-wrapper
  .footer-bold-text,
.footer-container-dark .highlighted-link:hover .footer-bold-text {
  color: #fff;
}
.footer-container-light .footer-wrapper .footer-link-text:hover,
.footer-container-light .footer-link-text:hover,
.footer-container-light
  .highlighted-link:hover
  .footer-wrapper
  .footer-bold-text,
.footer-container-light .highlighted-link:hover .footer-bold-text {
  color: #f04f5e;
}
.bounce {
  animation: bounce 2.5s ease infinite;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.bootstrap-iso .jobs-img-hld {
  height: 24px;
}
.bootstrap-iso .footer-tabs-jobs {
  padding-left: 3px;
}
.bootstrap-iso .course-lottie-hld {
  margin-bottom: 8px;
}
.footer-row {
  max-width: 1400px;
}

.slowBounce {
  animation: slowBounce 2s ease infinite;
}
@keyframes slowBounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}

.pulse {
  animation: pulse 1s infinite ease-in-out alternate;
}
@keyframes pulse {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1.1);
  }
}

.flash {
  animation: flash 500ms ease infinite alternate;
}
@media (max-width: 575.98px) {
  .footer-top-section-mob {
    font-size: 20px;
    flex-wrap: wrap;
    position: relative;
  }
  .footer-container .footer-bold-text {
    font-size: 14px;
  }
  .footer-tabs {
    padding: 0px 14px;
    margin: 10px 0;
  }
  .footer-container .footer-top-section-mob {
    padding: 6px 0px;
  }
  .footer-top-section-mob::after {
    content: "";
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.15);
    position: absolute;
    top: 50%;
  }
}
@media (max-width: 424px) {
  .footer-tabs {
    padding: 0px 6px;
    margin: 10px 0;
  }
}
