.page404Hld .Page404text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  text-align: center;
  color: #a5a6ac;
}
.page404Hld .page404Button {
  background: #f44d5e;
  border-radius: 5px;
  border: 1px solid #f44d5e;
  color: #ffffff;
  width: 250px;
  height: 40px;
  font-weight: 700;
}
