.table-data-row {
  /* min-width: 700px; */
  width: 100%;
}

/* .MuiTableHead-root {
    box-shadow: 0px 0px 7px 2px #d1d1d1;
} */

/* table {
    border-collapse: separate;
    border-spacing: 0 15px;
} */

tbody:before {
  /* This doesn't work because of border-collapse */
  /* line-height: 1em;
    content: "_";
    color: #fff;
    display: block; */
}

/* .MuiTableHead-root,
tr.highlight td {
    box-shadow: 0px 0px 7px 2px #d1d1d1;
    margin-bottom: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
} */
.infoIcon {
  filter: brightness(0) invert(1);
  cursor: pointer;
  height: 18px !important;
}
.popOverHld {
  background-color: #494d5c;
  position: absolute;
  padding: 2px 10px;
  border-radius: 4px;
  left: -10px;
  white-space: pre;
  bottom: -16px;
}
.popOverHld p {
  margin-bottom: 0;
}
.popOverHld::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #494d5c;
  position: absolute;
  top: -2px;
  left: 49%;
  transform: translate(-50%, -50%);
}
.nonPremiumUserRow {
  background-color: #475fb6;
}

.submissionSubscriptionHld:nth-of-type(3) ~ .submissionSubscriptionHld td,
.submissionSubscriptionHld:nth-of-type(3) ~ .submissionSubscriptionHld th {
  filter: blur(10px);
}
.darkTheme .submissionSubscriptionHld{
  border-right: 1px solid #263559;
  border-left: 1px solid #263559;
}
.darkTheme .submissionSubscriptionTable thead tr{
  border: 1px solid #263559 !important;
}
.darkTheme .submissionSubscriptionHld:last-child{
  border-bottom-left-radius: 10px ;
  border-bottom-right-radius: 10px ;
}
.table-data-row .submissionSubscription {
  position: absolute;
  left: 45%;
  bottom: calc((100% - (160px + 150px)) / 2);
  padding: 5px 30px;
  background: linear-gradient(121.13deg, #DC4E5A 20.17%, #E2595B 31.41%, #F57F5F 71.17%, #FD8E61 92.78%);
  color: #fff;
  border-radius: 40px !important;
  border: none;
  font-size: 14px;
  filter: blur(0);
  z-index: 111;
}
.darkTheme .table-data-row .submissionSubscription {
  background: #fff;
  color: #666;
}
.darkTheme .submissionTableMain table thead tr{
  border: 1px solid #263559;
}
.submissionTableMain{
  position: relative;
}
.table-data-mobile .submissionSubscription {
  left: 25%;
  top: 74%;
  position: absolute;
  padding: 5px 30px;
  background: #fff;
  border-radius: 40px !important;
  border: none;
  font-size: 14px;
  color: #666;
  filter: blur(0);
  z-index: 111111111;
}
.dark-Submisstion-history-heading {
  background: linear-gradient(283.18deg, #4C3798 -10.6%, #3E2646 63.41%, #452F34 97.5%);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.submissionTableHead{
  background: rgba(41, 31, 60, 0.6) !important;
}
@media only screen and (min-width: 600px) {
  .table-data-mobile {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .table-data-row {
    display: none;
  }
  .table-data-mobile {
    display: block;
    width: 100%;
  }
}
