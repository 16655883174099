.feedArticleUserImg {
  max-width: 60px;
  min-width: 60px;
  border-radius: 50px;
  max-height: 60px;
}

.feedArticleUserTags {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.feedArticleUserTags img {
  vertical-align: baseline;
}

.dark-feedArticleUserTags {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.dark-feedArticleUserTags img {
  vertical-align: baseline;
}

.feedArticleUserTags:hover {
  border: 0.5px solid #d9d9d9;
  border-radius: 50px !important;
  padding: 2px 15px;
}

.dark-feedArticleUserTags:hover {
  background: rgba(38, 53, 89, 0.8);
  border: 0.5px solid #263559;
  border-radius: 50px;
  padding: 2px 15px;
}

.article-input-section {
  background: #ffffff;
  border-radius: 50px;
  border: 1px solid #d9d9d9;
  padding: 0px 15px;
}

.article-input-section input {
  background-color: transparent;
  border: none;
  min-height: 52px;
  max-height: 52px;
  padding: 0px 15px;
}

.article-action-btn-section {
  max-height: 30px;
  min-height: 30px;
  min-width: 100px;
  max-width: 100px !important;
}

.article-action-btn-section-write {
  max-height: 25px;
  min-height: 30px;
  min-width: 163px;
  max-width: 163px;
}

.article-input-section input::placeholder {
  font-family: "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #757575;
}

.article-input-section-main {
  border: 0.5px solid #d9d9d9;
  border-radius: 10px;
  background: #ffffff;
}

/*Dark*/
.dark-article-input-section {
  background: rgba(0, 7, 38, 0.56);
  border-radius: 50px;
  border: none;
}

.dark-article-input-section input {
  background-color: transparent;
  border: none;
  min-height: 52px;
  max-height: 52px;
  color: #ffffff;
  padding: 0px 15px;
}

.dark-article-input-section input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #b5c0d3;
}
.feed-InputField-btn-web {
  height: 58px;
}
/*Dark*/

/*ForMobile*/
.create-post-content-edit {
  min-height: 150px;
  font-weight: 400;
  font-size: 15px;
}
.create-post-popup .article-pop-text .create-post-content-edit * {
  font-weight: 400 !important;
  font-size: 15px !important;
  font-family: "Inter", sans-serif !important;
  color: #000000 !important;
  background-color: transparent !important;
  background: transparent !important;
  border: none !important;
  text-decoration: none !important;
  margin: 0;
  padding: 0;
  width: auto !important;
  float: unset !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  white-space: unset !important
}
.create-post-popup .article-pop-text .create-post-content-edit h2,
.create-post-popup .article-pop-text .create-post-content-edit h1,
.create-post-popup .article-pop-text .create-post-content-edit h3,
.create-post-popup .article-pop-text .create-post-content-edit h4,
.create-post-popup .article-pop-text .create-post-content-edit h5 {
  font-size: 20px !important;
}
.create-post-popup .article-pop-text .create-post-content-edit ul,
.create-post-popup .article-pop-text .create-post-content-edit ol{
  margin-left: 40px !important;
}
.darkTheme .article-pop-text .create-post-content-edit * {
  color: #ffffff !important;
}
.min-height-50 {
  min-height: 50px;
}
.create-post-content-edit[contentEditable="true"]:empty:before {
  content: attr(placeholder);
  font-weight: 400;
  font-size: 15px;
  color: #757575;
}
.darkTheme .create-post-content-edit[contentEditable="true"]:empty:before {
  color: #b5c0d3;
}
.diabled-btn {
  opacity: 0.3;
  cursor: no-drop;
}
.selectedImage {
  border-radius: 10px;
}
.uploaded-img-remove {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 7px;
  right: 0;
  width: 20px;
  text-align: center;
  border-bottom-left-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
}
.uploaded-img-remove img {
  margin-bottom: 5px;
  margin-left: 2px;
}
.selectedSmallImg {
  width: 23%;
}
.userDetailsCntr {
  border-bottom: 0.5px solid #263559;
}
.userDetailsCntr .user-profile-picture {
  border-radius: 50px;
}
.userDetailsCntr .user-profile-name {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 0;
}
.userDetailsCntr .user-details {
  font-weight: 500;
  font-size: 10px;
  margin-bottom: 0;
}
/*ForMobile*/
